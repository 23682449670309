import Vue from 'vue'
import VueRouter from 'vue-router'

import ThankYouPage from '../views/thankyou.vue'
import InterstitialPage from '../views/interstitial.vue'
import TermsPage from '../views/terms.vue'
import ConsentForm from '../views/ConsentForm.vue'
import Covid from '../views/covid.vue'
import PaymentPlans from '../views/PaymentPlans.vue'
import Logins from '../views/logins.vue'
import store from '../store.js'

Vue.use(VueRouter)
const routes= [
    {
      path: '/',
      name: 'TermsPage',
      component: TermsPage,
    },
    {
      path: '/thankyou',
      name: 'ThankYou',
      component: ThankYouPage
    },
    {
      path: '/interstitial',
      name: 'InterstitialPage',
      component: InterstitialPage
    },
    {
      path: '/consent',
      name: 'ConsentForm',
      component: ConsentForm,
      beforeEnter: (to, from, next) => {
        if(store.state.baseData.consent && store.state.baseData.consent.enabled) {
          next(true)
        } else {
          next('/covid')
        }
      }
    },
    {
      path: '/covid',
      name: 'Covid',
      component: Covid,
      beforeEnter: (to, from, next) => {
        if(store.state.baseData.branding && store.state.baseData.branding.covid19PageEnabled) {
          next(true)
        } else {
          next('/payment-plans')
        }
      }
    },
    {
      path: '/payment-plans',
      name: 'PaymentPlans',
      component: PaymentPlans
    },
    {
      path: '/social-logins',
      name: 'Logins',
      component: Logins
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
const router = new VueRouter({
  routes
})
export default router