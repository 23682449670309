<template>
    <div class="logo">
        <img v-if="url" class="brand-logo"  :style="{'width': `${baseData.branding.logoImageSize}%`}" :src="url" alt="">
    </div>
</template>

<style>
    .logo{
        top: 10px;
        width: 100%;
        text-align: center;
    }
    .logo img{
        max-width: 200px;
        margin: 0 auto;
        max-height: 100%;
        padding: 20px 0;
        height: 100% !important;
    }
</style>

<script>
    export default {
        props: ['url'],
        computed: {
            baseData(){
                return this.$store.state.baseData
            },
        }
    }
</script>