<template>
<div class="contentWrapper">
  <thankyou-layout />
  <div class="postLogin-container">
    <ads
      v-if="hasInterstitialAd"
      class="ads-area"
      :wftkn="wftkn"
      :campaignId="campaignId"
      :position="'zone_position_post_interstitial'"
    />
    <ads-placeholder v-if="hasPreviewAds && ads.interstitial.top"/>
    <div class="redirect-area">
      <p v-if="!isManual"
         class="redirect-time">
        {{ timer / 1000 }} s
      </p>
      <p v-if="isManual">
        <router-link
          class="next-btn"
          :to="{ name: 'ThankYou'}">
          →
        </router-link>
      </p>
    </div>
    <ads-placeholder v-if="hasPreviewAds && ads.interstitial.bottom"/>
  </div>
</div>
</template>

<script>
import Ads from '../components/ads/ads'
import AdsPlaceholder from './ads-placeholder'
import ThankyouLayout from './thankyouLayout.vue'

export default {
  components: {
    Ads,
    AdsPlaceholder,
    ThankyouLayout
  },

  data() {
    return {
      timer: null
    }
  },

  mounted() {
    this.timer = this.redirectTime;
    window.setInterval(() => {
      if (this.timer !== 0) {
        this.$set(this, 'timer', this.timer - 1000);
      }
    }, 1000);
  },

  computed: {
    hasPreviewAds() {
      return this.baseData.isAdvertising && this.baseData.preview;
    },
    ads() {
      return this.baseData.advertisement;
    },
    baseData() {
      return this.$store.state.baseData
    },
    campaignId() {
      return this.baseData ? this.baseData.campaignId : null;
    },
    postLogin() {
      return this.$store.getters.postLogin
    },
    redirectTime() {
      return this.postLogin ? this.postLogin.payload.redirection_time_of_interstitial_page : 5000;
    },
    wftkn() {
      return (this.postLogin) ? this.postLogin.payload.wftkn : null;
    },
    hasAdvertising() {
      return this.postLogin ? this.postLogin.payload.advertising : false;
    },
    hasInterstitialAd() {
      return (this.hasAdvertising && (this.postLogin.payload.adData && ('zone_position_post_interstitial' in this.postLogin.payload.adData)))
    },
    isManual() {
      return (this.postLogin && this.postLogin.payload.redirection_time_of_interstitial_page == 0) ? true : false
    }
  },

  watch: {
    timer() {
      if (!this.baseData.preview) {
        if ((this.timer === 0 && !this.isManual) || this.hasAdvertising == "false") {
          this.$router.push('thankyou');
        }
      }
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@500&family=Archivo&family=B612&family=BioRhyme&family=Cairo&family=Cardo&family=Crimson+Text&family=Fjalla+One&family=Frank+Ruhl+Libre&family=IBM+Plex+Sans&family=Karla&family=Lora&family=Montserrat&family=Muli:wght@577&family=Playfair+Display&family=Roboto&family=Rubik&family=Source+Sans+Pro&family=Spectral&family=Titillium+Web&family=Varela&family=Vollkorn&family=Work+Sans&display=swap');

.contentWrapper{
  width: 100%;
  margin-block: 0;
}

.postLogin-container{
  max-width: 600px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.redirect-area {
  display: flex;
  align-items: center;
  justify-content: center;
}

.redirect-time {
  font-size: 12px;
  background: #f4f4f4;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 32px;
  font-weight: 600;
  color: #808080;
  margin-right: 10px;
}

.next-btn {
  background: #55acee;
  padding: 10px 20px;
  border-radius: 32px;
  color: white;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
}
</style>
