<template>
  <div>
    <div class="error-msg-area">
      <alert-msg
        v-if="customError"
        :errorMsg="customErrorMsg">
      </alert-msg>
    </div>

    <div class="custom-fields twilio" v-if="!enterCode">
      <CustomFormFields
        :fields="fields"
        ref="smsFields"
        :baseData="baseData"
        v-model="user"
        @invalid="invalidEntities"
        @valid="validEntities"
      />

      <div style="position: relative">
        <button class="social-btn connect sms"
          @click.prevent="connectverify()"
          :style="primaryButtonStyles">
          <i class="fa fa-wifi" aria-hidden="true" v-show="baseData.loginOptions.styles.primaryButtonIconVisible || false"></i>
          {{ $t('connect') }}
        </button>
        <div v-show="sending || validating"
          class="sk-three-bounce"
          style="position: absolute; top: 23px; width: 100%;">
          <div class="sk-child sk-bounce1"></div>
          <div class="sk-child sk-bounce2"></div>
          <div class="sk-child sk-bounce3"></div>
        </div>
      </div>
      <a href="#" v-if="baseData.backButton" @click.prevent="goBack()">
        ← {{ $t('back_button') }}
      </a>
    </div>

    <transition name="slide-fade">
      <div class="custom-fields" v-if="enterCode && !loading && !connected">
        <div class="sms-sent-holder">
          <transition name="slide-fade">
            <p class="sms-sent" v-if="sent">{{ $t('success') }}</p>
          </transition>
        </div>

        <CustomFormFields
          :fields="pinFields"
          ref="smsPinFields"
          v-model="pin"
          :baseData="baseData"
          @invalid="invalidPinEntities"
          @valid="validPinEntities"
        />

        <div class="code-buttons"
          style="position: relative">
          <div v-show="sending"
            class="sk-three-bounce"
            style="position: absolute; top: 23px; width: 50%;">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
          <button class="social-btn connect sms resend-pin"
            :disabled="resendBtnDisabled"
            @click.prevent="resendSMS()">
            {{ resendBtnDisabled ? $t('resend_pin') + ' ' + `${countdownTime}...` : $t('resend_pin') }}
          </button>
          <button class="social-btn connect connect-now"
            @click.prevent="verifyPin()">
            {{ $t('connect_button') }}
          </button>
        </div>
        <a href="#"
          @click.prevent="goBack()">
          ← {{ $t('back_button') }}
        </a>
      </div>
    </transition>

    <div class="wifi-loader-area" style="text-align: center">
      <transition name="slide-fade">
        <div class="loader" v-if="loading">
          <wifi-loader></wifi-loader>
          <p>{{ $t('connecting') }}</p>
        </div>
      </transition>
    </div>

    <div style="text-align: center">
      <transition name="slide-fade">
        <div class="loader" v-if="connected">
          <p>{{ $t('operation_success') }}</p>
        </div>
      </transition>
    </div>

  </div>
</template>


<script>
import { required } from 'vuelidate/lib/validators'
import WifiLoader from '../../components/loader/wifi-loading'
import AlertMsg from '../../components/errors/alert'
import CustomFormFields from '../fields/custom-fields'

const touchMap = new WeakMap()

export default {
  data(){
    return {
      number: '',
      sending: false,
      enterCode: false,
      resendBtnDisabled: false,
      countdownTime: 60,
      sent: false,
      user_pin: '',
      loading: false,
      connected: false,
      customError: false,
      customErrorMsg: '',
      validating: false,
      user: null,
      pin: null,
      fields: ['Phone'],
      pinFields: ['Phone PIN']
    }
  },
  components: {
    WifiLoader,
    AlertMsg,
    CustomFormFields
  },
  props: ['api', 'wftkn', 'action'],
  computed: {
    baseData(){
      return this.$store.state.baseData
    },
    payload(){
      return {
        ...this.user,
        action: 'sms-verify',
        wftkn: this.wftkn,
        user_pin: this.pin.phone_pin
      }
    },
    smsPayload(){
      return {
        action: this.action,
        wftkn: this.wftkn,
        user_phone: this.user.user_phone,
        country: this.user.country,
        sms_text: this.$t('sms_login_text'),
        ...this.user
      }
    },
    baseData(){
      return this.$store.state.baseData
    },
    primaryButtonStyles() {
      return {
        'border-radius': `${this.radius}px` || '32px',
        'background': this.primaryButtonColor || '#35c300',
        'color': this.primaryButtonTextColor || 'white',
        'justify-content': this.primaryButtonTextAlignment || 'center'
      }
    },
    radius(){
      return this.baseData.loginOptions.styles.buttons.radius
    },
    primaryButtonColor(){
      return this.baseData.loginOptions.styles.primaryButtonColor
    },
    primaryButtonTextColor(){
      return this.baseData.loginOptions.styles.primaryButtonTextColor
    },
    primaryButtonTextAlignment() {
      return this.baseData.loginOptions.styles.primaryButtonTextAlign.value
    }
  },
  mounted(){
    this.getCode();
    this.getCustomFields();
  },
  methods: {
    getCustomFields(){
      if(this.baseData.smsFields){
        this.fields = [...this.fields, ...this.baseData.smsFields.fields]
      }
    },
    getCode(){
      this.code = this.baseData.region ? this.baseData.region.phoneCode : '+1';
    },
    goBack(){
      this.$eventHub.$emit('showTwilio', false)
    },
    resetError(){
      this.customError = false
      this.customErrorMsg = ''
    },
    resendSMS(){
      this.sent = false;
      this.sendSMS();
      this.resendBtnDisabled = true;
      let timer = setInterval(() => {
        if (this.countdownTime > 0) {
          this.countdownTime--
        } else {
          clearInterval(timer)
          this.resendBtnDisabled = false
          this.countdownTime = 60
        }
      }, 1000)
      this.sent = true;
    },
    connectverify() {
      if(this.api.includes('preview')) {
        return
      } else {
        this.validating = true
        this.$refs.smsFields.allValid()
      }
    },
    invalidEntities(){
      this.validating = false
    },
    validEntities(){
      this.validating = false
      this.sendSMS()
    },
    sendSMS(){
      this.resetError()
      this.sending = true;
      axios
        .post(this.api, {}, {params: this.smsPayload})
        .then((res)=>{
            this.sending = false
            if(!res.data.status){
              this.enterCode = false
              this.customError = true
              this.customErrorMsg = this.language[this.lang][res.data.message]
            } else {
              this.enterCode = true
              this.user_phone = res.data.user_phone;
            }
          },
          ((res)=>{
            if(res.response.data.url) {
              window.location.href = res.response.data.url;
            }
            this.sending = false
            this.enterCode = false
            this.customError = true
            this.customErrorMsg = this.$t(res.response.data.message) || res.response.data.message
          })
      );
    },
    verifyPin(){
      this.$refs.smsPinFields.allValid()
    },
    invalidPinEntities(){
      this.loading = false
    },
    validPinEntities(){
      this.loading = true
      this.connect()
    },
    connect(){
      this.resetError()
      axios
        .post(this.api, {}, {params: this.payload})
        .then((res)=>{
          this.loading = false
          if(!res.data.status){
            this.customError = true
            if(res.data.error_message) {
              const errorMap = {
                default: 'pin_error',
              };

              // Error message samples
              // 'No pin code has been given by user to verify.'
              // 'Pin code did not match.'

              if (errorMap[res.data.error_message]) {
                this.customErrorMsg = this.$t(errorMap[res.data.error_message]);
              } else {
                this.customErrorMsg = this.$t(errorMap.default);
              }

            } else {
              this.customErrorMsg = this.$t(res.data.message)
            }
          }
          else{
            this.connected = true
            setTimeout(()=>{
              window.location = res.data.url
            }, 1000)
          }
        });
      }
    },
    watch: {
      sent(){
        if(this.sent){
          setTimeout(()=>{
            this.sent=false;
          }, 3000);
        }
      }
    }
  }
</script>


<style lang="scss">
.wifi-loader-area .wifi-symbol .wifi-circle{
  border-color: #55acee;
}
.sms-sent-holder{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
}
.sms-sent{
    background: #deffd4;
    padding: 10px;
    max-width: 270px;
    border-radius: 5px;
    margin: 0 auto;
    color: #2fbb00;
    text-align: center;
    position: absolute;
    z-index: 1;
    top: 17px;
}
.code-buttons{
    display: flex;
    max-width: 315px;
}
.code-buttons button:first-child{
    margin-right: 5px;
}
.code-buttons button:last-child{
    margin-left: 5px;
    margin-bottom: 10px;
}
.connect.sms{
    background: #35c300;
}
.connect.sms:hover{
     background: #217500;
}
.twilio{

  .phone-code{
      display: flex;
      max-width: 320px;
  }
  .phone-code .country-code{
      width: 30px;
      margin-right: 10px;
  }
}
.resend-pin{
  background: #9c9c9c!important;
}
.resend-pin:hover{
  background: #606160!important;
}
.resend-pin:disabled {
  background: #bababa!important;
}
.resend-pin:disabled:hover {
  background: #bababa!important;
}
.connect-now{
  background: #35c300!important;
}
.connect-now:hover{
  background: #217500!important;
}
</style>
