export default {
  "en": "English",
  "fr": "Français",
  "es": "Español",
  "zh-CN": "简体中文",
  "ja": "日本語",
  "de": "Deutsch",
  "ko": "한국어",
  "it": "Italiano",
  "ru": "Русский",
  "nl": "Nederlands",
  "tr": "Türkçe",
  "pl": "Polski",
  "sv": "Svenska",
  "fi": "Suomi",
  "ro": "Română",
  "el": "Ελληνικά",
  "hu": "Magyar",
  "ar": "العربية",
  "sk": "Slovenčina",
  "uk": "Українська",
  "he": "עברית",
  "id": "Bahasa Indonesia",
  "ms": "Bahasa Melayu",
  "th": "ไทย",
  "sw": "Kiswahili",
  "hi": "हिन्दी",
  "lt": "Lietuvių",
  "sl": "Slovenščina",
  "fil": "Filipino / Tagalog",
  "pa": "ਪੰਜਾਬੀ",
  "az": "Azərbaycanca",
  "ca": "Català",
  "fa": "فارسی",
  "vi": "Tiếng Việt",
  "mk-MK": "Македонски (Македонија)",
  "pt-BR": "Português (Brasil)",
  "pt-PT": "Português (Europeu)",
  "no": "Norsk",
  "nl-NL": "Nederlands (Nederland)",
  "fr-CA": "Français (Canada)",
  "ab": "аҧсуа бызшәа",
  "br": "Brezhoneg",
  "ch": "Chamoru",
  "bm": "Bamanakan",
  "kr": "Kanuri",
  "se": "Davvisámegiella",
  "si": "සිංහල",
  "et-EE": "Eesti (Eesti)",
  "en-CA": "English (Canada)",
  "de-CH": "Deutsch (Schweiz)",
  "el-CY": "Ελληνικά (Κύπρος)"
}
