<template>
  <layout>
    <div style="padding: 10px;" v-if="!covidPositive && !covidNegative">
      <div class="covid-title-text">{{baseData.multilingualOption.showLangOption === "true" ? $t('covid_title') : baseData.branding.covid19TitleText}}</div>
      <div class="covid-symptoms-question">{{ baseData.multilingualOption.showLangOption === "true" ? $t('covid_question') : baseData.branding.covid19QuestionText }}</div>
      <div class="covid-button-container">
        <div class="covid-button covid-negative" @click="proceedToLoginOptions()">{{ $t('generic.no')}}</div>
        <div class="covid-button covid-positive" @click="notAllowToEnterLocation()">{{ $t('generic.yes')}}</div>
      </div>
    </div>

    <div v-if="covidNegative" style="padding: 10px;">
      <div class="covid-negative-title-text">{{ baseData.multilingualOption.showLangOption === "true" ? $t('covid_success') : baseData.branding.covid19SuccessText }}</div>
      <p class="redirect-time">
        {{ timer / 1000 }} s
      </p>
    </div>

    <div v-if="covidPositive" style="padding: 10px;">
      <div class="covid-positive-title-text">{{ baseData.multilingualOption.showLangOption === "true" ? $t('covid_error') : baseData.branding.covid19ErrorText }}</div>
    </div>
  </layout>
</template>

<script>
import Layout from './layout'

export default {
  components: {
    Layout
  },
  data() {
    return {
      timer: "5000",
      covidForm: false,
      covidPositive: false,
      covidNegative: false,
    }
  },
  computed: {
    baseData() {
      return this.$store.state.baseData
    },
  },
  methods: {
    proceedToLoginOptions() {
      this.covidNegative = true;
      window.setInterval(() => {
        if (this.timer !== 0) {
          this.$set(this, 'timer', this.timer - 1000);
        }
      }, 1000);
      setTimeout(()=>{
        this.covidNegative = false;
        this.$router.push('/payment-plans').catch(error => {
          console.info(error.message)
        })
      }, 5000)
    },
    notAllowToEnterLocation() {
      this.covidPositive = true;
      this.covidNegative = false;
    }
  }
}
</script>

<style scoped>
.redirect-time {
  font-size: 12px;
  background: #f4f4f4;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 32px;
  font-weight: 600;
  color: #808080;
  margin-right: 10px;
}

.covid-title-text {
  font-weight: bold;
  margin-bottom: 15px;
  white-space: break-spaces;
  word-wrap: break-word;
}

.covid-negative-title-text {
  font-weight: bold;
  color: #4BB543;
  opacity: 0.6;
}

.covid-positive-title-text {
  font-weight: bold;
  color: #ff3333;
  opacity: 0.6;
}

.covid-symptoms-question {
  font-size: 15px;
  margin-bottom: 15px;
  white-space: break-spaces;
  word-wrap: break-word;
}

.covid-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.covid-button {
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 400;
  width: 100px;
  color:white;
  margin-top: 10px;
  border-radius: 25px;
}

.covid-button:hover {
  cursor: pointer;
}

.covid-negative {
  background: #4BB543;
  white-space: break-spaces;
  word-wrap: break-word;
}

.covid-positive {
  background: #ff3333;
  white-space: break-spaces;
  word-wrap: break-word;
}
</style>

