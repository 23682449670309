import Vue from 'vue';
import Vuex from 'vuex';
import router from './routes/index'
import { i18n } from './lang'
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
      baseData: {},
      postLogin: null,
      wftkn: '',
      api: '',
      logins: [],
      title1: '',
      fields: '',
      textColor: '#000',
      preError: false,
      preErrorPayload: {},
      isRejeted: false,
      welcomeBack: false,
      welcomeBackPayload: {},
      connecting: false,
      freeOver: false,
      plans: [],
      lastPostMessage: '',
      skipToFreeWifi: false,
      plumeLoginErrorMessage: '',
      isPlume: false,
    },
    mutations: {
      skipToFreeWifi: function (state) {
          state.skipToFreeWifi = true;
      },
      setBaseData: function (state, data) {
        state.baseData = data;
      },
      clearBaseData: function (state) {
        state.baseData= {};
      },
      setPostLogin(state, data){
        state.postLogin = data;
      },
      setPlumeLoginError(state, data){
        state.plumeLoginErrorMessage = data;
      },
      setWftkn(state, data){
        state.wftkn = data;
      },
      setApi(state, data){
        state.api = data;
      },
      setLogins(state, data) {
        state.logins = data;
      },
      setTitle1(state, data) {
        state.title1 = data;
      },
      setFields(state, data) {
        state.fields = data;
      },
      setTextColor(state, data) {
        state.textColor = data;
      },
      setPreError(state, data) {
        state.preError = data;
      },
      setPreErrorPayload(state, data) {
        state.preErrorPayload = data;
      },
      setIsRejeted(state, data) {
        state.isRejeted = data;
      },
      setWelcomeBackPayload(state, data) {
        state.welcomeBackPayload = data;
      },
      setWelcomeBack(state, data) {
        state.welcomeBack = data;
      },
      setConnecting(state, data) {
        state.connecting = data;
      },
      setFreeOver(state, data) {
        state.freeOver = data;
      },
      setPaymentPlans(state, data) {
        state.plans = data;
      },
      setLastPostMessage(state, data) {
        state.lastPostMessage = data;
      },
      setIsPlume(state, data) {
        state.isPlume = data;
      },
    },
    getters: {
      postLogin: state => {
        return state.postLogin;
      },
      isPlume: state => {
        return state.isPlume
      }
    },
    actions: {
      fetchPostLogin(context, wftkn) {
        axios.get('/index.php?controller=landingpage&action=post-login-payload&wftkn='+wftkn).then(res=>{
          context.commit('setPostLogin', res.data);
          const internConnectionCheked = sessionStorage.getItem('internetConnectChecked') ? false : true
          if(context.state.postLogin && context.state.postLogin.status  && internConnectionCheked){
            router.push('thankyou');
          }else if(context.state.postLogin.payload.has_interstitial && !internConnectionCheked){
            router.push('interstitial');
          }else if(!context.state.postLogin.payload.has_interstitial && !internConnectionCheked){
            router.push('thankyou');
          }
        });
      },
      fetchPlumeLogin(context, data) {
        axios.post(`/?controller=proxy-request&action=fetchPlumeLogin&url=${data.url}&body=${JSON.stringify(data.body)}`)
          .then(res=>{
            if (data.redirect_url) {
              window.location = data.redirect_url;
            } else {
              router.push('thankyou');
            }
          })
          .catch(error => {
            console.log('Proxy request failed', error)

            if (error.response && error.response.data && error.response.status === 400) {
              context.commit('setPlumeLoginError', `Request failed due to undefined action`);
            } else if (error.response && error.response.data && error.response.status === 417 && error.response.data.request_id) {
              context.commit('setPlumeLoginError', `Request failed. Request ID: ${error.response.data.request_id}`);
            } else {
              context.commit('setPlumeLoginError', `Request failed`);
            }
          });
      },
      agreeConsent(context, data) {
        return new Promise((resolve)=>{
          axios.post('/index.php?controller=landingpage&action=post-register-user-consent&UserConsent='+data.consent+'&wftkn='+data.wftkn).then(res=>{
            resolve();
          });
      })

      }
    }
})
