<template>
  <div class="Mw-PostLogin-thankyou-custom-html" v-if="html.enabled">
    <div class="Mw-PostLogin-thankyou-custom-html_elems" v-html="html.html"></div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object
    }
  },
  computed: {
    html(){
      return !!this.option.add_html && this.option.add_html
    }
  }
}
</script>

<style scoped lang="scss">
.Mw-PostLogin-thankyou-custom-html{
  padding: 20px;
  max-width: 100%;
  &_elems{
    max-width: 100%;
    p{
      font-size: 14px;
      line-height: 20px;
      img{
        max-width: 100%;
      }
    }
  }
}
</style>
<style lang="scss">
.Mw-PostLogin-thankyou-custom-html{
  img{
    max-width: 100%;
  }
  h1{
    font-size: 28px;
  }
  h2{
    font-size: 22px;
  }
  h3{
    font-size: 18px;
  }
  h4{
    font-size: 16px;
  }
  h5{
    font-size: 14px;
  }
  h6{
    font-size: 10px;
  }
  .ql-align-center{
    text-align: center;
  }
  .ql-align-right{
    text-align: right;
  }
}
</style>
