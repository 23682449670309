<template>
    <div>
        <div class="welcome-back">
            <div class="user-holder">
                <div  v-if="payload.picture_url" class="wb-avatar">
                    <!--<i class="fa fa-user-o" aria-hidden="true"></i>-->
                    <img :src="payload.picture_url" alt="">
                </div>
                <div class="welcome-avatar"></div>
                <h3 v-if="payload.first_name">{{ $t('welcome_back') }}</h3>
                <h3 v-if="payload.first_name">{{ payload.first_name }}</h3>

                <h3 v-if="!payload.first_name">{{ $t('welcome_back').replace(/,\s?$/, '') }}</h3>
            </div>

            <wifi-loader v-if="loading"></wifi-loader>
            <p v-if="loading" class="connecting-text">{{ $t('connecting') }}</p>

            <a v-if="!loading" @click="sendConnectRequest()" :href="'/index.php?controller=social-login'+'&action='+action+'&wftkn='+wftkn" class="social-btn connect">{{ $t('connect_button') }}</a>
        </div>
    </div>
</template>

<script>
    import WifiLoader from './../loader/wifi-loading.vue';
    export default {
        props:['payload', 'api', 'action', 'wftkn', 'baseData'],

        components: {
            WifiLoader
        },

        data(){
            return {
                loading: false
            }
        },

        mounted()
        {
            this.animateSocial();
        },

        methods: {
            sendConnectRequest()
            {
                this.loading = true;
            },

            animateSocial()
            {

                setTimeout(()=>{
                    let btns = document.getElementsByClassName('social-btn');
                    for (let i = 0; i < btns.length; i++) {
                        setTimeout(()=>{
                            if(btns[i])
                            {
                                btns[i].style.opacity = 1;
                                btns[i].style.marginLeft = '0';
                            }
                        }, 200+(i*50));
                    }
                }, 20);


            },
        }
    }
</script>


<style>
    .welcome-back .wifi-symbol .wifi-circle{
        border-color: #55acee;
    }
    .welcome-back .connecting-text{
      text-align: center;
    }
    .welcome-back{
        text-align: center;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .welcome-back .connect{
        opacity: 0;
        margin-left: -20px;
        /*margin: 0 auto;*/
    }
    .user-holder{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin: 20px 0 20px 0;
    }
    .user-holder h3{
        padding: 0;
        margin: 2px;
        letter-spacing: 0.05em;
        font-size: 20px;
    }
    .wb-avatar{
        height: 72px;
        width: 72px;
        background: #ccc;
        border-radius: 50%;
        margin: 20px 0 10px 0;

        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        color: #fff;
        overflow: hidden;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 0px 5px 0px;
        border: 4px solid #fff;
    }
    .wb-avatar img{
        width: 100%;

    }
</style>
