<template>
  <div class="pay-form stripe-form">
    <div class="review">
      <transition name="slide-fade">
        <h2 class="success" v-if="success">
          Payment was successful!
        </h2>
      </transition>
      <h3>
        {{selectedPlan.humanTime}} for
        <span class="price">
          {{(selectedPlan.amount!==0) ? getAmount(selectedPlan) : 'FREE'}}
        </span>
      </h3>
      <p>Get {{selectedPlan.humanTime}} of Internet right now!</p>
    </div>

    <div class="error-msg-area">
      <alert-msg
        v-if="this.customError"
        :errorMsg="customErrorMsg">
      </alert-msg>
    </div>

    <transition name="slide-fade">
      <div v-if="!success" class="custom-area">
        <form id="billing-form">
          <CustomFormFields
            class="Payment-custom-fields"
            :fields="info.fields"
            ref="paymentCustomFields"
            v-model="user"
            :baseData="$store.state.baseData"
            @invalid="invalidEntities"
            @valid="validEntities"
          />

          <div class="number">
            <i class="fa fa-lock"></i>
            <i class="fa cardtype"
            :class="'fa-cc-'+((cardType=='diners') ? 'diners-club' : cardType)">
            </i>
            <form-field type="tel"
              :class="{'input-error': (stripeErrors && stripeErrors.param=='number')}"
              v-model="card.cardNumber"
              placeholder="Credit Card Number"
              :options="{creditCard: true, onCreditCardTypeChanged: function(type) { cardType = type }}">
            </form-field>
          </div>
          <div class="expiry">
            <form-field type="tel"
              :class="{'input-error': (stripeErrors && stripeErrors.param=='exp_month')}"
              v-model="card.expiry"
              placeholder="MM/YY"
              :options="{date: true, datePattern: ['m', 'y']}">
            </form-field>
            <form-field type="tel"
              :class="{'input-error': (stripeErrors && stripeErrors.param=='cvc')}"
              v-model="card.cardCode"
              placeholder="CVC"
              :options="{date: true, datePattern: ['Y']}">
            </form-field>
          </div>

          <div class="error-msg-area" v-if="cardErrors">
            <alert-msg
              v-for="(error, index) in cardErrors"
              :key="index"
              :errorMsg="error.text">
            </alert-msg>
          </div>
          <div class="error-msg-area" v-if="stripeErrors">
            <alert-msg
              :errorMsg="stripeErrors.message">
            </alert-msg>
          </div>
          <div class="error-msg-area" v-if="customError">
            <alert-msg
              :errorMsg="customErrorMsg">
            </alert-msg>
          </div>

          <div class="card-submit">
            <button v-if="!loading"
              @click.prevent="processPayment()">
              <i class="fa fa-lock"></i>
              Pay Now
            </button>
            <button v-if="loading"
              disabled>
              <div class="loader"></div>
            </button>
          </div>
        </form>

      </div>
    </transition>
  </div>
</template>

<script>
  import {required, minLength, maxLength, email, numeric} from 'vuelidate/lib/validators'
  import CustomFormFields from '../fields/custom-fields'
  import AlertMsg from '../errors/alert.vue'
  import FormField from './cleave.vue'
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";

  const touchMap = new WeakMap();

  export default{
    components: {
      AlertMsg,
      FormField,
      vSelect,
      CustomFormFields
    },

    data(){
      return {
        card: {
          cardNumber: "",
          month: "",
          year: "",
          cardCode: "" ,
          expiry: ""
        },
        authData: {
          clientKey: null,
          apiLoginID: null
        },
        stripePublishableKey: null,
        cardErrors: [],
        loading: false,
        success: false,
        stripeErrors: null,
        cardType: '',
        customError: false,
        customErrorMsg: '',
        user: null,
        token: null
      }
    },
    computed: {
      stripeData: function() {
        return {
          number: this.card.cardNumber,
          cvc: this.card.cardCode,
          exp_month: this.card.month,
          exp_year: this.card.year
        }
      },
      payload(){
        return {
          ...this.user,
          action: this.action,
          wftkn: this.wftkn,
          token: this.token,
          plan_data: this.planData,
          type: this.type || 'stripe',
          currency: this.selectedPlan.currency
        }
      },
    },
    props: ['selectedPlan', "planData", "type", "info", "wftkn", "action", "api"],

    watch:{
      stripeData(){
        this.stripeErrors=null;
      },
      card:{
        handler() {
          this.card.month = this.card.expiry.substring(0, 2);
          this.card.year = this.card.expiry.substring(2, 5);
        },
        deep: true
      },
      success(){
        (this.success) ? this.$eventHub.$emit('hidePaymentGoBack', true) : '';
      }
    },
    mounted(){
      this.setupStripe();
      this.setupAuthnet();
    },
    methods:{
      getAmount(item){
        if(item.currency && item.currency.symbol){
          return item.currency.symbol + (item.amount/100).toFixed(2)
        }
        return '$' + (item.amount/100).toFixed(2)
      },
      showCard(){

      },
      clearErrors(){
        this.stripeErrors = null;
      },

      setupStripe(){
          Stripe.setPublishableKey(this.info.clientKey);
      },
      setupAuthnet(){
        this.authData.apiLoginID = this.info.clientID;
        this.authData.clientKey = this.info.clientKey;
      },
      invalidEntities(){
        this.loading = false
      },
      validEntities(){
        this.loading = true;
        this.success = false;
        (this.type=='authnet') ? this.authnetPayment() : this.stripePayment();
      },

      processPayment(){
        this.$refs.paymentCustomFields.allValid()
      },

      stripePayment(){
        let vm = this;
        var formData = new FormData(document.querySelector('form'))
        Stripe.card.createToken(this.stripeData, stripeResponseHandler)

        function stripeResponseHandler(status, res){
          vm.loading = false
          if(status!==200){
            vm.stripeErrors = res.error
            (status===400) ? vm.stripeErrors.text = res.error.message : ''
            (status===500) ? vm.stripeErrors.text = 'Something went wrong! Please try again!' : ''
          }else{
            vm.makePaymentCall(res.id)
          }
        }
      },

      authnetPayment(){
        let vm = this
        this.cardErrors = []
        let secureData = {
          cardData: this.card,
          authData: this.authData
        };

        Accept.dispatchData(secureData, responseHandler)

        function responseHandler(res){
          if(res.messages.resultCode=="Error"){
            vm.cardErrors = res.messages.message
            vm.loading = false
          }
          else{
            vm.makePaymentCall(res.opaqueData.dataValue)
          }
        }
      },

      makePaymentCall(token){
        this.token = token
        this.loading = true;
        axios
          .post(this.api, {}, {params: this.payload})
          .then((res) => {
              if(res.status===500 || res.status===404){
                this.customError = true
                this.customErrorMsg = 'Something went wrong! Please try again!'
              }
              else if(res.status===200){
                if(!res.data.status)
                {
                  this.customError = true
                  this.customErrorMsg = this.$t(res.data.message)
                }
                else
                {
                  this.connected = true
                  this.success = true
                  setTimeout(()=>{
                      window.location = decodeURIComponent(res.data.url)
                  }, 2000)
                }
              }
              this.loading = false
          })
      },
    }
  }
</script>

<style lang="scss">
  #billing-form{
    /*max-width: 350px;*/
    margin: 0 auto;
  }
  #card-element{
    padding: 30px;
  }
  .Payment-custom-fields{
    &.custom-fields{
      input{
        max-width: 100%;
      }
      margin-bottom: 10px;
    }
  }
  .number{
    position: relative;
  }
  .number i{
    position: absolute;
    right: 15px;
    top: 16px;
    color: #606060;
    font-size: 20px;
  }
  .number i.cardtype{
    right: 33px;
    font-size: 18px;
    top: 17px;
  }
  .stripe-form{
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }

  .stripe-form input{
    padding: 17px;
    border-radius: 32px;
    font-size: 14px;
    outline: none;
    width: 100%;
    letter-spacing: 1px;
    box-sizing: border-box;
   -webkit-box-sizing:border-box;
   -moz-box-sizing: border-box;
   margin-bottom: 10px;
   min-width: 0;
   font-weight: 700;
   box-shadow: 2px 0 20px 0 rgba(0, 0, 0, 0.1);
   border: 1px solid #ccc;
  }

  .stripe-form .expiry{
    display: flex;
    justify-content: space-around;
  }
  .stripe-form .expiry input:first-child{
    margin-right: 3px;
  }
  .stripe-form .expiry input:last-child{
    margin-left: 3px;
  }
  .stripe-form button{
    background: #64af0c;
    padding: 15px;
    border: 1px solid #64af0c;
    border-radius: 32px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    outline: none;
    transition: 0.3s all;
    width: 100%;
    cursor: pointer;
  }
  .stripe-form button:hover{
      background: #75c21b;
  }

  .stripe-form .price{
    color: #64af0c;
  }
  .stripe-form h3{
    margin: 0;
  }
  .stripe-form p{
    margin: 0;
  }

  .stripe-form .review{
    margin-bottom: 20px;
  }

  .stripe-form .card-area{
    margin-bottom: 15px;
    font-size: 18px;
  }

  .stripe-form input.input-error{
      border-color: red;
      background: #f7eaea;
  }


  .stripe-form input.input-error {
      animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
  }
  .card-submit{
    position: relative;
  }
  .card-processing{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .review .success{
    color: #64af0c;
  }


  .v-select .open-indicator {
      position: absolute;
      bottom: 10px!important;
      right: 25px!important;
  }
  .input-error .dropdown-toggle {
      border: 1px solid red !important;
      background: #f6e7e7 !important;
  }

  .phone-code {
      display: flex;
      max-width: 100%;
  }

  .phone-code .country-code {
      width: 100px;
      margin-right: 10px;
  }
  .v-select.open .dropdown-toggle{
      border-bottom-right-radius: 0!important;
      border-bottom-left-radius: 0!important;
  }

  .v-select .dropdown-toggle {
      padding: 8px 20px !important;
      font-size: 16px;
      border: 1px solid #cccccc!important;
      border-radius: 32px !important;
      width: 100%;
      margin: 10px 0 0 0;
      outline: none;
      background: #fff !important;
  }

  .v-select .open-indicator {
      position: absolute;
      bottom: 12px;
      right: 20px;
  }

  .v-select input[type=search], .v-select input[type=search]:focus {

  }

  .v-select.dropdown {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
  }

  .v-select.searchable .dropdown-toggle {
      position: relative;
  }

  .v-select .dropdown-menu {
      width: 100%;
  }

  .select-vue {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
  }

  .v-select .selected-tag {
      background-color: white;
      border: none;
      white-space: nowrap;
  }


  .pay-form .v-select.dropdown{
    max-width: 100%;
  }

  .pay-form .v-select .dropdown-toggle {
    border: 1px solid transparent;
    max-width: 100%;
    box-shadow: none;
  }

  .pay-form .v-select input[type=search]{
    width: auto!important;
  }
  .pay-form .v-select.searchable .dropdown-toggle{
    display: flex;
  }
  .pay-form .v-select .dropdown-toggle .clear{
    display: none;
  }

  .pay-form .loader {
    font-size: 10px;
    margin: 0 auto;
    text-indent: -9999em;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
.pay-form .loader:before {
    width: 50%;
    height: 50%;
    background: #ffffff;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
.pay-form .loader:after {
    background: #64af0c;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }


</style>
