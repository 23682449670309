<template>
  <div class="app-container">
    <router-view></router-view>
  </div>
</template>

<script>
import { setCampaignDefaults } from '../helpers';
import { loadLocaleMessages } from '../lang/index'
import langList from "../lang/lang_list";
import _ from 'lodash'

  export default{
    data() {
      return {
        wftkn: '',
        api: '/index.php?controller=social-login',
        i18nMessegesClone: null,
      }
    },
    computed: {
      baseData(){
        return this.$store.state.baseData;
      },
      isPaidCampaign(){
        return !!(this.baseData.paymentPlans);
      },
      paidSessionOver(){
        return (this.isRejeted && this.isPaidCampaign);
      },
      isRejeted() {
        return this.$store.state.isRejeted
      },
      consentDone(){
        return !!(this.$route.query.consent);
      },
      showConsentForm() {
        return this.$store.state.showConsentForm
      },
    },
    methods: {
      onCampaignChange(e) {
        const eventId = e.data.event_id;
        const dataPresent = !!e.data.data;
        if (['campaign', 'thankyou', 'interstitial'].includes(eventId)) {
          this.handlePostMessage(e)
        }
        if(e.data.event_id === "campaign" && dataPresent) {
          if (this.$i18n.locale !== e.data.data.langCode) {
            this.$i18n.locale = e.data.data.langCode;
          }

          if (e.data.openCustomField) {
            this.$eventHub.$emit('showCustom', e.data.openCustomField)
          } else {
            this.$eventHub.$emit('showCustom', e.data.openCustomField)
          }
          this.handleCamapignEvent(e);
        }
        else if(e.data.event_id === "thankyou" && dataPresent) {
          this.handleThankYouEvent(e);
        }
        else if(e.data.event_id === "interstitial" && dataPresent) {
          this.handleInterstitialEvent(e);
        }
      },
      handleCamapignEvent(e) {
        e.data.data.backButton = true;
        const { currentRoute: { path: curPath }} = this.$router;
        if (e.data.data.branding.activeTab === "covid19" && e.data.data.branding.covid19PageEnabled === true) {
          let baseData = this.baseData
          baseData.branding.covid19PageEnabled = true
          this.$store.commit('setBaseData', baseData);
          if(curPath !== "/covid") {
            this.$router.push('/covid').catch(error => {
                  console.info(error.message)
                })
          }
        } else {
          if(!!e.data.data.paymentPlans && e.data.data.paymentPlans.plans.length > 0) {
            this.$router.push('/payment-plans').catch(err => {
              if (err.name !== 'NavigationDuplicated') {
                logError(err);
              }
            });
          } else if(!!e.data.data.socialMediaOrder && curPath !== "/social-logins"){
            this.$router.push('/social-logins').catch(error => {
                  console.info(error.message)
                })
          }
        }
        this.setup(e.data.data);
      },
      handleThankYouEvent(e) {
        if(window.location.hash.substr(2) !== 'thankyou') {
          this.$router.push('/thankyou').catch(error => {
                  console.info(error.message)
                })
        }
        this.setup(e.data.data);
      },
      handleInterstitialEvent(e) {
        // this is being called twice for some reason, and printing an error in console. this will prevent the error for now
        if (this.$router.currentRoute.path != '/interstitial') {
          this.$router.push('/interstitial').catch(error => {
            console.info(error.message)
          })
          this.setup(e.data.data);
        }
      },
      handlePostMessage(e) {
        const lastPostMessage = e.data && JSON.stringify(e.data);
        this.$store.commit('setLastPostMessage', lastPostMessage);
        if(e.data.domain) {
          this.api = e.data.domain + "?preview=1";
        }
      },
      setLanguage(){
        const canBeNullThroughCustomLanguages = [
          'title_text',
          'sub_title_text',
          'footer_text',
          'support',
        ];
        if ((this.baseData.multilingualOption.showLangOption === 'true' || this.baseData.multilingualOption.showLangOption === true) && this.baseData.multilingualOption.languageSettings) {
          for (var language in this.baseData.multilingualOption.languageSettings.customLanguages) {
            var messages = { ...this.i18nMessegesClone[language] }
            for (let key in this.baseData.multilingualOption.languageSettings.customLanguages[language]) {
              if (!_.isEmpty(this.baseData.multilingualOption.languageSettings.customLanguages[language][key])) {
                messages[key] = this.baseData.multilingualOption.languageSettings.customLanguages[language][key]
              }
              if(canBeNullThroughCustomLanguages.includes(key) && _.isString(this.baseData.multilingualOption.languageSettings.customLanguages[language][key])){
                messages[key] = this.baseData.multilingualOption.languageSettings.customLanguages[language][key]
              }
            }
            this.$i18n.setLocaleMessage(language,messages);
          }
        }
        else {
          var language = _(this.baseData).get('multilingualOption.singleLanguageTexts.lang_code')
            ?_(this.baseData).get('multilingualOption.singleLanguageTexts.lang_code')
            : this.baseData.langCode
          var messages = { ...this.i18nMessegesClone[language] }
          for (let key in this.baseData.multilingualOption.singleLanguageTexts) {
            if (!_.isEmpty(this.baseData.multilingualOption.singleLanguageTexts[key])) {
              messages[key] = this.baseData.multilingualOption.singleLanguageTexts[key]
            }
            if(canBeNullThroughCustomLanguages.includes(key)){
              messages[key] = _.toString(this.baseData.multilingualOption.singleLanguageTexts[key])
            }
          }
          this.$i18n.setLocaleMessage(language, messages);
        }

        let plume = this.getValue('is_plume') || this.getValue('plume_post');

        const localStorageLanguage = localStorage.getItem('selectedLanguage');

        let isError = false;
        try {
          JSON.parse(localStorageLanguage);
        } catch (e) {
          isError = true;
          localStorage.removeItem('selectedLanguage');
        }

        let selectedLanguage = isError === false
          ? JSON.parse(localStorageLanguage)
          : {}

        const pathName = window.location.pathname;
        const parts = pathName.split('/');
        const pageName = parts.filter(part => part.includes('.html'))[0];

        if (this.baseData.multilingualOption.showLangOption === 'true' || this.baseData.multilingualOption.showLangOption === true || plume) {
          if (pageName && selectedLanguage && selectedLanguage[pageName]) {
            this.$i18n.locale = selectedLanguage[pageName];
            return;
          }

          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);

          const lang = urlParams.has('availableLanguages')
            ? urlParams.get('availableLanguages').split(',')
            : [];

          const result = lang.find(langCode => {
            return this.$i18n.messages[langCode] !== undefined;
          });

          if (result) {
            this.$i18n.locale = result;
          } else {
            if (navigator && navigator.languages && window.location.hostname !== 'localhost') {
              const navigatorResult = navigator.languages.find(langCode => {
                return this.$i18n.messages[langCode] !== undefined;
              });

              if (navigatorResult) {
                this.$i18n.locale = navigatorResult;
              } else {
                this.$i18n.locale = this.baseData.langCode || 'en';
              }
            } else {
              this.$i18n.locale = this.baseData.langCode || 'en';
            }
          }

          let selectedStoredLanguage = Object.assign({}, selectedLanguage || {});
          if (pageName) {
            selectedStoredLanguage[pageName] = this.$i18n.locale;
          }
          localStorage.setItem('selectedLanguage', JSON.stringify(selectedStoredLanguage))
        } else {
          this.$i18n.locale = this.baseData.langCode || 'en';
          localStorage.setItem('selectedLanguage', JSON.stringify(this.$i18n.locale))
        }
      },
      setHeaderFooter(){
        if(this.baseData.isAdvertising=='true'){
          let ckeditor = document.createElement('script');
          ckeditor.setAttribute('src',"https://cdn.embedly.com/widgets/platform.js");
          ckeditor.setAttribute('async', true);
          ckeditor.setAttribute('charset', 'UTF-8');
          document.head.appendChild(ckeditor);

          (function(w, d) {
            var id = 'embedly-platform',
              n = 'script';
            if (!d.getElementById(id)) {
              w.embedly = w.embedly || function() {
                (w.embedly.q = w.embedly.q || []).push(arguments);
              };
              var e = d.createElement(n);
              e.id = id;
              e.async = 1;
              e.src = 'https://cdn.embedly.com/widgets/platform.js';
              var s = d.getElementsByTagName(n)[0];
              s.parentNode.insertBefore(e, s);
            }
          })(window, document);
        }
      },
      addLayerStyle(){
        if(this.baseData.branding.layer.enabled){
          const styleElem = document.head.appendChild(document.createElement("style"));
          styleElem.innerHTML = ".background-image:before {background: "+this.baseData.branding.layer.layerColor+";}";
        }
      },
      getEmailFields(campaignInfo){
        if(campaignInfo.customEmailModal){
          const fields = campaignInfo.customEmailModal.filter((n)=>{ return n!== ''});
          this.$store.commit('setFields', fields);
        }
      },
      setTextColor(){
        if(this.baseData.termsConditionModal.campaignTextColor){
          const textColor = this.baseData.termsConditionModal.campaignTextColor;
          this.$store.commit('setTextColor', textColor)
        }
      },
      addBackgroundColor(){
        if(this.baseData.branding.background=="background-color: #ffffff") {
          this.baseData.branding.background = "background-color: #f2f3f9";
        }
        if (this.baseData.branding.background.substr(0, 'background'.length) === 'background') {
          this.baseData.branding.background = this.baseData.branding.background.substr(this.baseData.branding.background.indexOf(':') + 1)
        }
      },
      checkUserBlocked(){
        let reject = this.getValue('rej');

        if(reject){
          this.$store.commit('setIsRejeted', true);
          this.$store.commit('setPreError', true);
          this.$store.commit('setPreErrorPayload', JSON.parse(decodeURIComponent(atob(reject))));
        }
      },
      checkIsPlumeCaptivePortal() {
        let isPlume = !!localStorage.getItem('isPlume');

        if (!isPlume) {
          isPlume = !!this.getValue('is_plume');
        }

        if (isPlume) {
          localStorage.setItem('isPlume', true);
        }
      },
      checkWelcomeBack(){
        let wb = this.getValue('wb');
        if(wb){
          this.$store.commit('setWelcomeBack', true)
          const welcomeBackPayload = JSON.parse(atob(decodeURIComponent(wb)));
          this.$store.commit('setWelcomeBackPayload', welcomeBackPayload)
          if((welcomeBackPayload.first_name==="No Name") || (welcomeBackPayload.first_name==="") || (welcomeBackPayload.first_name==="Free")){
            welcomeBackPayload.first_name = '';
            this.$store.commit('setWelcomeBackPayload', welcomeBackPayload)
          }
        }
      },
      checkPlumeLogin(){
        let plume = this.getValue('plume_post');

        if (plume) {
          let plume_data = JSON.parse(decodeURIComponent(atob(plume)));
          this.$store.commit('setConnecting', true)
          this.$store.dispatch('fetchPlumeLogin', plume_data);
        }
      },
      checkForPlans(){
        (this.baseData.paymentPlans) ? this.$store.commit('setPaymentPlans', this.baseData.paymentPlans.plans) : '';
      },
      checkFreeOver(){
        let paid = this.getValue('paidonly');

        const freeOver = (paid) ? true : false;
        this.$store.commit('setFreeOver', freeOver)
      },
      checkBaseData(){
        if(this.baseData.headerUrl && this.baseData.headerUrl!="" && !Array.isArray(this.baseData.headerUrl)){
          this.baseData.headerUrl = [this.baseData.headerUrl];
        }
        (this.baseData.paymentPlans && !this.baseData.paymentPlans.fields) ? this.baseData.paymentPlans.fields = [] : '';
      },
      checkPostLogin(){
        let postLogin = this.getValue('postLogin');
        if(postLogin){
          this.$store.commit('setConnecting', true)
          this.$store.dispatch('fetchPostLogin', this.wftkn);
        }
      },
      checkConsentDone(){
        (this.consentDone) && this.$router.push('/consent');
      },
      getValue(name){
        name = name.toLowerCase();
        const url = new URL(
          window.location.href
        );

        var result = url.searchParams.get(name);

        if(result)
          return result;

        return "";
      },
      animateLogo(){
        let logo = document.getElementsByClassName('brand-logo')[0];

        if(logo){
          let originalheight = logo.clientHeight;
          let j=1;
          for(let i=originalheight; i>40; i-- ){
            setTimeout(()=>{
                logo.style.height = i +'px';
            }, 2+(j*5));
            j++;
          }
        }
      },

      checkArubaLogin(){
        let aruba = this.getValue('post');

        if(aruba){
          this.$store.commit('setConnecting', true)
          let arubaPayload = JSON.parse(decodeURIComponent(atob(aruba)));
          this.doFormSubmit(arubaPayload, 0);
        }
      },

      doFormSubmit(arubaPayload, url){
        // If we got an array of post URLs, try ajax with them
        if (Array.isArray(arubaPayload.post_url)) {
          return this.doAjaxFormSubmit(arubaPayload, url)
        }
        let form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', arubaPayload.post_url);
        for(let key in arubaPayload) {
          if(arubaPayload.hasOwnProperty(key)) {
            let hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", arubaPayload[key]);

            form.appendChild(hiddenField);
          }
        }
        document.body.appendChild(form);
        form.submit();
      },
      doAjaxFormSubmit(arubaPayload, url){
        if(!!arubaPayload.post_url[url]) {
          let dataPost = Object.assign({}, arubaPayload);
          delete dataPost.post_url;
          axios({
            method: 'post',
            url: arubaPayload.post_url[url],
            //It is necessary to axios identify and respect Content-Type 'application/x-www-form-urlencoded'
            data: qs.stringify(dataPost),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
          })
          .then(res => {
              if((res.status === 200 || res.status === 301 || res.status === 302) && !!res.data) {
                if (!!arubaPayload.url) {
                  window.location.href = arubaPayload.url
                }
                return;
              }
          }).catch(error => {
              this.doAjaxFormSubmit(arubaPayload,url+1);
          });
        } else {
          // fall back to old form submit method if we've tried all the other URLs
          arubaPayload.post_url = arubaPayload.post_url[0]
          this.doFormSubmit(arubaPayload)
        }
      },
      setup(campaignInfo) {
        const baseData = setCampaignDefaults(campaignInfo)
        this.$store.commit('setBaseData', baseData);
        this.$store.commit('setApi', this.api);
        if( _.isNil(this.i18nMessegesClone)){
          this.i18nMessegesClone = loadLocaleMessages()
        }
        this.checkIsPlumeCaptivePortal();

        this.setLanguage();

        const logins= campaignInfo.socialMediaOrder.split('&');
        this.$store.commit('setLogins', logins);
        this.$store.commit('setTitle1', campaignInfo.headerHtml);

        this.setHeaderFooter();

        this.getEmailFields(campaignInfo);

        this.setTextColor();

        this.addBackgroundColor();

        this.addLayerStyle();

        if(this.baseData.siteTitle) document.title = this.baseData.siteTitle;

        this.checkUserBlocked();

        this.checkWelcomeBack();

        this.checkPlumeLogin();

        this.checkArubaLogin();

        this.checkForPlans();

        this.checkFreeOver();

        this.checkBaseData();

        this.checkPostLogin();

        this.checkConsentDone();

        this.animateLogo();
      }
    },
    mounted(){
      if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
        this.$router.push("/")
      }
      //this.wftkn = this.getValue('wftkn');
      const campaignInfo = JSON.parse(atob(base64data));
      // If we don't have a wftkn, and we aren't running a local build, then run in preview mode
      if(window.location.host === 'localhost:9000' || !campaignInfo.livePreview) {
        this.$store.commit('setWftkn', this.wftkn);
        this.setup(campaignInfo);
      } else {
        this.wftkn = '5f5a01d288036';
        this.$store.commit('setWftkn', this.wftkn);
        window.addEventListener("message", this.onCampaignChange, false);
      }
    },
    beforeDestroy(){
      window.removeEventListener("message", this.onCampaignChange, false);
    },
  }
</script>

<style>
  .app-container{
    height: -webkit-fill-available;
    display: flex;
    justify-content: center;
    font-family: 'Avenir Next';
    height: unset;
    min-height: 100vh;
  }
</style>
