import Vue from "vue";
import VueI18n from "vue-i18n";
import langLocalNames from './lang_list'
import _ from 'lodash'

Vue.use(VueI18n);

if (window.base64data === undefined) {
  window.base64data = btoa(JSON.stringify({
    multilingualOption: {
      showLangOption: true
    },
    customLanguages: {},
  }))
}

const debugGetValue = (name) => {
  name = name.toLowerCase();
  const url = new URL(
    window.location.href
  );

  var result = url.searchParams.get(name);

  if(result)
    return result;

  return "";
};

let campaignInfo = JSON.parse(atob(base64data));

const isMultilingual = _(campaignInfo).get('multilingualOption.showLangOption') === true
  || _(campaignInfo).get('multilingualOption.showLangOption') === 'true';
const payloadVersion = _(campaignInfo).get('version')
  ? _(campaignInfo).get('version')
  : null;
let hasCustomLanguages = campaignInfo.customLanguages !== undefined;
if (hasCustomLanguages && Array.isArray(campaignInfo.customLanguages)) {
  hasCustomLanguages = false;
}
const debugIndex = false;
const debugLogger = (msg, ctx = undefined) => {
  if (debugIndex && !!!ctx) console.log(msg)
  if (debugIndex && !!ctx) console.log(msg, ctx)
}
debugLogger('Debug enabled')

function loadLocaleMessages() {
  const locales = require.context(
    "./",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );

  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
      messages[locale]['language_name'] = langLocalNames[key];
    }
  });

  messages.pt = locales('./pt-PT.json');
  messages.jp = locales('./ja.json');

  return messages;
}

const messages = loadLocaleMessages()

const defaultEmptyTranslations = [
  'sub_title_text',
  'footer_text',
];

for (let message in messages) {
  for (let emptyKey of defaultEmptyTranslations) {
    messages[message][emptyKey] = '';
  }
}

const fallbackLocale = 'en';
let locale = campaignInfo.langCode || fallbackLocale;

const messages_default = messages[fallbackLocale]

// Set all missed keys from ENGLISH
for (let message in messages) {
  for (let message_default in messages_default) {
    if (!!!messages[message][message_default]) {
      messages[message][message_default] = messages_default[message_default]
    }
  }
}

// Only whitelisted keys can obtain null values from the custom languages
const canBeNullThroughCustomLanguages = [
  'title_text',
  'sub_title_text',
  'footer_text',
  'support',
];

// Not all payloads have the `customLanguages` key
const customLanguages = hasCustomLanguages
  ? campaignInfo.customLanguages
  : {};

// Customize
// We trust empty values in the customLanguages if they are whitelisted to be empty.
// This is a safety for e.g. login btn texts to not be empty
for (let language in customLanguages) {
  for (let message in campaignInfo.customLanguages[language]) {
    const isNullOrUndefined = campaignInfo.customLanguages[language][message] === null || campaignInfo.customLanguages[language][message] === undefined;
    if (isNullOrUndefined === false) {
      messages[language][message] =
        campaignInfo.customLanguages[language][message]
    }

    if (isNullOrUndefined && canBeNullThroughCustomLanguages.includes(message)) {
      console.log(`language: ${language}; message: ${message}`, {messages, campaignInfo});
      messages[language][message] = campaignInfo.customLanguages[language][message]
    }
  }
}

// Processing fallbacks now. Together with legacy
const singleLanguagePayloadPaths = {
  // Main screen
  title_text: (campaignInfo) => _(campaignInfo).get('branding.headerText') || _(campaignInfo).get('headerText'),
  sub_title_text: (campaignInfo) => _(campaignInfo).get('branding.subTitleText') || _(campaignInfo).get('subTitleText'),
  footer_text: 'footerText',
  support: (campaignInfo) => _(campaignInfo).get('branding.campaignFooter.support') || _(campaignInfo).get('campaignFooterSupport'),

  // Login btns
  email:  'loginOptions.styles.buttonTexts.emailButtonText',
  facebook: 'loginOptions.styles.buttonTexts.facebookButtonText',
  instagram: 'loginOptions.styles.buttonTexts.instagramButtonText',
  linkedin: 'loginOptions.styles.buttonTexts.linkedinButtonText',
  messenger: 'loginOptions.styles.buttonTexts.messengerButtonText',
  oauth: 'loginOptions.styles.buttonTexts.oauthButtonText',
  officernd: 'loginOptions.styles.buttonTexts.officerndButtonText',
  passcode: 'loginOptions.styles.buttonTexts.passcodeButtonText',
  freewifi: 'loginOptions.styles.buttonTexts.freeWifiButtonText',
  stripe: 'loginOptions.styles.buttonTexts.creditcardButtonText',
  twilio: 'loginOptions.styles.buttonTexts.twilioButtonText',
  twitter: 'loginOptions.styles.buttonTexts.twitterButtonText',
  vkontakte: 'loginOptions.styles.buttonTexts.vkontakteButtonText',

  // T&C
  terms_and_conditions_text: 'branding.termsConditionText',
  custom_thank_you_text: 'postLogin.thankYouPage.options.1.text',

  // Consent
  consent_opt_in_text: 'consent.optInText',
  consent_opt_in_description: 'consent.optInDescription',
  consent_yes_text: 'consent.yesText',
  consent_no_text: 'consent.noText',
};

const setAlwaysIfPresent = [
  'email',
  'facebook',
  'instagram',
  'linkedin',
  'messenger',
  'oauth',
  'officernd',
  'passcode',
  'freewifi',
  'stripe',
  'twilio',
  'twitter',
  'vkontakte',
  'title_text',
  'enjoy_browsing',
];

// Set all defaults for single lang & old payloads
if (isMultilingual === false || hasCustomLanguages === false) {
  debugLogger('The case is either single language or has no custom langs', { isMultilingual, hasCustomLanguages })

  for (let customKey of Object.keys(singleLanguagePayloadPaths)) {
    let text = null;
    if (typeof singleLanguagePayloadPaths[customKey] === 'string') {
      text = _(campaignInfo).get(singleLanguagePayloadPaths[customKey]);
    } else {
      text = singleLanguagePayloadPaths[customKey](campaignInfo);
    }

    if (messages[locale][customKey] === undefined
      || messages[locale][customKey] === ''
      || (setAlwaysIfPresent.includes(customKey) && text)
    ) {
      debugLogger(`The case is translation missed or forced`);

      if (customKey === 'custom_thank_you_text') {
        // we are using `enjoy_browsing` term in the vuejs
        messages[locale]['enjoy_browsing'] = text;
      } else if (customKey === 'terms_and_conditions_text' && text) {
        // we are using `agree` term in the vuejs
        messages[locale]['agree'] = text;
      } else {
        messages[locale][customKey] = text;
      }

      debugLogger(`Set happened`, { 'messages[locale][customKey]': messages[locale][customKey] });
    } else {
      // we have default footer text, so need to overwrite it, if custom text exists
      if (customKey === 'support' && messages[locale][customKey] !== text) {
        messages[locale][customKey] = text;
      }
      debugLogger(`The case is translation present`);
    }

    debugLogger(`-----------------`);
  }
}

// i18n works with nulls & undefiends by printing the requested key instead of something empty
// While empty string print empty string
for (let message in messages) {
  for (let customKey in messages[message]) {
    if ((messages[message][customKey] === null || messages[message][customKey] === undefined)) {
      messages[message][customKey] = '';
    }
  }
}

if (debugIndex) {
  window.debug_box = {
    isMultilingual,
    payloadVersion,
    campaignInfo,
    messages,
    hasCustomLanguages,
    is_plume: debugGetValue('is_plume'),
    plume_post: debugGetValue('plume_post'),
    href: window.location.href,
  };
} else {
  window.debug_box = {
    isMultilingual,
    is_plume: debugGetValue('is_plume'),
    plume_post: debugGetValue('plume_post'),
    href: window.location.href
  }
}

const i18n = new VueI18n({
  locale,
  fallbackLocale,
  messages,
  dateTimeFormats: {},
  numberFormats: {},
});

export { i18n, loadLocaleMessages }
