<template>
    <transition name="slide-fade">
        <p v-if="show">{{ notEmptyErrorMessage }}</p>
    </transition>
</template>

<script>
    export default {
        props: ['errorMsg'],
        name: 'AlertMsg',
        data(){
            return {
                show: true
            }
        },

      computed: {
        notEmptyErrorMessage() {
          return this.errorMsg && this.errorMsg.length > 0
            ? this.errorMsg
            : this.$t('something_went_wrong');
        }
      },

        mounted()
        {
            setTimeout(()=>{
                this.show = false;
            }, 5000)
        }
    }
</script>
