<template>
  <div class="weather-widget" v-click-outside="closeAll"  v-if="weatherPayload">
    <div class="current-weather" @click="toggleForecast()">
      <div class="main" :class="{'small': !showDetails}">
        <h1>{{ weatherPayload.CurrentConditions[0].Temperature[unitType].Value.toFixed(0) }}° {{unitString}}</h1>
        <h3 v-if="showDetails"> {{weatherPayload.LocalizedName}} </h3>
      </div>
      <div class="details" v-if="showDetails">
        <div class="icon">
          <div v-html="weatherIcons[ weatherPayload.CurrentConditions[0].WeatherIcon]">
            
          </div>
        </div>
        <div class="short-info">
          <div class="we-icon">
            <i class="fa fa-tint" aria-hidden="true"></i>
            <h4><span>{{  weatherPayload.CurrentConditions[0].Precip1hr.Metric.Value }}</span><strong>MM</strong></h4>
          </div>
          <div class="we-icon"> 
            <div class="svg wind">
              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 612 612" style="enable-background:new 0 0 612 612;" xml:space="preserve"><g><g><path d="M43.411,335.593h437.203c63.387,0,114.952-51.568,114.952-114.952c0-63.291-51.417-114.8-114.677-114.95c-6.232-0.094-46.697,0.466-76.913,29.927c-20.244,19.737-30.506,46.857-30.506,80.603c0,14.901,12.078,26.98,26.98,26.98c14.901,0,26.978-12.08,26.978-26.98c0-18.482,4.697-32.516,13.955-41.709c14.429-14.33,36.875-14.846,38.67-14.864c0.252,0.008,0.22,0.004,0.56,0.004c33.631,0,60.993,27.36,60.993,60.989c0,33.631-27.36,60.991-60.993,60.991H43.411c-14.901,0-26.98,12.08-26.98,26.98C16.431,323.513,28.51,335.593,43.411,335.593z"/><path d="M43.411,229.908h208.393c63.383,0,114.95-51.568,114.95-114.952c0-63.295-51.415-114.806-114.677-114.95c-6.306-0.088-46.707,0.474-76.915,29.927c-20.242,19.739-30.506,46.859-30.506,80.605c0,14.901,12.078,26.982,26.98,26.982s26.978-12.08,26.978-26.982c0-18.482,4.697-32.516,13.955-41.709c14.427-14.328,36.873-14.848,38.674-14.866c0.252,0.008,0.218,0.004,0.56,0.004c33.629,0,60.991,27.36,60.991,60.989c0,33.631-27.36,60.991-60.991,60.991H43.411c-14.901,0-26.98,12.08-26.98,26.982S28.51,229.908,43.411,229.908z"/><path d="M480.614,382.093H43.411c-14.901,0-26.98,12.08-26.98,26.98c0,14.899,12.078,26.98,26.98,26.98h437.203c33.631,0,60.993,27.36,60.993,60.989c0,33.631-27.36,60.991-60.993,60.991c-0.339,0-0.308-0.006-0.56,0.004c-1.797-0.018-24.243-0.536-38.67-14.864c-9.258-9.193-13.955-23.227-13.955-41.711c0-14.901-12.077-26.982-26.978-26.982c-14.901,0-26.98,12.08-26.98,26.982c0,33.748,10.262,60.868,30.506,80.605c28.999,28.277,67.407,29.935,75.994,29.933c0.361,0,0.667-0.002,0.919-0.006c63.26-0.15,114.677-51.659,114.677-114.95C595.567,433.659,544.001,382.093,480.614,382.093z"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
            </div>
            <h4><span>{{  weatherPayload.CurrentConditions[0].Wind.Speed.Metric.Value }}</span> <strong>KMPH</strong></h4>
          </div>
        </div>
      </div>
    </div>
    <weather-forecast :weatherPayload="weatherPayload" :icons="weatherIcons" :show="forecast"></weather-forecast>
  </div>
</template> 

<style scoped>
  .weather-widget{
    display: flex;
    flex-direction: column;
    /*width: 240px;*/
    /*margin: 0 auto;*/
  }
  .current-weather{
    color: white;
    display: flex;
    cursor: pointer;
  }
  .weather-widget .main h1, .weather-widget .main h3{
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .weather-widget .main h1{
    font-weight: 100;
    font-size: 36px;
  }
  .weather-widget .main h3{
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 2px;
    margin-top: 0;
  }
  .weather-widget .main{
    background: #202020;
    padding: 20px;
    border-radius: 5px 0 0 5px;
  }
  .weather-widget .main.small{
    padding: 15px;
    border-radius: 5px;
  }
  .weather-widget .main.small h1{
    font-size: 16px;
  }
  .weather-widget .main.small h3{
    font-size: 10px;
  }
  .weather-widget .details{
    min-width: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background: white;
    border-radius: 0 5px 5px 0;
    overflow: hidden;
  }
  .weather-widget .details .icon{
    width: 64px;
    padding: 0 10px;
  }
  .weather-widget .details .short-info{
    color: white;
    display: flex;
    background: #55acee;
    padding: 5px 5px 5px 0;
  }
  .weather-widget .details .short-info h4{
    margin: 0;
    padding: 0;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
  }
  .weather-widget .details .short-info h4 strong{
    font-weight: 400;
    font-size: 8px;
    margin-top: -2px;
  }
  .weather-widget .details .short-info .we-icon{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3px;
  }
  .weather-widget .details .short-info .we-icon .svg{
    width: 14px;
    fill: white;
    margin-top: 5px;
    margin-right: 5px;
  }
  .weather-widget .details .short-info .we-icon .svg.snow{
    margin: 10px 5px 5px 5px;
  }
  .weather-widget .details .short-info .we-icon .svg.wind{
    margin: 10px 5px 5px 5px;
  }
  .weather-widget .details .short-info .we-icon i{
    padding: 0 5px;
    font-size: 14px;
  }
</style>

<script>
  import WeatherForecast from './forecast.vue';
  export default{
    data(){
      return {
        forecast: false,
        showDetails: false,
        locationInfo: null,
        api: '/index.php?controller=apiservices&name=get-weather-data&lang_code=',
        weatherPayload: null,
        weatherIcons: {
          //Sunny
          1: `<svg id="weather-icon-sunny" class="weather-icon weather-icon-white sunny" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.stsunny{fill:#F4A71D;}</style><g id="sunny"><path class="stsunny" d="M32.9 19.2c-.7 0-1.3-.6-1.3-1.3v-4.6c0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3v4.6c0 .7-.6 1.3-1.3 1.3zM32.9 53.8c-.7 0-1.3-.6-1.3-1.3v-4.6c0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3v4.6c0 .7-.6 1.3-1.3 1.3zM17.9 34.2h-4.6c-.7 0-1.3-.6-1.3-1.3 0-.7.6-1.3 1.3-1.3h4.6c.7 0 1.3.6 1.3 1.3 0 .7-.6 1.3-1.3 1.3zM52.5 34.2h-4.6c-.7 0-1.3-.6-1.3-1.3 0-.7.6-1.3 1.3-1.3h4.6c.7 0 1.3.6 1.3 1.3 0 .7-.6 1.3-1.3 1.3zM44 23.5c-.3 0-.7-.1-.9-.4-.5-.5-.5-1.4 0-1.9l3.2-3.2c.5-.5 1.4-.5 1.9 0s.5 1.4 0 1.9L45 23.1c-.3.3-.7.4-1 .4zM19.1 48.4c-.3 0-.7-.1-.9-.4-.5-.5-.5-1.4 0-1.9l3.2-3.2c.5-.5 1.4-.5 1.9 0s.5 1.4 0 1.9L20 48c-.2.3-.6.4-.9.4zM47.2 48.2c-.3 0-.7-.1-.9-.4l-3.2-3.2c-.5-.5-.5-1.4 0-1.9s1.4-.5 1.9 0l3.2 3.2c.5.5.5 1.4 0 1.9-.3.3-.6.4-1 .4zM22.3 23.8c-.3 0-.7-.1-.9-.4l-3.2-3.2c-.5-.5-.5-1.4 0-1.9s1.4-.5 1.9 0l3.2 3.2c.5.5.5 1.4 0 1.9-.3.2-.6.4-1 .4zM32.9 24.5c4.6 0 8.4 3.8 8.4 8.4 0 4.6-3.8 8.4-8.4 8.4-4.6 0-8.4-3.8-8.4-8.4 0-4.6 3.8-8.4 8.4-8.4m0-2.7c-6.1 0-11.1 5-11.1 11.1S26.8 44 32.9 44 44 39 44 32.9s-5-11.1-11.1-11.1z"/></g></svg>`,
          
          //Mostly Sunny
          2: `<svg id="weather-icon-mostlysunny" class="weather-icon weather-icon-white mostlysunny" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.stmostlysunny0{fill:#F4A71D;} .stmostlysunny1{fill:#202020;}</style><g id="mostlysunny"><path class="stmostlysunny0" d="M35.5 15.3c-.7 0-1.3-.6-1.3-1.3V9c0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3v5c0 .7-.6 1.3-1.3 1.3zM19.2 31.6h-5c-.7 0-1.3-.6-1.3-1.3 0-.7.6-1.3 1.3-1.3h5c.7 0 1.3.6 1.3 1.3 0 .7-.6 1.3-1.3 1.3zM56.7 31.6h-5c-.7 0-1.3-.6-1.3-1.3 0-.7.6-1.3 1.3-1.3h5c.7 0 1.3.6 1.3 1.3 0 .7-.6 1.3-1.3 1.3zM47.5 20c-.3 0-.7-.1-.9-.4-.5-.5-.5-1.4 0-1.9l3.5-3.5c.5-.5 1.4-.5 1.9 0s.5 1.4 0 1.9l-3.5 3.5c-.3.2-.7.4-1 .4zM51 46.8c-.3 0-.7-.1-.9-.4l-3.5-3.5c-.5-.5-.5-1.4 0-1.9s1.3-.5 1.9 0l3.5 3.5c.5.5.5 1.4 0 1.9-.3.2-.7.4-1 .4zM24 20.2c-.3 0-.7-.1-.9-.4l-3.5-3.5c-.5-.5-.5-1.4 0-1.9s1.3-.5 1.9 0l3.5 3.5c.5.5.5 1.4 0 1.9-.3.3-.7.4-1 .4z"/><path class="stmostlysunny1" d="M33.4 58.2h-20c-3.7 0-6.7-3-6.7-6.7 0-2.8 1.7-5.3 4.3-6.3.1-3.3 2.8-6 6.2-6 .7 0 1.4.1 2 .4 1.6-2.2 4.2-3.6 7-3.6 4.8 0 8.7 3.9 8.7 8.7 0 .4 0 .7-.1 1.1 2.8.6 5 3.1 5 6.2-.1 3.4-2.9 6.2-6.4 6.2zM17.1 41.9c-1.9 0-3.5 1.6-3.5 3.5 0 .2 0 .4.1.6l.2 1.3-1.3.3c-1.9.4-3.3 2.1-3.3 4 0 2.2 1.8 4.1 4.1 4.1h20c2 0 3.6-1.6 3.6-3.6s-1.6-3.6-3.6-3.6h-.2l-2 .1.6-1.9c.2-.6.3-1.2.3-1.9 0-3.3-2.7-6-6-6-2.2 0-4.3 1.2-5.3 3.2l-.7 1.3-1.2-.7c-.5-.5-1.2-.7-1.8-.7z"/><path class="stmostlysunny0" d="M47.5 30.3c0-6.6-5.4-12-12-12s-12 5.4-12 12c0 .7.1 1.4.2 2 0 .8.7 1.4 1.4 1.3.4 0 1-.3 1.1-.7.2-.5.1-1 0-1.5-.1-.4-.1-.8-.1-1.1 0-5.2 4.2-9.4 9.4-9.4s9.3 4.2 9.3 9.4c0 .4-.1.8-.1 1.2-.1.4-.1.8-.2 1.2-.2.7-.5 1.5-.8 2.1-.4.7-.8 1.3-1.3 1.9-.5.6-1.1 1.1-1.7 1.4-.7.4-1.4.7-2.1 1-.3.1-.6.1-.9.3-.3.1-.5.4-.6.7-.1.2-.1.3-.1.5 0 .7.6 1.3 1.4 1.4 5.1-1.1 9.1-6.1 9.1-11.7z"/></g></svg>`,
          
          //Partly Sunny
          3: `<svg id="weather-icon-partlysunny" class="weather-icon weather-icon-white partlysunny" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.stpartlysunny10{fill:#F4A71D;} .stpartlysunny11{fill:#202020;}</style><g id="mostlycloudy"><path class="stpartlysunny10" d="M43.1 13.9c-.7 0-1.2-.6-1.2-1.2V9.2c0-.7.6-1.2 1.2-1.2.7 0 1.2.6 1.2 1.2v3.4c0 .7-.5 1.3-1.2 1.3zM57.6 25h-3.4c-.7 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2h3.4c.7 0 1.2.5 1.2 1.2.1.7-.5 1.2-1.2 1.2zM51.3 17.1c-.3 0-.6-.1-.9-.4-.5-.5-.5-1.3 0-1.7l2.4-2.4c.5-.5 1.3-.5 1.7 0 .5.5.5 1.3 0 1.7l-2.4 2.4c-.1.3-.5.4-.8.4zM53.7 35.4c-.3 0-.6-.1-.9-.4l-2.4-2.4c-.5-.5-.5-1.3 0-1.7.5-.5 1.3-.5 1.7 0l2.4 2.4c.5.5.5 1.3 0 1.7-.1.3-.5.4-.8.4zM35.3 17.2c-.3 0-.6-.1-.9-.4L32 14.5c-.5-.5-.5-1.3 0-1.7.5-.5 1.3-.5 1.7 0l2.4 2.4c.5.5.5 1.3 0 1.7-.2.2-.5.3-.8.3z"/><path class="stpartlysunny11" d="M45.6 57.8H16c-5.1 0-9.3-4.2-9.3-9.3 0-4 2.6-7.6 6.3-8.8v-.2c0-4.7 3.8-8.5 8.5-8.5 1.1 0 2.2.2 3.2.7 2.2-3.4 6-5.4 10.1-5.4 6.7 0 12.2 5.5 12.2 12.2 0 .7-.1 1.4-.2 2.1 4.2.6 7.5 4.2 7.5 8.6-.1 4.7-3.9 8.6-8.7 8.6zM21.4 33.7c-3.2 0-5.8 2.6-5.8 5.8 0 .3 0 .6.1 1l.2 1.3-1.3.2c-3.1.6-5.3 3.4-5.3 6.5 0 3.7 3 6.7 6.7 6.7h29.6c3.3 0 6-2.7 6-6s-2.7-6-6-6h-.3l-2 .1.6-1.9c.3-.9.5-1.9.5-2.9 0-5.3-4.3-9.5-9.5-9.5-3.5 0-6.8 2-8.4 5.1l-.7 1.3-1.2-.7c-1-.8-2.1-1-3.2-1z"/><path class="stpartlysunny10" d="M44.6 16c-4.2-.8-8.2 2-8.9 6.2-.1.4-.1.7-.1 1.1-.1.5.3 1 .9 1.1 1.1.1 1-1 1.2-1.7.6-3.1 3.5-5.1 6.6-4.6 3.1.6 5.1 3.5 4.6 6.6-.1.7-.3 1.4-.7 2-.5.7-1.3 1.3-2 1.8-.6.4-.3 1.3.4 1.4.8.2 1.5-.4 2.1-1 .7-.6 1.3-1.5 1.7-2.4.2-.5.4-1 .5-1.5.7-4.3-2.1-8.3-6.3-9z"/></g></svg>`,
          
          //Partly Sunny
          4: `<svg id="weather-icon-partlysunny" class="weather-icon weather-icon-white partlysunny" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.stpartlysunny0{fill:#F4A71D;} .stpartlysunny1{fill:#202020;}</style><g id="mostlycloudy"><path class="stpartlysunny0" d="M43.1 13.9c-.7 0-1.2-.6-1.2-1.2V9.2c0-.7.6-1.2 1.2-1.2.7 0 1.2.6 1.2 1.2v3.4c0 .7-.5 1.3-1.2 1.3zM57.6 25h-3.4c-.7 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2h3.4c.7 0 1.2.5 1.2 1.2.1.7-.5 1.2-1.2 1.2zM51.3 17.1c-.3 0-.6-.1-.9-.4-.5-.5-.5-1.3 0-1.7l2.4-2.4c.5-.5 1.3-.5 1.7 0 .5.5.5 1.3 0 1.7l-2.4 2.4c-.1.3-.5.4-.8.4zM53.7 35.4c-.3 0-.6-.1-.9-.4l-2.4-2.4c-.5-.5-.5-1.3 0-1.7.5-.5 1.3-.5 1.7 0l2.4 2.4c.5.5.5 1.3 0 1.7-.1.3-.5.4-.8.4zM35.3 17.2c-.3 0-.6-.1-.9-.4L32 14.5c-.5-.5-.5-1.3 0-1.7.5-.5 1.3-.5 1.7 0l2.4 2.4c.5.5.5 1.3 0 1.7-.2.2-.5.3-.8.3z"/><path class="stpartlysunny1" d="M45.6 57.8H16c-5.1 0-9.3-4.2-9.3-9.3 0-4 2.6-7.6 6.3-8.8v-.2c0-4.7 3.8-8.5 8.5-8.5 1.1 0 2.2.2 3.2.7 2.2-3.4 6-5.4 10.1-5.4 6.7 0 12.2 5.5 12.2 12.2 0 .7-.1 1.4-.2 2.1 4.2.6 7.5 4.2 7.5 8.6-.1 4.7-3.9 8.6-8.7 8.6zM21.4 33.7c-3.2 0-5.8 2.6-5.8 5.8 0 .3 0 .6.1 1l.2 1.3-1.3.2c-3.1.6-5.3 3.4-5.3 6.5 0 3.7 3 6.7 6.7 6.7h29.6c3.3 0 6-2.7 6-6s-2.7-6-6-6h-.3l-2 .1.6-1.9c.3-.9.5-1.9.5-2.9 0-5.3-4.3-9.5-9.5-9.5-3.5 0-6.8 2-8.4 5.1l-.7 1.3-1.2-.7c-1-.8-2.1-1-3.2-1z"/><path class="stpartlysunny0" d="M44.6 16c-4.2-.8-8.2 2-8.9 6.2-.1.4-.1.7-.1 1.1-.1.5.3 1 .9 1.1 1.1.1 1-1 1.2-1.7.6-3.1 3.5-5.1 6.6-4.6 3.1.6 5.1 3.5 4.6 6.6-.1.7-.3 1.4-.7 2-.5.7-1.3 1.3-2 1.8-.6.4-.3 1.3.4 1.4.8.2 1.5-.4 2.1-1 .7-.6 1.3-1.5 1.7-2.4.2-.5.4-1 .5-1.5.7-4.3-2.1-8.3-6.3-9z"/></g></svg>`,
          
          
          //Hazy Sunshine
          5: `<svg id="weather-icon-hazy" class="weather-icon weather-icon-white hazy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.st0{fill:#202020;}</style><g id="fog"><path class="st0" d="M41.7 38.5c-3.2 0-6.1-.9-8.7-2.7-7.2-5.1-13.2-5.3-19.6-.9-.5.3-1.1.2-1.4-.3-.3-.5-.2-1.1.3-1.4 7.1-4.9 14.1-4.6 21.9.9 6.2 4.4 13.3 1.9 18.2-1 .5-.3 1.1-.1 1.4.4.3.5.1 1.1-.4 1.4-4 2.4-8 3.6-11.7 3.6z"/><path class="st0" d="M41.7 46.3c-3.2 0-6.1-.9-8.7-2.7-7.2-5.1-13.2-5.3-19.6-.9-.5.3-1.1.2-1.4-.3-.3-.5-.2-1.1.3-1.4 7.1-4.9 14.1-4.6 21.9.9 6.2 4.3 13.3 1.9 18.2-1 .5-.3 1.1-.1 1.4.4.3.5.1 1.1-.4 1.4-4 2.4-8 3.6-11.7 3.6zM41.7 30.8c-3.2 0-6.1-.9-8.7-2.7-7.2-5.1-13.2-5.3-19.6-.9-.5.3-1.1.2-1.4-.3-.3-.5-.2-1.1.3-1.4 7.1-4.9 14.1-4.6 21.9.9 6.2 4.4 13.3 1.9 18.2-1 .5-.3 1.1-.1 1.4.4.3.5.1 1.1-.4 1.4-4 2.4-8 3.6-11.7 3.6z"/></g></svg>`,
          
          
          //Mostly_Cloudy
          6: `<svg id="weather-icon-mostlycloudy" class="weather-icon weather-icon-white mostlycloudy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.stmostlycloudy0{fill:#F4A71D;} .stmostlycloudy1{fill:#202020;}</style><g id="mostlycloudy"><path class="stmostlycloudy0" d="M43.1 13.9c-.7 0-1.2-.6-1.2-1.2V9.2c0-.7.6-1.2 1.2-1.2.7 0 1.2.6 1.2 1.2v3.4c0 .7-.5 1.3-1.2 1.3zM57.6 25h-3.4c-.7 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2h3.4c.7 0 1.2.5 1.2 1.2.1.7-.5 1.2-1.2 1.2zM51.3 17.1c-.3 0-.6-.1-.9-.4-.5-.5-.5-1.3 0-1.7l2.4-2.4c.5-.5 1.3-.5 1.7 0 .5.5.5 1.3 0 1.7l-2.4 2.4c-.1.3-.5.4-.8.4zM53.7 35.4c-.3 0-.6-.1-.9-.4l-2.4-2.4c-.5-.5-.5-1.3 0-1.7.5-.5 1.3-.5 1.7 0l2.4 2.4c.5.5.5 1.3 0 1.7-.1.3-.5.4-.8.4zM35.3 17.2c-.3 0-.6-.1-.9-.4L32 14.5c-.5-.5-.5-1.3 0-1.7.5-.5 1.3-.5 1.7 0l2.4 2.4c.5.5.5 1.3 0 1.7-.2.2-.5.3-.8.3z"/><path class="stmostlycloudy1" d="M45.6 57.8H16c-5.1 0-9.3-4.2-9.3-9.3 0-4 2.6-7.6 6.3-8.8v-.2c0-4.7 3.8-8.5 8.5-8.5 1.1 0 2.2.2 3.2.7 2.2-3.4 6-5.4 10.1-5.4 6.7 0 12.2 5.5 12.2 12.2 0 .7-.1 1.4-.2 2.1 4.2.6 7.5 4.2 7.5 8.6-.1 4.7-3.9 8.6-8.7 8.6zM21.4 33.7c-3.2 0-5.8 2.6-5.8 5.8 0 .3 0 .6.1 1l.2 1.3-1.3.2c-3.1.6-5.3 3.4-5.3 6.5 0 3.7 3 6.7 6.7 6.7h29.6c3.3 0 6-2.7 6-6s-2.7-6-6-6h-.3l-2 .1.6-1.9c.3-.9.5-1.9.5-2.9 0-5.3-4.3-9.5-9.5-9.5-3.5 0-6.8 2-8.4 5.1l-.7 1.3-1.2-.7c-1-.8-2.1-1-3.2-1z"/><path class="stmostlycloudy0" d="M44.6 16c-4.2-.8-8.2 2-8.9 6.2-.1.4-.1.7-.1 1.1-.1.5.3 1 .9 1.1 1.1.1 1-1 1.2-1.7.6-3.1 3.5-5.1 6.6-4.6 3.1.6 5.1 3.5 4.6 6.6-.1.7-.3 1.4-.7 2-.5.7-1.3 1.3-2 1.8-.6.4-.3 1.3.4 1.4.8.2 1.5-.4 2.1-1 .7-.6 1.3-1.5 1.7-2.4.2-.5.4-1 .5-1.5.7-4.3-2.1-8.3-6.3-9z"/></g></svg>`,
    
          //Cloudy
          7: `<svg id="weather-icon-cloudy" class="weather-icon weather-icon-white cloudy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.stcloudy{fill:#202020;}</style><path class="stcloudy" d="M49.7 50.2H16.8c-5.6 0-10.2-4.6-10.2-10.2 0-4.4 2.9-8.3 7-9.7V30c0-5.1 4.2-9.3 9.3-9.3 1.3 0 2.5.3 3.7.8 2.5-3.7 6.6-6 11.2-6 7.4 0 13.4 6 13.4 13.4 0 .8-.1 1.6-.2 2.4 4.7.6 8.3 4.6 8.3 9.4-.1 5.2-4.4 9.5-9.6 9.5zM22.9 23.4c-3.6 0-6.6 3-6.6 6.6 0 .3 0 .7.1 1.1l.2 1.3-1.3.2c-3.5.7-6.1 3.8-6.1 7.4 0 4.2 3.4 7.5 7.5 7.5h32.9c3.8 0 6.8-3.1 6.8-6.8 0-3.8-3.1-6.8-6.8-6.8h-.3l-2 .1.6-1.9c.3-1.1.5-2.2.5-3.3 0-5.9-4.8-10.7-10.7-10.7-4 0-7.6 2.2-9.5 5.7l-.7 1.3-1.2-.7c-1-.7-2.2-1-3.4-1z" id="cloudy"/></svg>`,
          
          //Dreary
          8: `<svg id="weather-icon-cloudy" class="weather-icon weather-icon-white cloudy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.stcloudy{fill:#202020;}</style><path class="stcloudy" d="M49.7 50.2H16.8c-5.6 0-10.2-4.6-10.2-10.2 0-4.4 2.9-8.3 7-9.7V30c0-5.1 4.2-9.3 9.3-9.3 1.3 0 2.5.3 3.7.8 2.5-3.7 6.6-6 11.2-6 7.4 0 13.4 6 13.4 13.4 0 .8-.1 1.6-.2 2.4 4.7.6 8.3 4.6 8.3 9.4-.1 5.2-4.4 9.5-9.6 9.5zM22.9 23.4c-3.6 0-6.6 3-6.6 6.6 0 .3 0 .7.1 1.1l.2 1.3-1.3.2c-3.5.7-6.1 3.8-6.1 7.4 0 4.2 3.4 7.5 7.5 7.5h32.9c3.8 0 6.8-3.1 6.8-6.8 0-3.8-3.1-6.8-6.8-6.8h-.3l-2 .1.6-1.9c.3-1.1.5-2.2.5-3.3 0-5.9-4.8-10.7-10.7-10.7-4 0-7.6 2.2-9.5 5.7l-.7 1.3-1.2-.7c-1-.7-2.2-1-3.4-1z" id="cloudy"/></svg>`,
          
          
          //Fog
          11: `<svg id="weather-icon-fog" class="weather-icon weather-icon-white fog" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.st0{fill:#202020;}</style><g id="fog"><path class="st0" d="M41.7 38.5c-3.2 0-6.1-.9-8.7-2.7-7.2-5.1-13.2-5.3-19.6-.9-.5.3-1.1.2-1.4-.3-.3-.5-.2-1.1.3-1.4 7.1-4.9 14.1-4.6 21.9.9 6.2 4.4 13.3 1.9 18.2-1 .5-.3 1.1-.1 1.4.4.3.5.1 1.1-.4 1.4-4 2.4-8 3.6-11.7 3.6z"/><path class="st0" d="M41.7 46.3c-3.2 0-6.1-.9-8.7-2.7-7.2-5.1-13.2-5.3-19.6-.9-.5.3-1.1.2-1.4-.3-.3-.5-.2-1.1.3-1.4 7.1-4.9 14.1-4.6 21.9.9 6.2 4.3 13.3 1.9 18.2-1 .5-.3 1.1-.1 1.4.4.3.5.1 1.1-.4 1.4-4 2.4-8 3.6-11.7 3.6zM41.7 30.8c-3.2 0-6.1-.9-8.7-2.7-7.2-5.1-13.2-5.3-19.6-.9-.5.3-1.1.2-1.4-.3-.3-.5-.2-1.1.3-1.4 7.1-4.9 14.1-4.6 21.9.9 6.2 4.4 13.3 1.9 18.2-1 .5-.3 1.1-.1 1.4.4.3.5.1 1.1-.4 1.4-4 2.4-8 3.6-11.7 3.6z"/></g></svg>`,

          //Showers
          12: `<svg id="weather-icon-rain" class="weather-icon weather-icon-white rain" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.st0{fill:#2885C7;} .st1{fill:#202020;}</style><g id="rain"><path class="st0" d="M25.5 59.3c-.2 0-.3 0-.5-.1-.7-.3-1-1.1-.7-1.7l9.3-21.9c.3-.7 1.1-1 1.7-.7.7.3 1 1.1.7 1.7l-9.3 21.9c-.2.5-.7.8-1.2.8zM34 53.5c-.2 0-.3 0-.5-.1-.7-.3-1-1.1-.7-1.7l6.6-16c.3-.7 1.1-1 1.7-.7.7.3 1 1.1.7 1.7l-6.6 16c-.2.5-.7.8-1.2.8zM21.9 53.5c-.2 0-.3 0-.5-.1-.7-.3-1-1.1-.7-1.7l6.6-16c.3-.7 1.1-1 1.7-.7.7.3 1 1.1.7 1.7l-6.6 16c-.2.5-.6.8-1.2.8z"/><path class="st1" d="M50.9 22.4c.1-.8.2-1.6.2-2.4 0-7.4-6-13.4-13.4-13.4-4.5 0-8.7 2.3-11.2 6-1.2-.5-2.4-.8-3.7-.8-5.1 0-9.3 4.2-9.3 9.3v.3c-4.1 1.4-7 5.2-7 9.7 0 5.6 4.6 10.2 10.2 10.2h5c1.6 0 1.5-2.7 0-2.7h-5c-4.2 0-7.5-3.4-7.5-7.5 0-3.6 2.5-6.7 6.1-7.4l1.3-.3-.2-1.3c-.1-.4-.1-.7-.1-1.1 0-3.6 3-6.6 6.6-6.6 1.2 0 2.4.3 3.5 1l1.2.7.7-1.3c1.9-3.5 5.5-5.7 9.5-5.7 5.9 0 10.7 4.8 10.7 10.7 0 1.1-.2 2.2-.5 3.3l-.6 1.9 2.1-.1h.3c3.8 0 6.8 3.1 6.8 6.8 0 3.8-3.1 6.8-6.8 6.8h-5.7c-1.7 0-2 2.7 0 2.7h5.7c5.2 0 9.5-4.3 9.5-9.5-.1-4.7-3.7-8.7-8.4-9.3z"/></g></svg>`,
        
    
          //chance rain
          13: `<svg id="weather-icon-chancerain" class="weather-icon weather-icon-white chancerain" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.stchancerain0{fill:#202020;} .stchancerain1{fill:#2885C7;}</style><g id="chancerain"><path class="st0" d="M50.9 24.1c.1-.8.2-1.6.2-2.4 0-7.4-6-13.4-13.4-13.4-4.5 0-8.7 2.3-11.2 6-1.2-.5-2.4-.8-3.7-.8-5.1 0-9.3 4.1-9.3 9.3v.3c-4.1 1.4-7 5.2-7 9.7 0 5.6 4.6 10.2 10.2 10.2h5c1.6 0 1.5-2.7 0-2.7h-5c-4.2 0-7.5-3.4-7.5-7.5 0-3.6 2.5-6.7 6.1-7.4l1.3-.3-.2-1.3c-.1-.4-.1-.7-.1-1.1 0-3.6 3-6.6 6.6-6.6 1.2 0 2.4.3 3.5 1l1.2.7.7-1.3c1.9-3.5 5.5-5.7 9.5-5.7 5.9 0 10.7 4.8 10.7 10.7 0 1.1-.2 2.2-.5 3.3l-.6 1.9 2.1-.1h.3c3.8 0 6.8 3.1 6.8 6.8 0 3.8-3.1 6.8-6.8 6.8h-5.7c-1.7 0-2 2.7 0 2.7h5.7c5.2 0 9.5-4.3 9.5-9.5-.1-4.7-3.8-8.7-8.4-9.3z"/><path class="st1" d="M24.9 46.5c-.3 0-.5-.1-.8-.2-.6-.4-.7-1.3-.3-1.8l2.4-3.5c.4-.6 1.2-.7 1.8-.3.6.4.7 1.2.3 1.8L25.9 46c-.2.3-.6.5-1 .5zM30.7 46.5c-.3 0-.5-.1-.8-.2-.6-.4-.7-1.3-.3-1.8L32 41c.4-.6 1.3-.7 1.8-.3.6.4.7 1.2.3 1.8L31.7 46c-.2.3-.6.5-1 .5zM36.5 46.5c-.3 0-.5-.1-.8-.2-.6-.4-.7-1.3-.3-1.8l2.4-3.5c.4-.6 1.3-.7 1.8-.3.6.4.7 1.2.3 1.8L37.5 46c-.1.3-.5.5-1 .5zM18.6 56c-.3 0-.5-.1-.8-.2-.6-.4-.7-1.2-.3-1.8l2.4-3.5c.4-.6 1.3-.7 1.9-.3.6.4.7 1.2.3 1.8l-2.4 3.5c-.3.3-.7.5-1.1.5zM24.5 56c-.3 0-.5-.1-.8-.2-.6-.4-.7-1.2-.3-1.8l2.4-3.5c.4-.6 1.2-.7 1.8-.3.6.4.7 1.2.3 1.8l-2.4 3.5c-.2.3-.6.5-1 .5zM30.3 56c-.3 0-.5-.1-.8-.2-.6-.4-.7-1.2-.3-1.8l2.4-3.5c.4-.6 1.2-.7 1.8-.3.6.4.7 1.2.3 1.8l-2.4 3.5c-.2.3-.6.5-1 .5z"/></g></svg>`,
          
          //Partly Sunny with Showers
          14: `<svg id="weather-icon-chancesleet" class="weather-icon weather-icon-white chancesleet" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.stPartSunny0{fill:#202020;} .stPartlySunny1{fill:#55acee;}</style><g id="chancesleet"><path class="stPartSunny0" d="M50.9 23.8c.1-.8.2-1.6.2-2.4C51.1 14 45.1 8 37.7 8c-4.5 0-8.7 2.3-11.2 6-1.2-.5-2.4-.8-3.7-.8-5.1 0-9.3 4.2-9.3 9.3v.3c-4.1 1.4-7 5.2-7 9.7 0 5.6 4.6 10.2 10.2 10.2h5c1.6 0 1.5-2.6 0-2.6h-5c-4.2 0-7.5-3.4-7.5-7.5 0-3.6 2.5-6.7 6.1-7.4l1.3-.3-.2-1.3c-.1-.4-.1-.8-.1-1.1 0-3.6 3-6.6 6.6-6.6 1.2 0 2.4.3 3.5 1l1.2.7.7-1.3c1.9-3.5 5.5-5.7 9.5-5.7 5.9 0 10.7 4.8 10.7 10.7 0 1.1-.2 2.2-.5 3.3l-.6 1.9 2.1-.1h.3c3.8 0 6.8 3.1 6.8 6.8 0 3.8-3.1 6.8-6.8 6.8h-5.7c-1.7 0-2 2.6 0 2.6h5.7c5.2 0 9.5-4.3 9.5-9.5-.1-4.7-3.8-8.7-8.4-9.3z"/><path class="stPartlySunny1" d="M26.6 46c-.2 0-.3 0-.5-.1-.5-.3-.7-.9-.5-1.4l3.5-6.6c.3-.5.9-.7 1.4-.5.5.3.7.9.5 1.4l-3.5 6.6c-.1.4-.5.6-.9.6zM31.2 53c-.2 0-.3 0-.5-.1-.5-.3-.7-.9-.5-1.4l6.9-13.7c.3-.5.9-.7 1.4-.5.5.3.7.9.5 1.4l-6.9 13.7c-.1.4-.5.6-.9.6zM26.1 50.6l-2.8 1-.9-2.8 2.8-.9zM27.12 55.65l2.75-.917.918 2.75-2.75.92z"/></g></svg>`,
        
         //thunder Storms
          15: `<svg id="weather-icon-tstorms" class="weather-icon weather-icon-white tstorms" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.st0{fill:#2885C7;} .st1{fill:#F4A71D;} .st2{fill:#202020;}</style><g id="tstorms"><path class="st0" d="M19.7 53c-.2 0-.4 0-.6-.1-.7-.3-.9-1.1-.6-1.8l2.8-5.8c.3-.7 1.1-.9 1.8-.6.7.3.9 1.1.6 1.8l-2.8 5.8c-.3.4-.7.7-1.2.7zM13.1 53c-.2 0-.4 0-.6-.1-.7-.3-.9-1.1-.6-1.8l2.8-5.8c.3-.7 1.1-.9 1.8-.6.7.3.9 1.1.6 1.8l-2.8 5.8c-.2.4-.7.7-1.2.7zM46.8 53c-.2 0-.4 0-.6-.1-.7-.3-.9-1.1-.6-1.8l2.8-5.8c.3-.7 1.1-.9 1.8-.6.7.3.9 1.1.6 1.8L48 52.3c-.2.4-.7.7-1.2.7zM40.2 53c-.2 0-.4 0-.6-.1-.7-.3-.9-1.1-.6-1.8l2.8-5.8c.3-.7 1.1-.9 1.8-.6.7.3.9 1.1.6 1.8l-2.8 5.8c-.2.4-.7.7-1.2.7z"/><path class="st1" d="M27.7 59.3c-.2 0-.5-.1-.7-.2-.6-.4-.8-1.2-.4-1.8l7.9-13.1h-8.3L35.5 31c.4-.6 1.2-.7 1.8-.3.6.4.7 1.3.3 1.9l-6.4 9.1h7.9L28.8 58.6c-.2.4-.7.7-1.1.7z"/><path class="st2" d="M50.9 22.3c.1-.8.2-1.6.2-2.4 0-7.4-6-13.4-13.4-13.4-4.5 0-8.7 2.3-11.2 6-1.1-.5-2.4-.8-3.6-.8-5.1 0-9.3 4.2-9.3 9.3v.3c-4.1 1.4-7 5.2-7 9.7 0 5.6 4.6 10.2 10.2 10.2h5c1.6 0 1.5-2.6 0-2.6h-5c-4.2 0-7.5-3.4-7.5-7.5 0-3.6 2.5-6.7 6.1-7.4l1.3-.2-.2-1.3c-.1-.4-.1-.8-.1-1.1 0-3.6 3-6.6 6.6-6.6 1.2 0 2.4.3 3.5 1l1.2.7.7-1.3c1.9-3.5 5.5-5.7 9.5-5.7 5.9 0 10.7 4.8 10.7 10.7 0 1.1-.2 2.2-.5 3.3l-.8 1.8 2.1-.1h.3c3.8 0 6.8 3.1 6.8 6.8 0 3.8-3.1 6.8-6.8 6.8H44c-1.7 0-2 2.6 0 2.6h5.7c5.2 0 9.5-4.3 9.5-9.5 0-4.7-3.7-8.7-8.3-9.3z"/></g></svg>`,
    
          
          
          //Chance Storm
          16: `<svg id="weather-icon-chancetstorms" class="weather-icon weather-icon-white chancetstorms" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.st0{fill:#202020;} .st1{fill:#F4A71D;}</style><g id="chancetstorms"><path class="st0" d="M50.9 22.4c.1-.8.2-1.6.2-2.4 0-7.4-6-13.4-13.4-13.4-4.5 0-8.7 2.3-11.2 6-1.2-.5-2.4-.8-3.6-.8-5.1 0-9.3 4.2-9.3 9.3v.3c-4.1 1.4-7 5.2-7 9.7 0 5.6 4.6 10.2 10.2 10.2h5c1.6 0 1.5-2.7 0-2.7h-5c-4.2 0-7.5-3.4-7.5-7.5 0-3.6 2.5-6.7 6.1-7.4l1.3-.3-.2-1.3c-.1-.4-.1-.7-.1-1.1 0-3.6 3-6.6 6.6-6.6 1.2 0 2.4.3 3.5 1l1.2.7.7-1.3c1.9-3.5 5.5-5.7 9.5-5.7 5.9 0 10.7 4.8 10.7 10.7 0 1.1-.2 2.2-.5 3.3l-.6 1.9 2.1-.1h.3c3.8 0 6.8 3.1 6.8 6.8 0 3.8-3.1 6.8-6.8 6.8h-5.7c-1.7 0-2 2.7 0 2.7h5.7c5.2 0 9.5-4.3 9.5-9.5-.2-4.7-3.9-8.7-8.5-9.3z"/><path class="st1" d="M27.2 59.2c-.2 0-.5-.1-.7-.2-.6-.4-.8-1.2-.4-1.8L34 44.1h-8.3L35 30.9c.4-.6 1.2-.7 1.8-.3.6.4.7 1.3.3 1.9l-6.4 9.1h7.9l-10.2 17c-.3.4-.7.6-1.2.6z"/></g></svg>`,
          
          //thunder Storms
           17: `<svg id="weather-icon-tstorms" class="weather-icon weather-icon-white tstorms" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.st0{fill:#2885C7;} .st1{fill:#F4A71D;} .st2{fill:#202020;}</style><g id="tstorms"><path class="st0" d="M19.7 53c-.2 0-.4 0-.6-.1-.7-.3-.9-1.1-.6-1.8l2.8-5.8c.3-.7 1.1-.9 1.8-.6.7.3.9 1.1.6 1.8l-2.8 5.8c-.3.4-.7.7-1.2.7zM13.1 53c-.2 0-.4 0-.6-.1-.7-.3-.9-1.1-.6-1.8l2.8-5.8c.3-.7 1.1-.9 1.8-.6.7.3.9 1.1.6 1.8l-2.8 5.8c-.2.4-.7.7-1.2.7zM46.8 53c-.2 0-.4 0-.6-.1-.7-.3-.9-1.1-.6-1.8l2.8-5.8c.3-.7 1.1-.9 1.8-.6.7.3.9 1.1.6 1.8L48 52.3c-.2.4-.7.7-1.2.7zM40.2 53c-.2 0-.4 0-.6-.1-.7-.3-.9-1.1-.6-1.8l2.8-5.8c.3-.7 1.1-.9 1.8-.6.7.3.9 1.1.6 1.8l-2.8 5.8c-.2.4-.7.7-1.2.7z"/><path class="st1" d="M27.7 59.3c-.2 0-.5-.1-.7-.2-.6-.4-.8-1.2-.4-1.8l7.9-13.1h-8.3L35.5 31c.4-.6 1.2-.7 1.8-.3.6.4.7 1.3.3 1.9l-6.4 9.1h7.9L28.8 58.6c-.2.4-.7.7-1.1.7z"/><path class="st2" d="M50.9 22.3c.1-.8.2-1.6.2-2.4 0-7.4-6-13.4-13.4-13.4-4.5 0-8.7 2.3-11.2 6-1.1-.5-2.4-.8-3.6-.8-5.1 0-9.3 4.2-9.3 9.3v.3c-4.1 1.4-7 5.2-7 9.7 0 5.6 4.6 10.2 10.2 10.2h5c1.6 0 1.5-2.6 0-2.6h-5c-4.2 0-7.5-3.4-7.5-7.5 0-3.6 2.5-6.7 6.1-7.4l1.3-.2-.2-1.3c-.1-.4-.1-.8-.1-1.1 0-3.6 3-6.6 6.6-6.6 1.2 0 2.4.3 3.5 1l1.2.7.7-1.3c1.9-3.5 5.5-5.7 9.5-5.7 5.9 0 10.7 4.8 10.7 10.7 0 1.1-.2 2.2-.5 3.3l-.8 1.8 2.1-.1h.3c3.8 0 6.8 3.1 6.8 6.8 0 3.8-3.1 6.8-6.8 6.8H44c-1.7 0-2 2.6 0 2.6h5.7c5.2 0 9.5-4.3 9.5-9.5 0-4.7-3.7-8.7-8.3-9.3z"/></g></svg>`,


          // Rain
          18: `<svg id="weather-icon-rain" class="weather-icon weather-icon-white rain" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.stRain0{fill:#55acee;} .st1{fill:#202020;}</style><g id="rain"><path class="stRain0" d="M25.5 59.3c-.2 0-.3 0-.5-.1-.7-.3-1-1.1-.7-1.7l9.3-21.9c.3-.7 1.1-1 1.7-.7.7.3 1 1.1.7 1.7l-9.3 21.9c-.2.5-.7.8-1.2.8zM34 53.5c-.2 0-.3 0-.5-.1-.7-.3-1-1.1-.7-1.7l6.6-16c.3-.7 1.1-1 1.7-.7.7.3 1 1.1.7 1.7l-6.6 16c-.2.5-.7.8-1.2.8zM21.9 53.5c-.2 0-.3 0-.5-.1-.7-.3-1-1.1-.7-1.7l6.6-16c.3-.7 1.1-1 1.7-.7.7.3 1 1.1.7 1.7l-6.6 16c-.2.5-.6.8-1.2.8z"/><path class="st1" d="M50.9 22.4c.1-.8.2-1.6.2-2.4 0-7.4-6-13.4-13.4-13.4-4.5 0-8.7 2.3-11.2 6-1.2-.5-2.4-.8-3.7-.8-5.1 0-9.3 4.2-9.3 9.3v.3c-4.1 1.4-7 5.2-7 9.7 0 5.6 4.6 10.2 10.2 10.2h5c1.6 0 1.5-2.7 0-2.7h-5c-4.2 0-7.5-3.4-7.5-7.5 0-3.6 2.5-6.7 6.1-7.4l1.3-.3-.2-1.3c-.1-.4-.1-.7-.1-1.1 0-3.6 3-6.6 6.6-6.6 1.2 0 2.4.3 3.5 1l1.2.7.7-1.3c1.9-3.5 5.5-5.7 9.5-5.7 5.9 0 10.7 4.8 10.7 10.7 0 1.1-.2 2.2-.5 3.3l-.6 1.9 2.1-.1h.3c3.8 0 6.8 3.1 6.8 6.8 0 3.8-3.1 6.8-6.8 6.8h-5.7c-1.7 0-2 2.7 0 2.7h5.7c5.2 0 9.5-4.3 9.5-9.5-.1-4.7-3.7-8.7-8.4-9.3z"/></g></svg>`,
          
          //Flurries
          19: `<svg id="weather-icon-flurries" class="weather-icon weather-icon-white flurries" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.st0{fill:#72B8D4;} .st1{fill:#202020;}</style><g id="flurries"><path class="st0" d="M39.2 41c.7-.9.5-2.2-.4-2.9-.9-.7-2.2-.5-2.9.4-.7.9-.5 2.2.4 2.9.9.7 2.2.5 2.9-.4z"/><ellipse transform="rotate(-52.533 28.135 39.777)" class="st0" cx="28.1" cy="39.8" rx="2.1" ry="2.1"/><path class="st0" d="M39.2 58.1c.7-.9.5-2.2-.4-2.9-.9-.7-2.2-.5-2.9.4-.7.9-.5 2.2.4 2.9.9.7 2.2.6 2.9-.4z"/><ellipse transform="rotate(-52.533 28.134 56.89)" class="st0" cx="28.1" cy="56.9" rx="2.1" ry="2.1"/><ellipse transform="rotate(-52.57 32.522 48.335)" class="st0" cx="32.5" cy="48.3" rx="2.1" ry="2.1"/><path class="st0" d="M23.4 49.6c.7-.9.5-2.2-.4-2.9-.9-.7-2.2-.5-2.9.4-.7.9-.5 2.2.4 2.9.9.7 2.2.5 2.9-.4zM44.9 49.6c.7-.9.5-2.2-.4-2.9-.9-.7-2.2-.5-2.9.4-.6.9-.5 2.2.4 2.9.9.7 2.2.5 2.9-.4z"/><path class="st1" d="M50.9 22.3c.2-.8.2-1.6.2-2.4 0-7.4-6-13.4-13.4-13.4-4.5 0-8.7 2.3-11.2 6-1.2-.5-2.4-.8-3.6-.8-5.1 0-9.3 4.2-9.3 9.3v.3c-4.1 1.4-7 5.3-7 9.7 0 5.6 4.6 10.2 10.2 10.2h5c1.6 0 1.5-2.7 0-2.7h-5c-4.2 0-7.5-3.4-7.5-7.5 0-3.6 2.5-6.7 6-7.4l1.3-.2-.2-1.3c-.1-.4-.1-.7-.1-1.1 0-3.6 3-6.6 6.6-6.6 1.2 0 2.4.3 3.5 1l1.2.7.7-1.3c1.9-3.5 5.5-5.7 9.5-5.7 5.9 0 10.7 4.8 10.7 10.7 0 1.1-.2 2.2-.5 3.3l-.6 1.9 2.1-.1h.3c3.8 0 6.8 3.1 6.8 6.8 0 3.8-3.1 6.8-6.8 6.8h-5.7c-1.7 0-2 2.7 0 2.7h5.7c5.2 0 9.5-4.3 9.5-9.5-.1-4.8-3.8-8.8-8.4-9.4z"/></g></svg>`,
        
          //Mostly Cloudy
          20: `<svg id="weather-icon-mostlycloudy" class="weather-icon weather-icon-white mostlycloudy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.st0{fill:#F4A71D;} .st1{fill:#202020;}</style><g id="mostlycloudy"><path class="st0" d="M43.1 13.9c-.7 0-1.2-.6-1.2-1.2V9.2c0-.7.6-1.2 1.2-1.2.7 0 1.2.6 1.2 1.2v3.4c0 .7-.5 1.3-1.2 1.3zM57.6 25h-3.4c-.7 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2h3.4c.7 0 1.2.5 1.2 1.2.1.7-.5 1.2-1.2 1.2zM51.3 17.1c-.3 0-.6-.1-.9-.4-.5-.5-.5-1.3 0-1.7l2.4-2.4c.5-.5 1.3-.5 1.7 0 .5.5.5 1.3 0 1.7l-2.4 2.4c-.1.3-.5.4-.8.4zM53.7 35.4c-.3 0-.6-.1-.9-.4l-2.4-2.4c-.5-.5-.5-1.3 0-1.7.5-.5 1.3-.5 1.7 0l2.4 2.4c.5.5.5 1.3 0 1.7-.1.3-.5.4-.8.4zM35.3 17.2c-.3 0-.6-.1-.9-.4L32 14.5c-.5-.5-.5-1.3 0-1.7.5-.5 1.3-.5 1.7 0l2.4 2.4c.5.5.5 1.3 0 1.7-.2.2-.5.3-.8.3z"/><path class="st1" d="M45.6 57.8H16c-5.1 0-9.3-4.2-9.3-9.3 0-4 2.6-7.6 6.3-8.8v-.2c0-4.7 3.8-8.5 8.5-8.5 1.1 0 2.2.2 3.2.7 2.2-3.4 6-5.4 10.1-5.4 6.7 0 12.2 5.5 12.2 12.2 0 .7-.1 1.4-.2 2.1 4.2.6 7.5 4.2 7.5 8.6-.1 4.7-3.9 8.6-8.7 8.6zM21.4 33.7c-3.2 0-5.8 2.6-5.8 5.8 0 .3 0 .6.1 1l.2 1.3-1.3.2c-3.1.6-5.3 3.4-5.3 6.5 0 3.7 3 6.7 6.7 6.7h29.6c3.3 0 6-2.7 6-6s-2.7-6-6-6h-.3l-2 .1.6-1.9c.3-.9.5-1.9.5-2.9 0-5.3-4.3-9.5-9.5-9.5-3.5 0-6.8 2-8.4 5.1l-.7 1.3-1.2-.7c-1-.8-2.1-1-3.2-1z"/><path class="st0" d="M44.6 16c-4.2-.8-8.2 2-8.9 6.2-.1.4-.1.7-.1 1.1-.1.5.3 1 .9 1.1 1.1.1 1-1 1.2-1.7.6-3.1 3.5-5.1 6.6-4.6 3.1.6 5.1 3.5 4.6 6.6-.1.7-.3 1.4-.7 2-.5.7-1.3 1.3-2 1.8-.6.4-.3 1.3.4 1.4.8.2 1.5-.4 2.1-1 .7-.6 1.3-1.5 1.7-2.4.2-.5.4-1 .5-1.5.7-4.3-2.1-8.3-6.3-9z"/></g></svg>`,
    
          
          //chance Flurries
          21: `<svg id="weather-icon-chanceflurries" class="weather-icon weather-icon-white chanceflurries" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.st0{fill:#202020;} .st1{fill:#72B8D4;}</style><g id="chanceflurries"><path class="st0" d="M51 22c.1-.8.2-1.6.2-2.4 0-7.4-6-13.4-13.4-13.4-4.6 0-8.7 2.3-11.2 6-1.2-.5-2.4-.8-3.7-.8-5.1 0-9.3 4.2-9.3 9.3v.3c-4.1 1.4-7 5.3-7 9.7 0 5.6 4.6 10.2 10.2 10.2h5c1.6 0 1.5-2.7 0-2.7h-5c-4.2 0-7.6-3.4-7.6-7.6 0-3.6 2.6-6.7 6.1-7.4l1.3-.2-.2-1.3c-.1-.4-.1-.8-.1-1.1 0-3.7 3-6.6 6.6-6.6 1.2 0 2.4.3 3.5 1l1.2.7.7-1.3c1.9-3.5 5.5-5.8 9.5-5.8 5.9 0 10.8 4.8 10.8 10.8 0 1.1-.2 2.2-.5 3.3l-.6 1.9 2.1-.1h.3c3.8 0 6.9 3.1 6.9 6.9 0 3.8-3.1 6.9-6.9 6.9h-5.8c-1.7 0-2 2.7 0 2.7h5.8c5.2 0 9.5-4.3 9.5-9.5 0-4.9-3.7-8.9-8.4-9.5z"/><path class="st1" d="M40.9 49.3c.7-.9.5-2.2-.4-2.9-.9-.7-2.2-.5-2.9.4-.6.9-.5 2.2.4 2.9.9.7 2.2.5 2.9-.4zM28.2 49.3c.7-.9.5-2.2-.4-2.9-.9-.7-2.2-.5-2.9.4-.7.9-.5 2.2.4 2.9.9.7 2.2.5 2.9-.4z"/><ellipse transform="rotate(-52.562 32.904 56.797)" class="st1" cx="32.9" cy="56.8" rx="2.1" ry="2.1"/><ellipse transform="rotate(-52.54 32.904 39.302)" class="st1" cx="32.9" cy="39.3" rx="2.1" ry="2.1"/></g></svg>`,
          
          
          //Snow
          22: `<svg id="weather-icon-snow" class="weather-icon weather-icon-white snow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.st0{fill:#72B8D4;}</style><path class="st0" d="M51.6 32h-5.2l2.2-2.2c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L43.6 32h-8.3l5.9-5.9h5.1c.5 0 1-.4 1-1 0-.5-.4-1-1-1h-3.1l3.7-3.7c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-3.7 3.7v-3.1c0-.5-.4-1-1-1-.5 0-1 .4-1 1v5.1l-5.9 5.9v-8.3l3.6-3.6c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-2.2 2.2v-5.2c0-.5-.4-1-1-1-.5 0-1 .4-1 1v5.2l-2.2-2.2c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l3.6 3.6v8.3L26 24.7v-5.1c0-.5-.4-1-1-1-.5 0-1 .4-1 1v3.1L20.3 19c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l3.7 3.7h-3.1c-.5 0-1 .4-1 1 0 .5.4 1 1 1h5.1l5.9 5.9h-8.3l-3.6-3.6c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l2.2 2.2h-5.2c-.5 0-1 .4-1 1 0 .5.4 1 1 1h5.2l-2.2 2.2c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.2 0 .5-.1.7-.3l3.6-3.6h8.3l-5.9 5.9h-5.1c-.5 0-1 .4-1 1 0 .5.4 1 1 1h3.1L19 45.4c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.2 0 .5-.1.7-.3l3.7-3.7v3.1c0 .5.4 1 1 1 .5 0 1-.4 1-1v-5.1l5.9-5.9v8.3l-3.6 3.6c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3l2.2-2.2v5.2c0 .5.4 1 1 1 .5 0 1-.4 1-1v-5.2l2.2 2.2c.2.2.4.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4L34 43.5v-8.3l5.9 5.9v5.1c0 .5.4 1 1 1 .5 0 1-.4 1-1v-3.1l3.7 3.7c.2.2.4.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-3.7-3.7h3.1c.5 0 1-.4 1-1 0-.5-.4-1-1-1h-5.1l-5.9-5.9h8.3l3.6 3.6c.2.2.4.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-2.2-2.2h5.2c.5 0 1-.4 1-1-.2-.4-.6-.8-1.1-.8z" id="snow"/></svg>`,
          
          //Mostly cloudy Flurries
          23: `<svg id="weather-icon-chanceflurries" class="weather-icon weather-icon-white chanceflurries" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.st0{fill:#202020;} .st1{fill:#72B8D4;}</style><g id="chanceflurries"><path class="st0" d="M51 22c.1-.8.2-1.6.2-2.4 0-7.4-6-13.4-13.4-13.4-4.6 0-8.7 2.3-11.2 6-1.2-.5-2.4-.8-3.7-.8-5.1 0-9.3 4.2-9.3 9.3v.3c-4.1 1.4-7 5.3-7 9.7 0 5.6 4.6 10.2 10.2 10.2h5c1.6 0 1.5-2.7 0-2.7h-5c-4.2 0-7.6-3.4-7.6-7.6 0-3.6 2.6-6.7 6.1-7.4l1.3-.2-.2-1.3c-.1-.4-.1-.8-.1-1.1 0-3.7 3-6.6 6.6-6.6 1.2 0 2.4.3 3.5 1l1.2.7.7-1.3c1.9-3.5 5.5-5.8 9.5-5.8 5.9 0 10.8 4.8 10.8 10.8 0 1.1-.2 2.2-.5 3.3l-.6 1.9 2.1-.1h.3c3.8 0 6.9 3.1 6.9 6.9 0 3.8-3.1 6.9-6.9 6.9h-5.8c-1.7 0-2 2.7 0 2.7h5.8c5.2 0 9.5-4.3 9.5-9.5 0-4.9-3.7-8.9-8.4-9.5z"/><path class="st1" d="M40.9 49.3c.7-.9.5-2.2-.4-2.9-.9-.7-2.2-.5-2.9.4-.6.9-.5 2.2.4 2.9.9.7 2.2.5 2.9-.4zM28.2 49.3c.7-.9.5-2.2-.4-2.9-.9-.7-2.2-.5-2.9.4-.7.9-.5 2.2.4 2.9.9.7 2.2.5 2.9-.4z"/><ellipse transform="rotate(-52.562 32.904 56.797)" class="st1" cx="32.9" cy="56.8" rx="2.1" ry="2.1"/><ellipse transform="rotate(-52.54 32.904 39.302)" class="st1" cx="32.9" cy="39.3" rx="2.1" ry="2.1"/></g></svg>`,
          
          //Sleet
          24: `<svg id="weather-icon-sleet" class="weather-icon weather-icon-white sleet" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.st0{fill:#72B8D4;} .st1{fill:#202020;}</style><g id="sleet"><path class="st0" d="M22.3 57.4l-2.8.9-.9-2.7 2.8-.9zM22.6 52.7c-.1 0-.3 0-.4-.1-.6-.2-.9-.9-.7-1.6l5-12.3c.2-.6.9-.9 1.6-.7.6.3.9.9.6 1.6l-5 12.3c-.2.5-.7.8-1.1.8zM24.61 55.844l2.752-.918.918 2.75-2.75.92zM28.6 53c-.2 0-.3 0-.5-.1-.6-.2-.9-.9-.6-1.6l5-12.3c.2-.6.9-.9 1.6-.6.6.2.9.9.7 1.6l-5 12.3c-.3.4-.7.7-1.2.7zM30.71 55.828l2.75-.918.92 2.75-2.752.92zM34.6 53c-.1 0-.3 0-.5-.1-.6-.2-.9-.9-.6-1.6l5-12.3c.2-.6.9-.9 1.6-.6.6.2.9.9.6 1.6l-5 12.3c-.2.4-.6.7-1.1.7z"/><path class="st1" d="M50.9 24.2c.1-.8.2-1.6.2-2.4 0-7.4-6-13.4-13.4-13.4-4.5 0-8.7 2.3-11.2 6-1.2-.5-2.4-.8-3.6-.8-5.1 0-9.3 4.2-9.3 9.3v.3c-4.1 1.4-7 5.2-7 9.7 0 5.6 4.6 10.2 10.2 10.2h5c1.6 0 1.5-2.7 0-2.7h-5c-4.2 0-7.5-3.4-7.5-7.5 0-3.6 2.5-6.7 6.1-7.4l1.3-.3-.3-1.2c-.1-.4-.1-.8-.1-1.1 0-3.6 3-6.6 6.6-6.6 1.2 0 2.4.3 3.5 1l1.2.7.7-1.3c1.9-3.5 5.5-5.7 9.5-5.7 5.9 0 10.7 4.8 10.7 10.7 0 1.1-.2 2.2-.5 3.3l-.6 1.9 2.1-.1h.3c3.8 0 6.8 3.1 6.8 6.8 0 3.8-3.1 6.8-6.8 6.8h-5.7c-1.7 0-2 2.7 0 2.7h5.7c5.2 0 9.5-4.3 9.5-9.5-.1-4.8-3.8-8.8-8.4-9.4z"/></g></svg>`,
        
          
          //Sleet
          25: `<svg id="weather-icon-sleet" class="weather-icon weather-icon-white sleet" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.st0{fill:#72B8D4;} .st1{fill:#202020;}</style><g id="sleet"><path class="st0" d="M22.3 57.4l-2.8.9-.9-2.7 2.8-.9zM22.6 52.7c-.1 0-.3 0-.4-.1-.6-.2-.9-.9-.7-1.6l5-12.3c.2-.6.9-.9 1.6-.7.6.3.9.9.6 1.6l-5 12.3c-.2.5-.7.8-1.1.8zM24.61 55.844l2.752-.918.918 2.75-2.75.92zM28.6 53c-.2 0-.3 0-.5-.1-.6-.2-.9-.9-.6-1.6l5-12.3c.2-.6.9-.9 1.6-.6.6.2.9.9.7 1.6l-5 12.3c-.3.4-.7.7-1.2.7zM30.71 55.828l2.75-.918.92 2.75-2.752.92zM34.6 53c-.1 0-.3 0-.5-.1-.6-.2-.9-.9-.6-1.6l5-12.3c.2-.6.9-.9 1.6-.6.6.2.9.9.6 1.6l-5 12.3c-.2.4-.6.7-1.1.7z"/><path class="st1" d="M50.9 24.2c.1-.8.2-1.6.2-2.4 0-7.4-6-13.4-13.4-13.4-4.5 0-8.7 2.3-11.2 6-1.2-.5-2.4-.8-3.6-.8-5.1 0-9.3 4.2-9.3 9.3v.3c-4.1 1.4-7 5.2-7 9.7 0 5.6 4.6 10.2 10.2 10.2h5c1.6 0 1.5-2.7 0-2.7h-5c-4.2 0-7.5-3.4-7.5-7.5 0-3.6 2.5-6.7 6.1-7.4l1.3-.3-.3-1.2c-.1-.4-.1-.8-.1-1.1 0-3.6 3-6.6 6.6-6.6 1.2 0 2.4.3 3.5 1l1.2.7.7-1.3c1.9-3.5 5.5-5.7 9.5-5.7 5.9 0 10.7 4.8 10.7 10.7 0 1.1-.2 2.2-.5 3.3l-.6 1.9 2.1-.1h.3c3.8 0 6.8 3.1 6.8 6.8 0 3.8-3.1 6.8-6.8 6.8h-5.7c-1.7 0-2 2.7 0 2.7h5.7c5.2 0 9.5-4.3 9.5-9.5-.1-4.8-3.8-8.8-8.4-9.4z"/></g></svg>`,
        
          //Sleet
          26: `<svg id="weather-icon-sleet" class="weather-icon weather-icon-white sleet" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.st0{fill:#72B8D4;} .st1{fill:#202020;}</style><g id="sleet"><path class="st0" d="M22.3 57.4l-2.8.9-.9-2.7 2.8-.9zM22.6 52.7c-.1 0-.3 0-.4-.1-.6-.2-.9-.9-.7-1.6l5-12.3c.2-.6.9-.9 1.6-.7.6.3.9.9.6 1.6l-5 12.3c-.2.5-.7.8-1.1.8zM24.61 55.844l2.752-.918.918 2.75-2.75.92zM28.6 53c-.2 0-.3 0-.5-.1-.6-.2-.9-.9-.6-1.6l5-12.3c.2-.6.9-.9 1.6-.6.6.2.9.9.7 1.6l-5 12.3c-.3.4-.7.7-1.2.7zM30.71 55.828l2.75-.918.92 2.75-2.752.92zM34.6 53c-.1 0-.3 0-.5-.1-.6-.2-.9-.9-.6-1.6l5-12.3c.2-.6.9-.9 1.6-.6.6.2.9.9.6 1.6l-5 12.3c-.2.4-.6.7-1.1.7z"/><path class="st1" d="M50.9 24.2c.1-.8.2-1.6.2-2.4 0-7.4-6-13.4-13.4-13.4-4.5 0-8.7 2.3-11.2 6-1.2-.5-2.4-.8-3.6-.8-5.1 0-9.3 4.2-9.3 9.3v.3c-4.1 1.4-7 5.2-7 9.7 0 5.6 4.6 10.2 10.2 10.2h5c1.6 0 1.5-2.7 0-2.7h-5c-4.2 0-7.5-3.4-7.5-7.5 0-3.6 2.5-6.7 6.1-7.4l1.3-.3-.3-1.2c-.1-.4-.1-.8-.1-1.1 0-3.6 3-6.6 6.6-6.6 1.2 0 2.4.3 3.5 1l1.2.7.7-1.3c1.9-3.5 5.5-5.7 9.5-5.7 5.9 0 10.7 4.8 10.7 10.7 0 1.1-.2 2.2-.5 3.3l-.6 1.9 2.1-.1h.3c3.8 0 6.8 3.1 6.8 6.8 0 3.8-3.1 6.8-6.8 6.8h-5.7c-1.7 0-2 2.7 0 2.7h5.7c5.2 0 9.5-4.3 9.5-9.5-.1-4.8-3.8-8.8-8.4-9.4z"/></g></svg>`,
        
          //Showers
          29: `<svg id="weather-icon-rain" class="weather-icon weather-icon-white rain" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.st0{fill:#2885C7;} .st1{fill:#202020;}</style><g id="rain"><path class="st0" d="M25.5 59.3c-.2 0-.3 0-.5-.1-.7-.3-1-1.1-.7-1.7l9.3-21.9c.3-.7 1.1-1 1.7-.7.7.3 1 1.1.7 1.7l-9.3 21.9c-.2.5-.7.8-1.2.8zM34 53.5c-.2 0-.3 0-.5-.1-.7-.3-1-1.1-.7-1.7l6.6-16c.3-.7 1.1-1 1.7-.7.7.3 1 1.1.7 1.7l-6.6 16c-.2.5-.7.8-1.2.8zM21.9 53.5c-.2 0-.3 0-.5-.1-.7-.3-1-1.1-.7-1.7l6.6-16c.3-.7 1.1-1 1.7-.7.7.3 1 1.1.7 1.7l-6.6 16c-.2.5-.6.8-1.2.8z"/><path class="st1" d="M50.9 22.4c.1-.8.2-1.6.2-2.4 0-7.4-6-13.4-13.4-13.4-4.5 0-8.7 2.3-11.2 6-1.2-.5-2.4-.8-3.7-.8-5.1 0-9.3 4.2-9.3 9.3v.3c-4.1 1.4-7 5.2-7 9.7 0 5.6 4.6 10.2 10.2 10.2h5c1.6 0 1.5-2.7 0-2.7h-5c-4.2 0-7.5-3.4-7.5-7.5 0-3.6 2.5-6.7 6.1-7.4l1.3-.3-.2-1.3c-.1-.4-.1-.7-.1-1.1 0-3.6 3-6.6 6.6-6.6 1.2 0 2.4.3 3.5 1l1.2.7.7-1.3c1.9-3.5 5.5-5.7 9.5-5.7 5.9 0 10.7 4.8 10.7 10.7 0 1.1-.2 2.2-.5 3.3l-.6 1.9 2.1-.1h.3c3.8 0 6.8 3.1 6.8 6.8 0 3.8-3.1 6.8-6.8 6.8h-5.7c-1.7 0-2 2.7 0 2.7h5.7c5.2 0 9.5-4.3 9.5-9.5-.1-4.7-3.7-8.7-8.4-9.3z"/></g></svg>`,
        
        
          //nt_sunny
          33: `<svg version="1.1" id="Layer_4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve"><style type="text/css">.stnt_sunny0{fill:#202020;}</style><path class="stnt_sunny0" d="M50.5,35.1C39,35.1,26.3,26,32.1,11.2l0,0c-12.4-0.1-20.9,10-20.9,20.8c0,11.5,9.3,20.9,20.9,20.9c10.5,0,19.2-7.6,20.7-17.7C52.8,35.1,51.3,35.1,50.5,35.1z M32.4,49.8c-9.9,0-17.9-8-17.9-17.9c0-8.2,5.8-15.1,13.3-17.3l0,0.1c-1.8,12.5,7.1,23,20.7,24.2l0,0C45,45.7,40.2,49.5,32.4,49.8z"/></svg>`,
  
      
          //nt_clear
          34: `<svg version="1.1" id="Layer_4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve"><style type="text/css">.st0{fill:#72B8D4;}</style><path class="st0" d="M50.5,35.1C39,35.1,26.3,26,32.1,11.2l0,0c-12.4-0.1-20.9,10-20.9,20.8c0,11.5,9.3,20.9,20.9,20.9c10.5,0,19.2-7.6,20.7-17.7C52.8,35.1,51.3,35.1,50.5,35.1z M32.4,49.8c-9.9,0-17.9-8-17.9-17.9c0-8.2,5.8-15.1,13.3-17.3l0,0.1c-1.8,12.5,7.1,23,20.7,24.2l0,0C45,45.7,40.2,49.5,32.4,49.8z"/></svg>`,
                
        
          //nt_partlycloudy
          35: `<svg version="1.1" id="weather-icon-mostlysunny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve"><style type="text/css">.st0{fill:#202020;}.st1{fill:#72B8D4;}</style><path class="st0" d="M40.2,58.2H15.1c-4.6,0-8.4-3.8-8.4-8.4c0-3.5,2.1-6.7,5.4-7.9c0.1-4.1,3.5-7.5,7.8-7.5c0.9,0,1.8,0.1,2.5,0.5c2-2.8,5.3-4.5,8.8-4.5c6,0,10.9,4.9,10.9,10.9c0,0.5,0,0.9-0.1,1.4c3.5,0.8,6.3,3.9,6.3,7.8C48.1,54.7,44.6,58.2,40.2,58.2z M19.8,37.7c-2.4,0-4.4,2-4.4,4.4c0,0.3,0,0.5,0.1,0.8l0.3,1.6l-1.6,0.4c-2.4,0.5-4.1,2.6-4.1,5c0,2.8,2.3,5.1,5.1,5.1h25.1c2.5,0,4.5-2,4.5-4.5s-2-4.5-4.5-4.5H40l-2.5,0.1l0.8-2.4c0.3-0.8,0.4-1.5,0.4-2.4c0-4.1-3.4-7.5-7.5-7.5c-2.8,0-5.4,1.5-6.7,4l-0.9,1.6L22,38.6C21.4,38,20.5,37.7,19.8,37.7z"/><path class="st1" d="M58.1,24.7c0,0-1.1,0-1.6,0c-5,0-9.8-2.3-12.3-5.9c-2.1-3.1-2.4-7.4-0.9-11.4l0,0H43c-4.1,0-8.1,1.5-10.9,4.4c-2.9,2.9-4.6,6.9-4.6,11.1c0,1.3,0.2,2.7,0.5,4l0.1,0.4l0.4-0.1c0.7-0.2,1.5-0.3,2.3-0.4h0.5l-0.1-0.4c-0.3-1.1-0.5-2.3-0.5-3.4c0-5.3,3.6-10.2,8.8-12c-0.5,4.1,0.6,8,3.1,11.1c2.7,3.5,6.9,5.7,11.7,6.2c-2.2,4.2-5,6.4-9,7.1l-0.4,0.1l0.2,0.4c0.3,0.7,0.5,1.4,0.6,2.1l0.1,0.4l0.4-0.1c6.5-1.3,11.5-6.4,12.4-12.9l0.1-0.4L58.1,24.7z"/></svg>`,
          
          
        
          //nt_partlysunny
          36: `<svg version="1.1" id="weather-icon-mostlycloudy" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve"><style type="text/css">.st0{fill:#202020;}.st1{fill:#72B8D4;}</style><path class="st0" d="M49.3,57.8H16.9c-5.6,0-10.2-4.6-10.2-10.2c0-4.4,2.8-8.3,6.9-9.6v-0.2c0-5.1,4.2-9.3,9.3-9.3c1.2,0,2.4,0.2,3.5,0.8c2.4-3.7,6.6-5.9,11.1-5.9c7.3,0,13.4,6,13.4,13.4c0,0.8-0.1,1.5-0.2,2.3c4.6,0.7,8.2,4.6,8.2,9.4C58.7,53.5,54.5,57.8,49.3,57.8z M22.8,31.4c-3.5,0-6.3,2.8-6.3,6.3c0,0.3,0,0.7,0.1,1.1l0.2,1.4l-1.4,0.2c-3.4,0.7-5.8,3.7-5.8,7.1c0,4,3.3,7.3,7.3,7.3h32.4c3.6,0,6.6-3,6.6-6.6s-3-6.6-6.6-6.6H49l-2.2,0.1l0.7-2.1c0.3-1,0.5-2.1,0.5-3.2C48,30.6,43.3,26,37.6,26c-3.8,0-7.4,2.2-9.2,5.6L27.6,33l-1.3-0.8C25.2,31.6,24,31.4,22.8,31.4z"/><path class="st1" d="M57.3,19.8c-3.3,0-6.4-1.5-8-3.9c-1.3-2-1.6-5.1-0.6-7.7l0,0h-0.3c-2.8,0-5.5,1-7.5,3s-3.2,4.7-3.2,7.5c0,0.4,0,0.8,0.1,1.2v0.4h0.4c0.5,0,1.1,0.1,1.6,0.2l0.6,0.1L40.3,20c-0.1-0.5-0.1-0.9-0.1-1.3c0-3.4,2.2-6.5,5.4-7.7c-0.2,2.7,0.5,5.2,2.2,7.3c1.8,2.3,4.5,3.8,7.7,4.2c-1,1.9-2.3,3.1-3.9,3.8L51,26.5l0.4,0.5c0.3,0.4,0.6,0.8,0.9,1.2l0.2,0.3l0.3-0.2c3.2-1.5,5.4-4.5,5.9-8l0.1-0.5H57.3z"/></svg>`,


        
          //Hazy Moonlight
          37: `<svg id="weather-icon-hazy" class="weather-icon weather-icon-white hazy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.st0{fill:#202020;}</style><g id="fog"><path class="st0" d="M41.7 38.5c-3.2 0-6.1-.9-8.7-2.7-7.2-5.1-13.2-5.3-19.6-.9-.5.3-1.1.2-1.4-.3-.3-.5-.2-1.1.3-1.4 7.1-4.9 14.1-4.6 21.9.9 6.2 4.4 13.3 1.9 18.2-1 .5-.3 1.1-.1 1.4.4.3.5.1 1.1-.4 1.4-4 2.4-8 3.6-11.7 3.6z"/><path class="st0" d="M41.7 46.3c-3.2 0-6.1-.9-8.7-2.7-7.2-5.1-13.2-5.3-19.6-.9-.5.3-1.1.2-1.4-.3-.3-.5-.2-1.1.3-1.4 7.1-4.9 14.1-4.6 21.9.9 6.2 4.3 13.3 1.9 18.2-1 .5-.3 1.1-.1 1.4.4.3.5.1 1.1-.4 1.4-4 2.4-8 3.6-11.7 3.6zM41.7 30.8c-3.2 0-6.1-.9-8.7-2.7-7.2-5.1-13.2-5.3-19.6-.9-.5.3-1.1.2-1.4-.3-.3-.5-.2-1.1.3-1.4 7.1-4.9 14.1-4.6 21.9.9 6.2 4.4 13.3 1.9 18.2-1 .5-.3 1.1-.1 1.4.4.3.5.1 1.1-.4 1.4-4 2.4-8 3.6-11.7 3.6z"/></g></svg>`,
  
  
          //nt_mostlycloudy
          38: `<svg version="1.1" id="weather-icon-mostlycloudy" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve"><style type="text/css">.st0{fill:#202020;}.st1{fill:#72B8D4;}</style><path class="st0" d="M49.3,57.8H16.9c-5.6,0-10.2-4.6-10.2-10.2c0-4.4,2.8-8.3,6.9-9.6v-0.2c0-5.1,4.2-9.3,9.3-9.3c1.2,0,2.4,0.2,3.5,0.8c2.4-3.7,6.6-5.9,11.1-5.9c7.3,0,13.4,6,13.4,13.4c0,0.8-0.1,1.5-0.2,2.3c4.6,0.7,8.2,4.6,8.2,9.4C58.7,53.5,54.5,57.8,49.3,57.8z M22.8,31.4c-3.5,0-6.3,2.8-6.3,6.3c0,0.3,0,0.7,0.1,1.1l0.2,1.4l-1.4,0.2c-3.4,0.7-5.8,3.7-5.8,7.1c0,4,3.3,7.3,7.3,7.3h32.4c3.6,0,6.6-3,6.6-6.6s-3-6.6-6.6-6.6H49l-2.2,0.1l0.7-2.1c0.3-1,0.5-2.1,0.5-3.2C48,30.6,43.3,26,37.6,26c-3.8,0-7.4,2.2-9.2,5.6L27.6,33l-1.3-0.8C25.2,31.6,24,31.4,22.8,31.4z"/><path class="st1" d="M57.3,19.8c-3.3,0-6.4-1.5-8-3.9c-1.3-2-1.6-5.1-0.6-7.7l0,0h-0.3c-2.8,0-5.5,1-7.5,3s-3.2,4.7-3.2,7.5c0,0.4,0,0.8,0.1,1.2v0.4h0.4c0.5,0,1.1,0.1,1.6,0.2l0.6,0.1L40.3,20c-0.1-0.5-0.1-0.9-0.1-1.3c0-3.4,2.2-6.5,5.4-7.7c-0.2,2.7,0.5,5.2,2.2,7.3c1.8,2.3,4.5,3.8,7.7,4.2c-1,1.9-2.3,3.1-3.9,3.8L51,26.5l0.4,0.5c0.3,0.4,0.6,0.8,0.9,1.2l0.2,0.3l0.3-0.2c3.2-1.5,5.4-4.5,5.9-8l0.1-0.5H57.3z"/></svg>`,
          
          //nt_chancerain
          39: `<svg id="weather-icon-chancerain" class="weather-icon weather-icon-white chancerain" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.st0{fill:#202020;} .st1{fill:#2885C7;}</style><g id="chancerain"><path class="st0" d="M50.9 24.1c.1-.8.2-1.6.2-2.4 0-7.4-6-13.4-13.4-13.4-4.5 0-8.7 2.3-11.2 6-1.2-.5-2.4-.8-3.7-.8-5.1 0-9.3 4.1-9.3 9.3v.3c-4.1 1.4-7 5.2-7 9.7 0 5.6 4.6 10.2 10.2 10.2h5c1.6 0 1.5-2.7 0-2.7h-5c-4.2 0-7.5-3.4-7.5-7.5 0-3.6 2.5-6.7 6.1-7.4l1.3-.3-.2-1.3c-.1-.4-.1-.7-.1-1.1 0-3.6 3-6.6 6.6-6.6 1.2 0 2.4.3 3.5 1l1.2.7.7-1.3c1.9-3.5 5.5-5.7 9.5-5.7 5.9 0 10.7 4.8 10.7 10.7 0 1.1-.2 2.2-.5 3.3l-.6 1.9 2.1-.1h.3c3.8 0 6.8 3.1 6.8 6.8 0 3.8-3.1 6.8-6.8 6.8h-5.7c-1.7 0-2 2.7 0 2.7h5.7c5.2 0 9.5-4.3 9.5-9.5-.1-4.7-3.8-8.7-8.4-9.3z"/><path class="st1" d="M24.9 46.5c-.3 0-.5-.1-.8-.2-.6-.4-.7-1.3-.3-1.8l2.4-3.5c.4-.6 1.2-.7 1.8-.3.6.4.7 1.2.3 1.8L25.9 46c-.2.3-.6.5-1 .5zM30.7 46.5c-.3 0-.5-.1-.8-.2-.6-.4-.7-1.3-.3-1.8L32 41c.4-.6 1.3-.7 1.8-.3.6.4.7 1.2.3 1.8L31.7 46c-.2.3-.6.5-1 .5zM36.5 46.5c-.3 0-.5-.1-.8-.2-.6-.4-.7-1.3-.3-1.8l2.4-3.5c.4-.6 1.3-.7 1.8-.3.6.4.7 1.2.3 1.8L37.5 46c-.1.3-.5.5-1 .5zM18.6 56c-.3 0-.5-.1-.8-.2-.6-.4-.7-1.2-.3-1.8l2.4-3.5c.4-.6 1.3-.7 1.9-.3.6.4.7 1.2.3 1.8l-2.4 3.5c-.3.3-.7.5-1.1.5zM24.5 56c-.3 0-.5-.1-.8-.2-.6-.4-.7-1.2-.3-1.8l2.4-3.5c.4-.6 1.2-.7 1.8-.3.6.4.7 1.2.3 1.8l-2.4 3.5c-.2.3-.6.5-1 .5zM30.3 56c-.3 0-.5-.1-.8-.2-.6-.4-.7-1.2-.3-1.8l2.4-3.5c.4-.6 1.2-.7 1.8-.3.6.4.7 1.2.3 1.8l-2.4 3.5c-.2.3-.6.5-1 .5z"/></g></svg>`,
          
          // Night rain
          40: `<svg id="weather-icon-rain" class="weather-icon weather-icon-white rain" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.st0{fill:#2885C7;} .st1{fill:#202020;}</style><g id="rain"><path class="st0" d="M25.5 59.3c-.2 0-.3 0-.5-.1-.7-.3-1-1.1-.7-1.7l9.3-21.9c.3-.7 1.1-1 1.7-.7.7.3 1 1.1.7 1.7l-9.3 21.9c-.2.5-.7.8-1.2.8zM34 53.5c-.2 0-.3 0-.5-.1-.7-.3-1-1.1-.7-1.7l6.6-16c.3-.7 1.1-1 1.7-.7.7.3 1 1.1.7 1.7l-6.6 16c-.2.5-.7.8-1.2.8zM21.9 53.5c-.2 0-.3 0-.5-.1-.7-.3-1-1.1-.7-1.7l6.6-16c.3-.7 1.1-1 1.7-.7.7.3 1 1.1.7 1.7l-6.6 16c-.2.5-.6.8-1.2.8z"/><path class="st1" d="M50.9 22.4c.1-.8.2-1.6.2-2.4 0-7.4-6-13.4-13.4-13.4-4.5 0-8.7 2.3-11.2 6-1.2-.5-2.4-.8-3.7-.8-5.1 0-9.3 4.2-9.3 9.3v.3c-4.1 1.4-7 5.2-7 9.7 0 5.6 4.6 10.2 10.2 10.2h5c1.6 0 1.5-2.7 0-2.7h-5c-4.2 0-7.5-3.4-7.5-7.5 0-3.6 2.5-6.7 6.1-7.4l1.3-.3-.2-1.3c-.1-.4-.1-.7-.1-1.1 0-3.6 3-6.6 6.6-6.6 1.2 0 2.4.3 3.5 1l1.2.7.7-1.3c1.9-3.5 5.5-5.7 9.5-5.7 5.9 0 10.7 4.8 10.7 10.7 0 1.1-.2 2.2-.5 3.3l-.6 1.9 2.1-.1h.3c3.8 0 6.8 3.1 6.8 6.8 0 3.8-3.1 6.8-6.8 6.8h-5.7c-1.7 0-2 2.7 0 2.7h5.7c5.2 0 9.5-4.3 9.5-9.5-.1-4.7-3.7-8.7-8.4-9.3z"/></g></svg>`,


          
          //Night Tstorms
          41: `<svg id="weather-icon-tstorms" class="weather-icon weather-icon-white tstorms" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.st0{fill:#2885C7;} .st1{fill:#F4A71D;} .st2{fill:#202020;}</style><g id="tstorms"><path class="st0" d="M19.7 53c-.2 0-.4 0-.6-.1-.7-.3-.9-1.1-.6-1.8l2.8-5.8c.3-.7 1.1-.9 1.8-.6.7.3.9 1.1.6 1.8l-2.8 5.8c-.3.4-.7.7-1.2.7zM13.1 53c-.2 0-.4 0-.6-.1-.7-.3-.9-1.1-.6-1.8l2.8-5.8c.3-.7 1.1-.9 1.8-.6.7.3.9 1.1.6 1.8l-2.8 5.8c-.2.4-.7.7-1.2.7zM46.8 53c-.2 0-.4 0-.6-.1-.7-.3-.9-1.1-.6-1.8l2.8-5.8c.3-.7 1.1-.9 1.8-.6.7.3.9 1.1.6 1.8L48 52.3c-.2.4-.7.7-1.2.7zM40.2 53c-.2 0-.4 0-.6-.1-.7-.3-.9-1.1-.6-1.8l2.8-5.8c.3-.7 1.1-.9 1.8-.6.7.3.9 1.1.6 1.8l-2.8 5.8c-.2.4-.7.7-1.2.7z"/><path class="st1" d="M27.7 59.3c-.2 0-.5-.1-.7-.2-.6-.4-.8-1.2-.4-1.8l7.9-13.1h-8.3L35.5 31c.4-.6 1.2-.7 1.8-.3.6.4.7 1.3.3 1.9l-6.4 9.1h7.9L28.8 58.6c-.2.4-.7.7-1.1.7z"/><path class="st2" d="M50.9 22.3c.1-.8.2-1.6.2-2.4 0-7.4-6-13.4-13.4-13.4-4.5 0-8.7 2.3-11.2 6-1.1-.5-2.4-.8-3.6-.8-5.1 0-9.3 4.2-9.3 9.3v.3c-4.1 1.4-7 5.2-7 9.7 0 5.6 4.6 10.2 10.2 10.2h5c1.6 0 1.5-2.6 0-2.6h-5c-4.2 0-7.5-3.4-7.5-7.5 0-3.6 2.5-6.7 6.1-7.4l1.3-.2-.2-1.3c-.1-.4-.1-.8-.1-1.1 0-3.6 3-6.6 6.6-6.6 1.2 0 2.4.3 3.5 1l1.2.7.7-1.3c1.9-3.5 5.5-5.7 9.5-5.7 5.9 0 10.7 4.8 10.7 10.7 0 1.1-.2 2.2-.5 3.3l-.8 1.8 2.1-.1h.3c3.8 0 6.8 3.1 6.8 6.8 0 3.8-3.1 6.8-6.8 6.8H44c-1.7 0-2 2.6 0 2.6h5.7c5.2 0 9.5-4.3 9.5-9.5 0-4.7-3.7-8.7-8.3-9.3z"/></g></svg>`,
              
          
          //nt_chancestorms
          42: `<svg id="weather-icon-chancetstorms" class="weather-icon weather-icon-white chancetstorms" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.st0{fill:#202020;} .st1{fill:#F4A71D;}</style><g id="chancetstorms"><path class="st0" d="M50.9 22.4c.1-.8.2-1.6.2-2.4 0-7.4-6-13.4-13.4-13.4-4.5 0-8.7 2.3-11.2 6-1.2-.5-2.4-.8-3.6-.8-5.1 0-9.3 4.2-9.3 9.3v.3c-4.1 1.4-7 5.2-7 9.7 0 5.6 4.6 10.2 10.2 10.2h5c1.6 0 1.5-2.7 0-2.7h-5c-4.2 0-7.5-3.4-7.5-7.5 0-3.6 2.5-6.7 6.1-7.4l1.3-.3-.2-1.3c-.1-.4-.1-.7-.1-1.1 0-3.6 3-6.6 6.6-6.6 1.2 0 2.4.3 3.5 1l1.2.7.7-1.3c1.9-3.5 5.5-5.7 9.5-5.7 5.9 0 10.7 4.8 10.7 10.7 0 1.1-.2 2.2-.5 3.3l-.6 1.9 2.1-.1h.3c3.8 0 6.8 3.1 6.8 6.8 0 3.8-3.1 6.8-6.8 6.8h-5.7c-1.7 0-2 2.7 0 2.7h5.7c5.2 0 9.5-4.3 9.5-9.5-.2-4.7-3.9-8.7-8.5-9.3z"/><path class="st1" d="M27.2 59.2c-.2 0-.5-.1-.7-.2-.6-.4-.8-1.2-.4-1.8L34 44.1h-8.3L35 30.9c.4-.6 1.2-.7 1.8-.3.6.4.7 1.3.3 1.9l-6.4 9.1h7.9l-10.2 17c-.3.4-.7.6-1.2.6z"/></g></svg>`,    
        
          //nt_flurries
          43: `<svg id="weather-icon-flurries" class="weather-icon weather-icon-white flurries" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.st0{fill:#72B8D4;} .st1{fill:#202020;}</style><g id="flurries"><path class="st0" d="M39.2 41c.7-.9.5-2.2-.4-2.9-.9-.7-2.2-.5-2.9.4-.7.9-.5 2.2.4 2.9.9.7 2.2.5 2.9-.4z"/><ellipse transform="rotate(-52.533 28.135 39.777)" class="st0" cx="28.1" cy="39.8" rx="2.1" ry="2.1"/><path class="st0" d="M39.2 58.1c.7-.9.5-2.2-.4-2.9-.9-.7-2.2-.5-2.9.4-.7.9-.5 2.2.4 2.9.9.7 2.2.6 2.9-.4z"/><ellipse transform="rotate(-52.533 28.134 56.89)" class="st0" cx="28.1" cy="56.9" rx="2.1" ry="2.1"/><ellipse transform="rotate(-52.57 32.522 48.335)" class="st0" cx="32.5" cy="48.3" rx="2.1" ry="2.1"/><path class="st0" d="M23.4 49.6c.7-.9.5-2.2-.4-2.9-.9-.7-2.2-.5-2.9.4-.7.9-.5 2.2.4 2.9.9.7 2.2.5 2.9-.4zM44.9 49.6c.7-.9.5-2.2-.4-2.9-.9-.7-2.2-.5-2.9.4-.6.9-.5 2.2.4 2.9.9.7 2.2.5 2.9-.4z"/><path class="st1" d="M50.9 22.3c.2-.8.2-1.6.2-2.4 0-7.4-6-13.4-13.4-13.4-4.5 0-8.7 2.3-11.2 6-1.2-.5-2.4-.8-3.6-.8-5.1 0-9.3 4.2-9.3 9.3v.3c-4.1 1.4-7 5.3-7 9.7 0 5.6 4.6 10.2 10.2 10.2h5c1.6 0 1.5-2.7 0-2.7h-5c-4.2 0-7.5-3.4-7.5-7.5 0-3.6 2.5-6.7 6-7.4l1.3-.2-.2-1.3c-.1-.4-.1-.7-.1-1.1 0-3.6 3-6.6 6.6-6.6 1.2 0 2.4.3 3.5 1l1.2.7.7-1.3c1.9-3.5 5.5-5.7 9.5-5.7 5.9 0 10.7 4.8 10.7 10.7 0 1.1-.2 2.2-.5 3.3l-.6 1.9 2.1-.1h.3c3.8 0 6.8 3.1 6.8 6.8 0 3.8-3.1 6.8-6.8 6.8h-5.7c-1.7 0-2 2.7 0 2.7h5.7c5.2 0 9.5-4.3 9.5-9.5-.1-4.8-3.8-8.8-8.4-9.4z"/></g></svg>`,
          
          //nt_chanceflurries
          44: `<svg id="weather-icon-chanceflurries" class="weather-icon weather-icon-white chanceflurries" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><style>.st0{fill:#202020;} .st1{fill:#72B8D4;}</style><g id="chanceflurries"><path class="st0" d="M51 22c.1-.8.2-1.6.2-2.4 0-7.4-6-13.4-13.4-13.4-4.6 0-8.7 2.3-11.2 6-1.2-.5-2.4-.8-3.7-.8-5.1 0-9.3 4.2-9.3 9.3v.3c-4.1 1.4-7 5.3-7 9.7 0 5.6 4.6 10.2 10.2 10.2h5c1.6 0 1.5-2.7 0-2.7h-5c-4.2 0-7.6-3.4-7.6-7.6 0-3.6 2.6-6.7 6.1-7.4l1.3-.2-.2-1.3c-.1-.4-.1-.8-.1-1.1 0-3.7 3-6.6 6.6-6.6 1.2 0 2.4.3 3.5 1l1.2.7.7-1.3c1.9-3.5 5.5-5.8 9.5-5.8 5.9 0 10.8 4.8 10.8 10.8 0 1.1-.2 2.2-.5 3.3l-.6 1.9 2.1-.1h.3c3.8 0 6.9 3.1 6.9 6.9 0 3.8-3.1 6.9-6.9 6.9h-5.8c-1.7 0-2 2.7 0 2.7h5.8c5.2 0 9.5-4.3 9.5-9.5 0-4.9-3.7-8.9-8.4-9.5z"/><path class="st1" d="M40.9 49.3c.7-.9.5-2.2-.4-2.9-.9-.7-2.2-.5-2.9.4-.6.9-.5 2.2.4 2.9.9.7 2.2.5 2.9-.4zM28.2 49.3c.7-.9.5-2.2-.4-2.9-.9-.7-2.2-.5-2.9.4-.7.9-.5 2.2.4 2.9.9.7 2.2.5 2.9-.4z"/><ellipse transform="rotate(-52.562 32.904 56.797)" class="st1" cx="32.9" cy="56.8" rx="2.1" ry="2.1"/><ellipse transform="rotate(-52.54 32.904 39.302)" class="st1" cx="32.9" cy="39.3" rx="2.1" ry="2.1"/></g></svg>`,

    
        },
      }
    },
    props:['wftkn', 'lang', 'weather', 'preview'],
    created(){
      this.$eventHub.$on('closeForecast', this.closeForecast); 
    },
    components: {
      WeatherForecast
    },
    mounted(){
      this.getWeatherPayload();
    },
    watch: {
      forecast(){
        (this.forecast) ? this.showDetails = true : '';
      }
    },
    computed: {
      unitType(){
        return (this.weatherPayload) ? (this.weatherPayload.UnitType).toLowerCase().replace(/^.|\s\S/g, function(a) { return a.toUpperCase(); }) : null;
      },
      unitString(){
        return (this.weatherPayload) ? this.weatherPayload.CurrentConditions[0].Temperature[this.unitType].Unit : null;
      },
      hasMetric(){
        return !!(this.weather.unit && this.weather.unit=='metric');  
      }
    },
    methods: {
      toggleForecast(){
        this.forecast = !this.forecast;
        this.$eventHub.$emit('closeDetails');
        (!this.forecast) ? this.showDetails = false : '';
      },
      closeForecast(){
        this.forecast = false;
      },
      closeAll(){
        this.$eventHub.$emit('closeForecast');
        this.$eventHub.$emit('closeDetails');
        this.forecast = false;
        this.showDetails = false;
      },
      getWeatherPayload(){
        if(this.preview) {
          axios.get(window.location.origin+'/ajax/location-demo-weather-data?lang_code='+this.lang+'&wftkn='+this.wftkn+'&metric='+this.hasMetric).then(res=>{
            this.weatherPayload = res.data.data;
          })
        } else {
          axios.get(this.api+this.lang+'&wftkn='+this.wftkn+'&metric='+this.hasMetric).then(res=>{
            this.weatherPayload = res.data.data;
          })
        }
      }
    },
    
  directives: {
    'click-outside': {
      bind: function(el, binding, vNode) {
        // Provided expression must evaluate to a function.
        if (typeof binding.value !== 'function') {
        	const compName = vNode.context.name
          let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
          if (compName) { warn += `Found in component '${compName}'` }
          
          console.warn(warn)
        }
        // Define Handler and cache it on the element
        const bubble = binding.modifiers.bubble
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
          	binding.value(e)
          }
        }
        el.__vueClickOutside__ = handler

        // add Event Listeners
        document.addEventListener('click', handler)
			},
      
      unbind: function(el, binding) {
        // Remove Event Listeners
        document.removeEventListener('click', el.__vueClickOutside__)
        el.__vueClickOutside__ = null

      }
    }
  }
  }
</script>