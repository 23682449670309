<template>
  <div class="day-weather" @click="close()">
    <div class="weekdays"  v-if="!isToday">
      <div class="day">
        <h3>{{moment(item.Date).format('dddd')}}</h3>
        <p>{{moment(item.Date).format('MMMM, D, YYYY')}}</p> 
      </div>
      <div class="icon">
        <div v-html="icons[item.Day.Icon]"></div>
      </div>
      <h2>{{getTemperature(item.Temperature)}}°</h2>
      <p>{{item.Day.IconPhrase}}</p>
      <h4></h4>
    </div>
    
    
    
    <div class="current-day" v-if="isToday">
      <div class="day">
        <h3>{{moment(item.Date).format('dddd')}}</h3>
        <p>Now</p> 
      </div>
      <h2>{{ weatherPayload.HourlyForecast[0].Temperature.Value }}°</h2>
      <p>{{ weatherPayload.HourlyForecast[0].Icon }}</p>
      <div class="icon">
        <div v-html="icons[weatherPayload.HourlyForecast[0].WeatherIcon]"></div>
      </div>
      
      <div class="hourly">
        <div class="hour" v-for="(hour, index) in fiveHours">
          <div>
            <h4>{{ moment(hour.DateTime).format('h') }}<span>{{moment(hour.DateTime).format('A')}}</span></h4>
            <!-- <p>{{ hour.PrecipitationProbability }}%</p> -->
          </div>
          <div class="icon">
            <div v-html="icons[hour.WeatherIcon]"></div>
          </div>
          <div class="unit">
            <h2>{{ hour.Temperature.Value.toFixed(0) }}°</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .day-weather{
    background: white;
    color: #202020;
    box-shadow: 1px 1px 1px 1px #2020201c;
    border-radius: 0 0 5px 5px;
    position: absolute;
    top:0;
    width: 100%;
  }
  
  .day-weather .weekdays, .day-weather .current-day{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .day-weather h3{
    margin: 0;
    padding: 10px 0 0 0;
    font-weight: 600;
  }
  
  .day-weather h2{
    margin: 0;
    font-weight: 400;
    font-size: 32px;
  }
  
  .day-weather h4{
    font-weight: 600;
    font-size: 14px;
    margin: 0;
    padding: 10px 0 20px 0;
  }
  
  .day-weather p{
    font-size: 12px;
    margin: 0;
    text-align: center;
  }
  
  .day-weather .icon{
    width: 128px;
  }
  
  .day-weather .hourly{
    width: 100%;
    margin-top: -10px;
    display: flex;
  }
  
  .day-weather .hourly .hour{
    display: flex;
    width: 100%;
    align-items: center;
    padding: 5px 0;
    flex-direction: column;
    justify-content: center;
  }
  
  .day-weather .hourly .hour .icon{
    width: 24px;
    margin-top: 5px;
  }
  
  .day-weather .hourly .hour h4{
    font-size: 14px;
    font-weight: 400;
    padding: 0;
  }
  .day-weather .hourly .hour h4 span{
    font-size: 10px;
  }
  .day-weather .hourly .hour p{
    opacity: 0.7;
    font-size: 10px;
  }
  .day-weather .hourly .hour .unit{
    
  }
  .day-weather .hourly .hour h2{
    font-size: 18px;
    font-weight: 400;
  }
  
</style>

<script>
  export default{
    props: ['weatherPayload', 'icons', 'item'],
    data(){
      return {
        
      }
    },
    computed: {
      fiveHours(){
        return this.weatherPayload.HourlyForecast.slice(0, 5);
      },
      isToday(){
        return (this.itemDay===this.today);
      },
      itemDay(){
        return window.moment(this.item.Date).format('YYYYMMDD');
      },
      today(){
        return window.moment(new Date()).format('YYYYMMDD');
      }
    },
    
    methods: {
      getTemperature(temp){
        return ((temp.Minimum.Value + temp.Maximum.Value)/2).toFixed(0);
      },
      close(){
        this.$eventHub.$emit('closeDetails');
      }
    }
  }
</script>