<template>
    <a @click.prevent="showOfficeRnDFields()" href="#" class="social-btn officernd-btn" :style="loginButtonStyles">
      <i class="fa fa-lock" v-show="baseData.branding.iconVisible"></i>
      <span v-text="$t('officernd')" />
    </a>
</template>

<script>
    export default {
        name: 'OfficeRnDLogin',
        props: ['api', 'action', 'wftkn'],
        methods: {
            showOfficeRnDFields()
            {
                this.$eventHub.$emit('showOfficeRnD', true)
            }
        },
      computed: {
        baseData(){
          return this.$store.state.baseData
        },
        loginButtonStyles() {
          return {
            'background': this.loginButtonColor || "#636363",
            'color': this.loginButtonTextColor || '#ffffff',
            'justify-content': this.baseData.loginOptions.styles.layout === "app" ? 'center' : this.loginButtontextAlignment || 'flex-start'
          }
        },
        loginButtontextAlignment(){
          return this.baseData.branding.loginButtonTextAlign.value
        },
        loginButtonColor(){
          return this.baseData.loginOptions.styles.buttons.oauthButtonColor
        },
        loginButtonTextColor(){
          return this.baseData.loginOptions.styles.buttons.loginButtonsTextColor
        },
      },
    }
</script>

<style>
    .officernd-btn{
        background: #636363;
    }
    .officernd-btn:hover{
        background: #636363;
    }

</style>
