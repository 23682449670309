<template>
  <layout>
    <transition name="slide-fade">
      <consent
        :wftkn="wftkn"
        :preview="baseData.preview || 0"/>
    </transition>
  </layout>
</template>

<script>
import Layout from './layout'
import Consent from './consent.vue';

export default {
  components: {
    Layout,
    Consent
  },
  data() {
    return {}
  },
  computed: {
    baseData() {
      return this.$store.state.baseData
    },
    wftkn(){
      return this.$store.state.wftkn
    }
  },
}
</script>

<style scoped>
</style>
