import _ from 'lodash';
import langLocalNames from './lang/lang_list';

function replacePredefinedVariables(text) {
  if (typeof text !== 'string') {
    return text;
  }

  return text
    .replace('{%YEAR%}', new Date().getFullYear());
}

function setCampaignDefaults(campaignInfo) {
  const defaultLayout = 'default'

  const defaultBranding = {
    "logo": campaignInfo.logoUrl || null,
    "header": campaignInfo.headerUrl || [],
    "fontFamily": {
      "label": "Avenir Next",
      "value": "'Avenir Next', sans-serif"
    },
    "backgroundRepeat": {
      "label": "Repeat",
      "value": "repeat"
    },
    "thankyouJustifyContent": {
      "label": "Space Between",
      "value": "space-between"
    },
    "background": campaignInfo.backgroundStyle || "",
    "layer": {
      "enabled": !!campaignInfo.layerStyle,
      "layerColor": campaignInfo.layerStyle || "",
    },
    "loginButtonTextAlign": {
      "label": (campaignInfo.loginOptions && campaignInfo.loginOptions.styles && campaignInfo.loginOptions.styles.primaryButtonTextAlign && campaignInfo.loginOptions.styles.primaryButtonTextAlign.label) || "Left",
      "value": (campaignInfo.loginOptions && campaignInfo.loginOptions.styles && campaignInfo.loginOptions.styles.primaryButtonTextAlign && campaignInfo.loginOptions.styles.primaryButtonTextAlign.value) || "left",
    },
    "iconVisible": (campaignInfo.loginOptions && campaignInfo.loginOptions.styles && campaignInfo.loginOptions.styles.primaryButtonIconVisible),
    "textColor": (campaignInfo.termsConditionModal && campaignInfo.termsConditionModal.campaignTextColor) || "#202020",
    "footerTextColor": (campaignInfo.termsConditionModal && campaignInfo.termsConditionModal.campaignTextColor) || "#202020",
    "titleText": campaignInfo.headerText || "",
    "titleTextSize": 24,
    "subTitleText": campaignInfo.subTitleText || "",
    "subTitleTextSize": 16,
    "subtitleTextColor": (campaignInfo.termsConditionModal && campaignInfo.termsConditionModal.campaignTextColor) || "#202020",
    "footerText": campaignInfo.footerText || "",
    "footerTextSize": 16,
    "supportTextColor": (campaignInfo.termsConditionModal && campaignInfo.termsConditionModal.campaignTextColor) || "#202020",
    "footerImageSize": 100,
    "headerImageSize": 100,
    "logoImageSize": 100,
    "backgroundImageSize": 345,
    "supportTextSize": 16,
    "headerHtml": campaignInfo.headerHtml || "",
    "footerHtml": campaignInfo.footerHtml || "",
    "campaignFooter": {
      "support": campaignInfo.campaignFooterSupport || null,
      "image": campaignInfo.campaignFooterImage || null,
    },
    "layout" : campaignInfo.layout || defaultLayout,
  };


  if (typeof campaignInfo.branding === 'undefined') {
    campaignInfo.branding = {}
  }

  if (typeof campaignInfo.multilingualOption === 'undefined') {
    campaignInfo.multilingualOption = {}
  }
  for (let brandingSetting in defaultBranding) {
    if (!defaultBranding.hasOwnProperty(brandingSetting)) {
      continue;
    }
    if (typeof campaignInfo.branding[brandingSetting] === 'undefined') {
      campaignInfo.branding[brandingSetting] = defaultBranding[brandingSetting]
    }
  }
  campaignInfo.branding.layer.layerColor = fixInvalidColor(campaignInfo.branding.layer.layerColor)
  campaignInfo.branding.background = campaignInfo.branding.background || ""
  campaignInfo.logoUrl = campaignInfo.branding.logo || ""

  if (!campaignInfo.loginOptions) {
    campaignInfo.loginOptions = {}
  }
  campaignInfo.loginOptions.layout = campaignInfo.loginOptions.layout || campaignInfo.branding.layout || campaignInfo.layout || defaultLayout
  if (!campaignInfo.loginOptions.styles) {
    campaignInfo.loginOptions.styles = {}
  }
  campaignInfo.layout = campaignInfo.layout || campaignInfo.loginOptions.layout
  if (typeof campaignInfo.loginOptions.styles.buttons === 'undefined') {
    campaignInfo.loginOptions.styles.buttons = {
      "radius": 25,
      "emailButtonColor": "#6D4E15",
      "facebookButtonColor": "#3b5998",
      "twitterButtonColor": "#1da1f2",
      "instagramButtonColor": "#3f729b",
      "linkedinButtonColor": "#0976b4",
      "freeWifiButtonColor": "#7f017f",
      "messengerButtonColor": "#0084ff",
      "passcodeButtonColor": "#636363",
      "oauthButtonColor": "#636363",
      "officerndButtonColor": "#636363",
      "smsButtonColor": "#629450",
      "vkontakteButtonColor": "#45668e",
      "creditcardButtonColor": "#636363",
      "loginButtonsTextColor": "#FFFFFF",
    }
  }
  if (typeof campaignInfo.loginOptions.styles.buttonTexts === 'undefined') {
    campaignInfo.loginOptions.styles.buttonTexts = {
      "emailButtonText": null,
      "facebookButtonText": null,
      "twitterButtonText": null,
      "instagramButtonText": null,
      "linkedinButtonText": null,
      "freeWifiButtonText": null,
      "messengerButtonText": null,
      "passcodeButtonText": null,
      "smsButtonText": null,
      "vkontakteButtonText": null,
      "creditcardButtonText": null,
      "officerndButtonText": null,
      "oauthButtonText": null,
      "loginButtonsTextText": null
    }
  }
  campaignInfo.loginOptions.styles.primaryButtonColor = campaignInfo.loginOptions.styles.primaryButtonColor || "#55acee"
  campaignInfo.loginOptions.styles.primaryButtonTextColor = campaignInfo.loginOptions.styles.primaryButtonTextColor || "#FFFFFF"
  campaignInfo.loginOptions.styles.primaryButtonTextAlign = campaignInfo.loginOptions.styles.primaryButtonTextAlign || campaignInfo.loginOptions.primryButtonTextAlign ||  {
    "label": "Left",
    "value": "left"
  }
  campaignInfo.loginOptions.styles.primaryButtonIconVisible = campaignInfo.loginOptions.styles.primaryButtonIconVisible

  if (campaignInfo.loginOptions.order) {
    let socialMediaOrder = campaignInfo.loginOptions.order.join('&')
    socialMediaOrder = socialMediaOrder.replace('Facebook Messenger', 'facebook_messenger')
    socialMediaOrder = socialMediaOrder.replace('Free Wi-Fi', 'freewifi')
    socialMediaOrder = socialMediaOrder.toLowerCase();
    campaignInfo.socialMediaOrder = socialMediaOrder
  }

  if(campaignInfo.loginOptions.customFields && !campaignInfo.preview) {
    if(!campaignInfo.loginOptions.customFields) {
      campaignInfo.customFields = []
    } else {
      campaignInfo.customFields = campaignInfo.loginOptions.customFields
    }
  }
  campaignInfo.multilingualOption.optimizedLanguageBar = !campaignInfo?.advancedOptions?.language.hasOwnProperty('optimizedLanguageBar')
    ? false
    : campaignInfo.advancedOptions.language.optimizedLanguageBar;
  
  campaignInfo.multilingualOption.optimizedLanguageList = campaignInfo?.multilingualOption?.optimizedLanguageList?.length > 0
    ? campaignInfo.multilingualOption.optimizedLanguageList 
    : campaignInfo?.advancedOptions?.language?.optimizedLanguageList || ["en", "fr", "es", "zh-CN", "ja"]

  let optimizedLanguageList = campaignInfo.multilingualOption.optimizedLanguageList
  if(optimizedLanguageList.length > 1 && campaignInfo.multilingualOption.optimizedLanguageBar && campaignInfo.multilingualOption.showLangOption !== 'false' || campaignInfo.multilingualOption.showLangOption !== false){
    const allLanguages= Object.keys(langLocalNames).map(code => ({
      lang_code: code,
      lang: langLocalNames[code],
    }))
    const result = optimizedLanguageList.map(code => {
      return allLanguages.find(language => language.lang_code === code);
    }).filter(Boolean)
    campaignInfo.multilingualOption.optimizedLanguageList = result
  }
  campaignInfo.backButton = true;
  return campaignInfo
}

// Some bugs introduced colors like rgba('#abcdef00'), which are not valid
function fixInvalidColor(color) {
  if (typeof color !== 'string') {
    return '';
  }
  const startHex = color.indexOf('#')
  if (startHex === -1) {
    return color;
  }
  const endHex = color.indexOf(')')
  if (endHex === -1) {
    return color;
  }
  return color.substr(startHex, endHex - startHex)
}

export {
  fixInvalidColor,
  setCampaignDefaults,
  replacePredefinedVariables,
}
