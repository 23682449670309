<template>
    <a :href="href" class="social-btn ig-btn" :style="loginButtonStyles">
      <i class="fa fa-instagram"  v-show="baseData.branding.iconVisible" />
      <span v-text="$t('instagram')" />
    </a>
</template>

<script>
    export default {
        name: 'InstagramButton',
        props: ['api', 'action', 'wftkn'],
        data() {
            return {
                href: ""
            }
        },
        computed: {
            baseData(){
                return this.$store.state.baseData
            },
            loginButtonStyles() {
                return {
                    'background': this.loginButtonColor || "#3f729b",
                    'color': this.loginButtonTextColor || '#ffffff',
                    'justify-content': this.baseData.loginOptions.styles.layout === "app" ? 'center' : this.loginButtontextAlignment || 'flex-start'
                }
            },
            loginButtontextAlignment(){
                return this.baseData.branding.loginButtonTextAlign.value
            },
            loginButtonColor(){
                return this.baseData.loginOptions.styles.buttons.instagramButtonColor
            },
            loginButtonTextColor(){
                return this.baseData.loginOptions.styles.buttons.loginButtonsTextColor
            },
        },
        mounted() {
            if(this.api.includes('preview')) {
                this.href = "#";
            } else {
                this.href = `${this.api}&action=${this.action}&wftkn=${this.wftkn}`
            }
        }
    }
</script>

<style>
    .ig-btn{
        background: #3f729b;
    }
    .ig-btn:hover{
        background: #2d506c;
    }
</style>
