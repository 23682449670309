<template>
    <div>
        <div class="error-msg-area">
            <alert-msg
                    v-if="$v.passcode.$error && !$v.passcode.required"
                    :errorMsg="$t('passcode_required')"></alert-msg>
            <alert-msg v-if="customError"
                       :errorMsg="customErrorMsg"></alert-msg>
        </div>

        <div class="custom-fields" v-if="!connected">
            <div>
                <input v-model.trim="passcode" @input="delayTouch($v.passcode)" v-bind:class="{ 'input-error': $v.passcode.$error }" type="text" :placeholder="$t('placeholder_passcode')">
            </div>
            <div style="position: relative">
                <button class="social-btn connect" @click.prevent="connect()" :style="primaryButtonStyles">
                    <i class="fa fa-wifi" aria-hidden="true" v-show="baseData.loginOptions.styles.primaryButtonIconVisible || false"></i>
                    {{ $t('connect_button') }}
                </button>
                <div v-show="loading" class="sk-three-bounce" style="position: absolute; top: 23px; width: 100%;">
                    <div class="sk-child sk-bounce1"></div>
                    <div class="sk-child sk-bounce2"></div>
                    <div class="sk-child sk-bounce3"></div>
                </div>
            </div>
            <a href="#" v-if="baseData.backButton" @click.prevent="goBack()">← {{ $t('back_button') }}</a>
        </div>

        <div class="wifi-loader-area" style="text-align: center">
            <transition name="slide-fade">
                <div class="loader" v-if="loading && connected">
                    <wifi-loader></wifi-loader>
                    <p>{{ $t('connecting') }}</p>
                </div>
            </transition>
        </div>

        <div style="text-align: center">
            <transition name="slide-fade">
                <div class="loader" v-if="connected">
                    <p>{{ $t('operation_success') }}</p>
                </div>
            </transition>
        </div>

    </div>
</template>

<style>
    .wifi-loader-area .wifi-symbol .wifi-circle{
      border-color: #55acee;
    }
    .sms-sent-holder{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
    }
    .sms-sent{
        background: #deffd4;
        padding: 10px;
        max-width: 270px;
        border-radius: 5px;
        margin: 0 auto;
        color: #2fbb00;
        text-align: center;
        position: absolute;
        z-index: 1;
        top: 17px;
    }
    .code-buttons{
        display: flex;
        max-width: 317px;
    }
    .code-buttons button:first-child{
        margin-right: 5px;
    }
    .code-buttons button:last-child{
        margin-left: 5px;
        margin-bottom: 10px;
    }
    .connect.sms{
        background: #35c300;
    }
    .connect.sms:hover{
        background: #217500;
    }
    .phone-code{
        display: flex;
        max-width: 320px;
    }
    .phone-code .country-code{
        width: 30px;
        margin-right: 10px;
    }
    .social-btn {
        display: flex !important;
    }
    .social-btn:hover {
        cursor: pointer;
    }
</style>


<script>
    import { required } from 'vuelidate/lib/validators';
    import WifiLoader from '../../components/loader/wifi-loading.vue';
    import AlertMsg from '../../components/errors/alert.vue';
    const touchMap = new WeakMap();
    export default {

        data(){
            return {
                passcode: '',
                loading: false,
                connected: false,
                customError: false,
                customErrorMsg: '',
                validating: false
            }
        },

        components: {
            WifiLoader,
            AlertMsg
        },

        props: ['api', 'wftkn', 'action'],

        validations: {
            passcode: {
                required
            }
        },

        computed: {
            allValidated(){
                return !this.$v.$invalid;
            },
            baseData(){
                return this.$store.state.baseData
            },
            primaryButtonStyles() {
                return {
                    'border-radius': `${this.radius}px` || '32px',
                    'background': this.primaryButtonColor || '#55acee',
                    'color': this.primaryButtonTextColor || 'white',
                    'justify-content': this.primaryButtonTextAlignment || 'center'
                }
            },
            radius(){
                return this.baseData.loginOptions.styles.buttons.radius
            },
            primaryButtonColor(){
                return this.baseData.loginOptions.styles.primaryButtonColor
            },
            primaryButtonTextColor(){
                return this.baseData.loginOptions.styles.primaryButtonTextColor
            },
            primaryButtonTextAlignment() {
                return this.baseData.loginOptions.styles.primaryButtonTextAlign.value
            }
        },

        methods: {
            goBack()
            {
                this.$eventHub.$emit('showPasscode', false)
            },

            delayTouch ($v) {
                $v.$reset();
                if (touchMap.has($v)) {
                    clearTimeout(touchMap.get($v))
                }
                touchMap.set($v, setTimeout($v.$touch, 1000))
            },
            connect() {
                if(this.api.includes('preview')) {
                    return
                } else {
                    if (this.allValidated) this.connectNow();
                    else {
                        this.validating = true;
                        this.allValid();
                    }
                }
            },
            allValid() {
                this.delayTouch(this.$v.passcode);
                setTimeout(()=>{ this.validating = false; }, 1000);
                return (!this.$v.$error && this.$v.$dirty);
            },

            connectNow()
            {
                this.loading = true;
                this.customError = false;
                this.customErrorMsg = '';
                axios.post(this.api+'&action='+this.action+'&wftkn='+this.wftkn+'&passcode='+this.passcode).
                then((res)=>{
                    this.loading = false;
                    if(!res.data.status)
                    {
                        this.customError = true;
                        this.customErrorMsg = this.$t(res.data.message);
                    }
                    else
                    {
                        this.connected = true;
                        setTimeout(()=>{
                            window.location = decodeURIComponent(res.data.url);
                        }, 1000);
                    }

                });
            }
        },
    }
</script>
