<template>
  <div id="app1" v-if="!!baseData" :class="{'layout-default':baseData.layout==='default', 'layout-login-text-left':(baseData.branding && baseData.branding.loginButtonTextAlign && baseData.branding.loginButtonTextAlign.value==='left'), 'layout-primary-text-left':(baseData.loginOptions && baseData.loginOptions.primaryButtonTextAlign && baseData.loginOptions.primaryButtonTextAlign.value==='left')}" :style="backgroundClass">
    <div class="error-msg-area">
      <alert-msg
        v-if="showPlumeLoginErrorMessage"
        :errorMsg="plumeLoginErrorMessage">
      </alert-msg>
    </div>
    <div
      v-if="backgroundBlur"
      :style="backgroundImage"
    ></div>
    <img v-if="backgroundBlur" :src="imageUrl"  :style="foregroundImage">
    <div style="display: none;" v-if="baseData && baseData.preview" id="app1_lastPostMessage">{{lastPostMessage}}</div>
    <div style="display: none;" v-if="baseData && baseData.preview" id="app1_currentState">{{JSON.stringify(baseData)}}</div>
    <div v-if="baseData.socialMediaOrder && !connecting"
      class="background-image" 
      ref="background"
    > 
        <language-select
          v-if="baseData.multilingualOption.showLangOption==='true'"
          :baseData="baseData">
        </language-select>

        <div class="logo-area"
          v-if="baseData.layout==='default'">
          <default-header :baseData="baseData" />
        </div>

        <div class="logo-area"
          v-if="baseData.layout==='fb-left'"
          style="padding: 0;">
          <fb-left :baseData="baseData" />
        </div>

        <div class="logo-area fb-center"
          v-if="baseData.layout==='fb-center'"
          style="padding: 0;">
          <fb-center :baseData="baseData" />
        </div>

        <weather-widget
          v-if="baseData.widgets && baseData.widgets.weather.enabled"
          class="weather-info"
          :wftkn="wftkn"
          :lang="$i18n.locale"
          :preview="baseData.preview || 0"
          :weather="baseData.widgets.weather" />

        <div class="container" v-if="preError && !paidSessionOver">
          <div class="pre-error">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <h3>{{ $t(preErrorPayload.title) }}</h3>
            <p>{{ $t(preErrorPayload.reject_reason) }}</p>
          </div>
        </div>


        <div v-if="!(preError && !paidSessionOver)"
          class="social-btn-container">

          <!--<ads class="ads-area"
            v-if="(baseData.isAdvertising==='true')"
            :wftkn="wftkn"
            :campaignId="baseData.campaignId"
            :position="'zone_position_pre_top'" />
          <ads-placeholder v-if="hasPreviewAds && ads.preLogin.top" />-->
            <div class="welcome-back-area">
              <welcome-back
                v-if="welcomeBack"
                :payload="welcomeBackPayload"
                :baseData="baseData"
                :api="api"
                action="return-login"
                :wftkn="wftkn" />

              <a v-if='welcomeBack && !isPaidCampaign'
                class="not-user"
                :href="'/index.php?controller=social-login'+'&action=return-new-login'+'&wftkn='+wftkn">
                {{ $t('welcome_back_notyou') }}
              </a>
            </div>

            <p
              :key="$i18n.locale"
              class="sub-title-text"
              v-if="$t('sub_title_text')"
              :style="subtitleTextStyles"
              v-text="replacePredefinedVariables($t('sub_title_text'))"
            />

            <slot />

            <!--<ads v-if="baseData.isAdvertising==='true'"
              class="ads-area"
              :wftkn="wftkn"
              :position="'zone_position_pre_bottom'"
              :campaignId="baseData.campaignId" />
            <ads-placeholder v-if="hasPreviewAds && ads.preLogin.top" />-->
        </div>

        <div class="logo-area campaign-footer">
          <div v-if="baseData.campaignFooterImage">
            <img :src="baseData.campaignFooterImage"  :style="{'width': `${baseData.branding.footerImageSize}%`}" alt="">
          </div>

          <div v-if="$t('footer_text')">
            <p
              :key="$i18n.locale"
              :style="footerTextStyles"
              v-text="replacePredefinedVariables($t('footer_text'))"
            />
          </div>

          <div v-if="$t('support')">
            <p
              :key="$i18n.locale"
              :style="supportTextStyles"
              v-text="replacePredefinedVariables($t('support'))"
            />
          </div>
        </div>
      </div>
      
      <div :style="layerStyles"></div>
      <div class="wifi-loading" v-if="connecting">
        <wifi-loader />
      </div>
    </div>
</template>

<script>
import Ads from '../components/ads/ads.vue';
import AdsPlaceholder from '../views/ads-placeholder'
import LanguageSelect from '../components/multilingual/language.vue';
import WifiLoader from '../components/loader/wifi-loading.vue';

import FbLeft from '../components/layouts/fb-left.vue';
import FbCenter from '../components/layouts/fb-center.vue';
import DefaultHeader from '../components/layouts/default.vue';
import WeatherWidget from '../components/weather/weather.vue';
import WelcomeBack from '../components/welcome-back/welcome-back.vue';
import AlertMsg from '../components/errors/alert.vue';
import {replacePredefinedVariables} from "../helpers";

export default {
  name: 'app',
  components: {
    Ads,
    AdsPlaceholder,
    LanguageSelect,
    WifiLoader,
    FbLeft,
    FbCenter,
    DefaultHeader,
    WeatherWidget,
    WelcomeBack,
    AlertMsg
  },
  data(){
    return {
      styles: {
        'background-image': 'url()',
      },
      showPlumeLoginErrorMessage: false,
      plumeLoginErrorMessage: ''
    }
  },
  methods: {
    replacePredefinedVariables,
    resetErrorPlumeError() {
      //reset thr error message
      this.$store.commit('setPlumeLoginError', '')
      this.plumeLoginErrorMessage = ''
      this.showPlumeLoginErrorMessage = false
    }
  },
  computed: {
    baseData(){
      return this.$store.state.baseData
    },
    wftkn(){
      return this.$store.state.wftkn
    },
    textColor() {
      return this.$store.state.textColor
    },
    preErrorPayload() {
      return this.$store.state.preErrorPayload
    },
    preError() {
      return this.$store.state.preError
    },
    isRejeted() {
      return this.$store.state.isRejeted
    },
    connecting() {
      return this.$store.state.connecting
    },
    lastPostMessage() {
      return this.$store.state.lastPostMessage
    },
    isPaidCampaign(){
      return !!(this.baseData.paymentPlans);
    },

    welcomeBackPayload() {
      return this.$store.state.welcomeBackPayload
    },
    welcomeBack() {
      return this.$store.state.welcomeBack
    },

    paidSessionOver(){
      return (this.isRejeted && this.isPaidCampaign);
    },
    hasPreviewAds() {
      return this.baseData.isAdvertising && this.baseData.preview;
    },
    ads(){
      return this.baseData.advertisement;
    },
    subtitleTextStyles() {
      return {
        'font-size': `${this.baseData.branding.subTitleTextSize}px !important`,
        'color': this.baseData.branding.subtitleTextColor
      }
    },
    footerTextStyles() {
      return {
        'font-size': `${this.baseData.branding.footerTextSize}px !important`,
        'color': this.baseData.branding.footerTextColor
      }
    },
    supportTextStyles() {
      return {
        'font-size': `${this.baseData.branding.supportTextSize}px !important`,
        'color': this.baseData.branding.supportTextColor
      }
    },
    backgroundClass(){
      if (!this.baseData.branding) return {}
      if (this.baseData.branding.background.substr(0, 3) === 'url') { 
        const styles = {
          'color': this.textColor,
          'font-family': this.baseData.branding.fontFamily.value,
          'background': `${this.baseData.branding.background} ${this.baseData.branding.backgroundRepeat.value}`,
        }   

        if(this.baseData.branding.backgroundRepeat.value === 'square-fit'){
          Object.assign(styles, {
            'background': null
          })
        }

        if(this.baseData.branding.backgroundRepeat.value === 'stretch') {
          Object.assign(styles, {
            'background' : this.baseData.branding.background,
            'background-position': this.baseData.branding.backgroundRepeatAlignment.value,
            'background-size': 'cover',
            'background-attachment': 'fixed'
          })
        }
        return styles
      }
      else {
        return {
          'background': this.baseData.branding.background,
          'color': this.textColor,
          'font-family': this.baseData.branding.fontFamily.value
        }
      }
    },
    backgroundBlur(){
      if (!this.baseData.branding) return false
      return (this.baseData.branding.background.substr(0, 3) === 'url' && this.baseData.branding.backgroundRepeat.value  === 'square-fit') ? true : false
    },
    backgroundImage(){
      if (!this.baseData.branding) return {}
      return {
        'background': this.baseData.branding.background,
        'position': 'absolute',
        'height': '100vh',
        'width': '100%',
        'background-size': 'cover',
        'filter': 'blur(5px)',
        'background-position': 'center',
        'z-index': '1',
      }
    },
    foregroundImage(){
      if (!this.baseData.branding) return {}
      return {
        'height': '100%',
        'max-width': '600px',
        'position': 'absolute',
        'margin': 'auto',
        'left': '0',
        'right': '0',
        'width': '100%',
        'background-size': 'contain',
        'background-repeat': 'no-repeat',
        'z-index': '2'
      }
    },
    imageUrl(){
      if(this.baseData.branding && this.baseData.branding.background.substr(0, 3) !== 'url') return {}
      let imageUrl = this.baseData.branding.background
      return imageUrl.replace(/^url\((.*)\)$/, '$1');
    },
    layerStyles(){
      if(this.baseData && this.baseData.branding && this.baseData.branding.layer && !this.baseData.branding.layer.enabled) return {}
      if(this.baseData && this.baseData.branding && this.baseData.branding.layer.enabled){
        return {
          'background': this.baseData.branding.layer.layerColor,
          'height': '100vh',
          'width': '100%',
          'position': 'absolute',
          'z-index': '3'
        }
      }
    },
    plumeLoginError() {
      return this.$store.state.plumeLoginErrorMessage
    },
  },
  watch: {
    plumeLoginError: {
      deep: true,
      handler(val){
        if(this.plumeLoginError){
          this.showPlumeLoginErrorMessage = true
          this.plumeLoginErrorMessage = this.plumeLoginError
          //erasing the error
          setTimeout(() => {
            this.$router.push('/thankyou');
            this.resetErrorPlumeError()
          }, 2000)
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@500&family=Archivo&family=B612&family=BioRhyme&family=Cairo&family=Cardo&family=Crimson+Text&family=Fjalla+One&family=Frank+Ruhl+Libre&family=IBM+Plex+Sans&family=Karla&family=Lora&family=Montserrat&family=Muli:wght@577&family=Playfair+Display&family=Roboto&family=Rubik&family=Source+Sans+Pro&family=Spectral&family=Titillium+Web&family=Varela&family=Vollkorn&family=Work+Sans&display=swap');
.loginBorder{
  .social-btn{
    border-radius: inherit;
  }
}
.sub-title-text{
  margin: 0;
}
.weather-info{
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 10;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-button {
  background: #ccc
}
::-webkit-scrollbar-track {
  background-color: #9993;
}
::-webkit-scrollbar-thumb {
  background-color: #9999;
}
.fb-cover-area .fb-cover-bg{
  height: 175px;
}
.logo-area.campaign-footer img{
  max-width: 170px;
}
.wifi-loading{
  display: flex;
  justify-content: center;
  align-items: center;
}
.wifi-loading .wifi-symbol .wifi-circle{
  border-color: #55acee;
}
.container{
  padding: 20px 0;
}
.pre-error{
  background: red;
  border-radius: 5px;
  margin: 30px 10px 0 10px;
  padding: 20px;
  text-align: center;
  color: white;
  width: 100%;
  letter-spacing: 0;
  box-shadow: 0px 5px 5px -1px #000000;
}
.pre-error i{
  font-size: 40px;
  margin: 0 0 10px 0;
}
.pre-error h3{
  margin: 0;
  font-size: 20px;
}
.pre-error p{
  margin: 0;
  padding: 5px 0;
  font-size: 14px;
}
.not-user{
  margin: 20px 0;
  color: #ccc;
  border-color: #ccc;
  z-index:1;
}
.fb-cover-area img{
  width: 100%;
  /*height: 100%;*/
}
.fb-cover-area .fb-cover{
  position: relative;
  margin-bottom: -30px;
}
.fb-cover-area .fb-cover-bg{
  max-height: 175px;
  overflow: hidden;
  justify-content: center;
  display: flex;
  align-items: center;
}
.placeholder-cover{
  width: 100%;
  height: 175px;
}
.fb-cover-bg img{
  height: 100%;
}

.fb-avatar .fb-container{
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-top: -65px;
  margin-bottom: 40px;
  min-height: 100px;
}
.fb-avatar .fb-container.center{
  flex-direction: column;
}
.fb-cover-area .fb-cover .fb-avatar.center:after{
  height: 95px;
}
.fb-cover-area .fb-cover .fb-avatar.center img{
  margin-bottom: 20px;
}
.fb-cover-area .fb-cover .fb-avatar .fb-container.center h2{
  position: relative;
  top: 15px;
}
.fb-cover-area .fb-cover .fb-avatar{
  position: relative;
  width: 100%;
}
.fb-cover-area .fb-cover .fb-avatar:after{
  content: '';
  display: block;
  height: 55px;
  width: 100%;
  position: absolute;
  top: 65px;
  z-index: -1;
}
.fb-cover-area .fb-cover .fb-avatar h2{
  /*margin-top: 75px; *!*/
  margin-left: 15px;
  letter-spacing: 0;
  bottom: -5px;
  position: absolute;
  color: inherit;
  font-size: 20px;
  top: 70px;
}
.fb-cover-area .fb-cover .fb-avatar p{
  margin-left: 15px;
  font-size: 12px;
  margin-top: -5px;
}
.fb-cover-area .fb-cover .fb-avatar p .fa{
  margin-right:3px;
}
.fb-cover-area .fb-cover .fb-avatar div{
  text-align: left;
}
.fb-cover-area .fb-cover .fb-avatar img{
  width: 100px;
  border-radius: 5px;
  border: 3px solid #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.29);
}
#app1, body, html{
  font-family: 'Avenir Next', 'Roboto', sans-serif;
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  background-size: cover;
  overflow: unset;
  height: inherit;
}
.social-btn-holder{
  display: flex;
  flex-direction: column;
  align-items:center;
  position: relative;
  /*padding-bottom: 50px;*/
  /*height: 100%;*/
  /*justify-content: center;*/
  padding: 20px 0px;
  margin: 0 auto;
  width: 300px;
  text-align: left;
}
.background-image{
  height: 100%;
  width: 100%;
  background-size: cover!important;
  /*color: #000;*/
  /*position: relative;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /*position: relative;*/
  z-index: 10;

  overflow-y: overlay;
  overflow-x: hidden;
  /*position: fixed;*/
}
.container{
  z-index: 3;
  /*height: 100%;*/
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.social-btn-container{
  padding: 20px 0;
  /* z-index: 3; */
  text-align: center;
  position: relative;
}
.logo-area{
  position: relative;
  /* z-index: 3; */
  text-align: center;
  padding: 20px 0 0 0;
}
.logo-area h2{
  margin: 5px;
  padding: 0;
}
.logo-area h3{
  margin: 5px;
  font-size: 15px;

}
.logo-area h3 p, .logo-area h2 p{
  margin: 0;
}

a{
  text-decoration: none;
  border-bottom: 1px dashed #55acee;
  color: #55acee;
}
.v-select li>a{
  border:none;
}

.welcome-back-area{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(50px);
  opacity: 0;
}


.slide-fade-right-enter-active {
  transition: all .3s ease;
}
.slide-fade-right-enter
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-50px);
  opacity: 0;
}
.custom-fields{
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
}
.custom-fields input, .custom-fields select{
  padding: 15px 20px;
  font-size: 16px;
  border: 1px solid transparent;
  border-radius: 32px;
  max-width: 275px;
  width: 320px;
  margin: 10px 0 0 0;
  outline: none;
  box-shadow: 2px 0 20px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
}
.custom-fields select{
  width: 290px;
  max-width: 290px;
  -moz-appearance: none;
  -webkit-appearance:none;
  background: #fff;
}
button.connect, a.connect{
  opacity: 1;
  background: #55acee;
  margin-left: 0;
  justify-content: center;
  display: block;
  width: 320px;
  margin-top: 10px;
  padding: 14px 22px !important;
}
button.connect:hover, a.connect:hover{
  background: #345c85;
}
button.connect .fa, a.connect .fa,button.connect .far, a.connect .far{
  margin-left: 0;
}

.alert{
  padding: 0;
  position: absolute;
  top: -65px;
  width: 90%;
  left: 20px;
}
.vue-alert p{
  padding: 0 20px;
}
.alert-danger {
  color: #ffffff;
  background-color: rgba(220, 73, 0, 0.91);
  border-color: #c00007;
}
.custom-fields input.input-error,
.custom-fields .input-error input{
  border-color: red;
  background: #f7eaea;
}


.custom-fields input.input-error,
.custom-fields .input-error input {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
      transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
      transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
      transform: translate3d(4px, 0, 0);
  }
}

.error-msg-area{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index:15;
}
.error-msg-area p{
  background: rgb(255, 0, 0);
  padding: 5px 10px;
  text-align: center;
  margin: 0;
  color: white;
}
.error-msg-area p:first-child{
  padding-top: 10px;
}
.error-msg-area p:last-child{
  padding-bottom: 10px;
}

.gender-div{
  padding: 20px 0 10px 0;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: inherit;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #55acee;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.sk-three-bounce {
  margin: 0 auto;
  width: 80px;
  text-align: center; }
.sk-three-bounce .sk-child {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
  animation: sk-three-bounce 1.4s ease-in-out 0s infinite both; }
.sk-three-bounce .sk-bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }
.sk-three-bounce .sk-bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

@-webkit-keyframes sk-three-bounce {
  0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0); }
  40% {
      -webkit-transform: scale(1);
      transform: scale(1); } }

@keyframes sk-three-bounce {
  0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0); }
  40% {
      -webkit-transform: scale(1);
      transform: scale(1); } }


.lang-select .v-select .dropdown-toggle .clear{
  display: none;
}
.lang-select .v-select .dropdown-toggle{
  box-shadow: none;
  border-radius: 3px!important;
  margin: 0;
  max-width: 100%!important;
  padding: 8px 20px !important;
  background: rgba(255, 255, 255, 0.1)!important;
  border-color: transparent!important;
}
.lang-select .v-select.dropdown{
  max-width: 100%;
}
.lang-select .v-select .open-indicator {
  position: absolute;
  bottom: 14px!important;
  right: 25px!important;
}
.lang-select .v-select .selected-tag{
  background: none;
  border: none;
}
.v-select .open-indicator:before{
  border-color: inherit!important;
}
.v-select .selected-tag{
  color: inherit!important;
}

#app1{
  display: flex;
  justify-content: center;
}
.background-image{
  max-width: 600px;
  position: relative;
}
.pre-error.paidsession{
  margin: -50px 0 10px 0;
  background: #e9a924;
}
.pre-error.paidsession p{
  padding: 0;
}
.goback-payment{
  z-index: 100;
  color: inherit;
  border: none;
}
</style>
