<template>
    <div class="ads">
        <div class="placeholder">
            <!---<iframe ref="adframe" scrolling="no" frameBorder="0" style="width: 100%; height: 100%; overflow: hidden; background: rgb(255 255 255 / 0%)"></iframe>-->
          <span :key="`ad_key_${adKey}`" v-html="ad" />
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                adKey: 1,
                ad: '',
                api: '/index.php?controller=apiservices&name=ad-zone'
            }
        },
        props: ['wftkn', 'campaignId', 'position'],

        mounted() {
            /*const adframe = this.$refs.adframe;
            adframe.onload = () => {
                adframe.style.height = (adframe.contentWindow.document.body.scrollHeight) + 'px';
            }*/

            if (this.campaignId) {
              axios.get(`${this.api}&wftkn=${this.wftkn}&campaign_id=${this.campaignId}&position=${this.position}`)
                .then((res) => {
                  this.ad = res.data;
                  this.adKey++;
                });
            }
        }
    }
</script>


<style>
    .ads{
        width: 100%;
        position: relative;
        display: flex;
        margin: 10px 0;
    }
    .ads .placeholder{
        height: auto;
        width: 100%;
        background: rgba(140, 126, 126, 0);
        position: relative;
        margin: 0 30px;
        border-radius: 3px;
    }
    /*.ads .placeholder:after{*/
        /*content: 'Ads by Google';*/
        /*position: absolute;*/
        /*bottom: 0;*/
        /*right: 0;*/
        /*font-size: 12px;*/
        /*padding: 7px 10px;*/
    /*}*/
</style>
