<template>
  <agile v-if="(baseData.headerUrl.length > 0) && show" class="agile-carousel" :arrows="false" :autoplay="autoplay" :autoplaySpeed="5000" :dots="showDots" :infinite="false">
    <div class="slide" v-for="(item, index) in baseData.headerUrl" :key="index">
        <img :src="item" alt="" class="header-image" :style="{'width': `${baseData.branding.headerImageSize}%`}">
    </div>
  </agile>
</template>

<script>
  export default{
    
    props: ['baseData'], 
    
    data(){
        return {show:true}
    },
    methods:{
        rerender(){
            this.show = false
            this.$nextTick(() => {
                this.show = true;
            })
        }
    },
    
    watch: {
        campaign: {
            handler(){
              this.rerender();
            },
            deep: true
        },
    },
    
    computed: {
      showDots () {
        return (this.baseData.headerUrl.length > 1);
      },
      autoplay(){
        return (this.baseData.headerUrl.length > 1);
      }
    } 
  }
</script>

<style>
  .fb-cover-area .fb-cover-bg {
    height: 175px;
  }
  .agile {
    height: 100%;
    width: 100%;
  }
  .agile__list {
    width: 100%;
    height: 100%;
  }
  .agile__dots{
    position: absolute;
    justify-content: center;
    z-index: 100;
    bottom: 10px;
    margin: 0 auto;
    width: 100%;
  }
  .agile__dot button{
    border-radius: 50%;
    height: 15px;
    width: 15px;
    padding: 0;
    font-size: 0;
    margin: 0 2px;
    border:none;
  }
  .agile__dot.agile__dot--current button{
    background: #444444;
  }
  
  .fb-center .agile__dots{
    justify-content: flex-start;
    margin-left: 15px;
  }
  .agile{
    max-height: 300px;
  }
  .agile__slide{
    max-height: 300px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-image {
    max-width: 100%;
  }
</style>