<template>
  <div>
    <!-- Insert Your Facebook Pixel ID below. --> 
    <noscript>
      <img height='1' width='1' style='display:none' 
      :src="'https://www.facebook.com/tr?id='+facebookPixelId+'&amp;ev=Lead&amp;em='+socialEmail+'&amp;noscript=1'"/>
    </noscript>
    <!-- End Facebook Pixel Code -->
  </div>
</template>


<script>
  export default{
    mounted(){
      let vm = this;
      !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
            n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
            document,'script','https://connect.facebook.net/en_US/fbevents.js');
            // Insert Your Facebook Pixel ID below. 
      fbq('init', vm.facebookPixelId);
      fbq('track', 'Lead');
      fbq('trackCustom', 'WiFiLogin', vm.facebookEvent);
    },
    computed: {
      facebookPixelId(){
        return (this.$store.getters.postLogin) ? this.$store.getters.postLogin.payload.campaign_facebook_pixel_id : null;
      },
      facebookPixelInit(){
        return (this.$store.getters.postLogin && this.facebookPixelId) ? this.$store.getters.postLogin.payload.facebook_pixel_init : null;
      },
      facebookEvent(){
        return (this.$store.getters.postLogin && this.facebookPixelId) ? this.$store.getters.postLogin.payload.facebook_event : null;
      },
      socialEmail(){
        return (this.$store.getters.postLogin) ? this.$store.getters.postLogin.payload.social_user_email_address : null;
      }
    }
  }
</script>