<template>
  <div v-click-outside="hide" v-if="lazyLoadLanguageBar" style="height: 45px">
    <div :style="[background, langSelect]">
      <input
        class="lang-input" 
        type="text"
        ref="searchLanguage"
        v-model="searchQuery"
        :placeholder="currentSelectedLang"
        @focus="showLanguage = true"
        @input="(e) => filterLanguage(e.target.value)"
      >
      <i 
        :class="arrow" 
        style="font-size:25px;color:#a3a3a3;margin:6px 2px 6px 0px;font-weight: 700;" 
        @click="openSelector"
      />
    </div>
    <template v-if="showLanguage" >
      <div class="langList" v-if="filteredLanguage.length > 0">
        <div
          v-for="item in filteredLanguage"
          :key="item.lang"
          class="item"
          @click="handleSelect(item)"
          >
          <span>{{ item.lang || item.name }}</span>
        </div>
      </div>
      <div v-else class="langList">
        <span 
          class="no-result"
          v-text="$t('no_options_available')" 
        />
      </div>
    </template>
  </div>
  <div v-else class="lang-select">
    <v-select 
      v-model="language" 
      label="lang" 
      :options="allLanguages"
      :placeholder="$t('language_choice')" 
    />
  </div>
</template>


<script>
    import vClickOutside from 'v-click-outside'
    import langLocalNames from '../../lang/lang_list';
    import vSelect from 'vue-select';
    import "vue-select/dist/vue-select.css";
    import { find } from 'lodash'
    export default {
        components: {
            vSelect
        },

        data(){
            return {
                allLanguages: Object.keys(langLocalNames).map(code => ({
                  lang_code: code,
                  lang: langLocalNames[code],
                })),
                language: {
                  lang_code: 'en',
                  lang: langLocalNames['en']
                },
                filteredLanguage: [],
                showLanguage: false,
                searchQuery: ''
            }
        },
        directives: {
          clickOutside: vClickOutside.directive
        },
        props: ['baseData'],
        watch:{
            language()
            {
              this.$i18n.locale = this.language.lang_code;

              const localStorageLanguage = localStorage.getItem('selectedLanguage');

              let isError = false;
              try {
                JSON.parse(localStorageLanguage);
              } catch (e) {
                isError = true;
                localStorage.removeItem('selectedLanguage');
              }

              let selectedLanguage = isError === false
                ? JSON.parse(localStorageLanguage)
                : {}

              const pathName = window.location.pathname;
              const parts = pathName.split('/');
              const pageName = parts.filter(part => part.includes('.html'))[0];

              if (pageName) {
                selectedLanguage[pageName] = this.language.lang_code;
              }

            localStorage.setItem('selectedLanguage', JSON.stringify(selectedLanguage));
          },
          baseData() {
            this.updateLang()
            this.updateLanguageList()
          },
        },
        computed:{
          background(){
            if(this.showLanguage){
              return {
                'background': '#f1f1f191',
              }
            }
            return {
              'background': '#ffffffb0',
            }
          },
          lazyLoadLanguageBar(){
            return this.baseData.multilingualOption.optimizedLanguageBar
          },
          langSelect(){
            return {
              'max-width': '100%!important',
              'border': 'none!important',
              'padding': '6px 21px',
              'box-shadow': 'none',
              'border-radius': '3px!important',
              'margin': '0',
              'display': 'flex',
              'justify-content': 'flex-end',
            }
          },
          arrow(){
            if(this.showLanguage){
              return "fa fa-angle-up"
            }
            return "fa fa-angle-down"
          },
          currentSelectedLang(){
            return this.language.lang
          },
        },
        mounted(){
          setTimeout(() => {
            let jsObjects = this.allLanguages;
            let locale = this.$i18n.locale;
            if (locale === 'pt') {
              locale = 'pt-PT';
            }

            if (locale === 'jp') {
              locale = 'ja';
            }

            if(window.location.hostname === 'localhost'){
              let currentLang = this.baseData.customLangCode || 'en'
              this.language = {lang_code: currentLang, lang: langLocalNames[currentLang]}
            } else {
              let currentValue = jsObjects.filter(( obj ) => {
                  return obj.lang_code === locale;
              });
              this.language = currentValue[0];
            }
          }, 10);
          this.filteredLanguage = this.baseData.multilingualOption.optimizedLanguageList
        },
        methods:{
            updateLang(){
                if(this.baseData.multilingualOption.showLangOption === "true" || this.builderCampaign.advancedOptions.language.showMultilingual === true){
                    let currentLang = this.baseData.customLangCode || 'en'
                    this.language = {lang_code: currentLang, lang: langLocalNames[currentLang]}
                }
            },
            filterLanguage(searchTerm){
              let searchItem = searchTerm.toLowerCase();
              this.filteredLanguage =  Object.values(this.allLanguages).filter(language => {
                return language.lang.toLowerCase().includes(searchItem) || language.lang_code.toLowerCase().includes(searchItem);
              }).slice(0,5);
            },
            hide(){
              this.$refs.searchLanguage.blur()
              this.showLanguage = false
              this.searchQuery = ""
            },
            openSelector(){
              this.showLanguage = !this.showLanguage
              if(!this.showLanguage){
                this.hide()
              }
            },
            handleSelect(language){
              this.language = {lang_code: language.lang_code, lang: language.lang || language.name}
              if(language && language.name){
                this.language = find(this.allLanguages, function(o) { return o.lang === language.name; });
              }
              this.hide()
              this.updateLanguageList()
            },
            updateLanguageList(){
              this.filteredLanguage = this.baseData.multilingualOption.optimizedLanguageList
            }
        }
    }
</script>


<style>
.lang-input {
  margin: 5px;
  padding: 3px;
  width: -webkit-fill-available;
  height: 20px;
  position: sticky;
  border: none;
  background: #ffffff05;
  font-size: 16px;
  color: #333333;
}
.lang-input:focus {
  outline: none;
  background: #ffffff05;
  border: none;
}
.langList{
  position: absolute;
  z-index: 999;
  width: 100%;
  padding: 5px 12px;
  height: -webkit-fit-content;
  min-height: 80px;
  max-height: fit-content;
  background-color: #f1f1f1;
  margin-inline: 5px;
  display: flex;
  flex-direction: column;
  max-width: -webkit-fill-available;
  border-radius: 5px;
}
.item{
  width: auto;
  height: 20px;
  padding: 5px 10px;
  color: black;
  /* text-decoration: none; */
}
.item:last-child {
  margin-bottom: 5px;
}
.item:hover{
  background: #6393f5;
  cursor: pointer;
  width: initial;
  /* padding: 5px 10px; */
}
.no-result {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.v-select {
  color: #333333;
}
.lang-select .vs__dropdown-toggle {
  border: none !important;
  padding: 10PX;
  box-shadow: none;
  border-radius: 3px!important;
  margin: 0;
  max-width: 100%!important;
  padding: 8px 20px!important;
  background: #ffffffb0!important;
  border-color: transparent!important;
}
.lang-select .vs__dropdown-toggle .vs__actions .vs__clear{
  display: none;
}
</style>
