<template>
    <a @click.prevent="loginOAuth()" class="social-btn li-btn" :style="loginButtonStyles">
      <i class="fa fa-circle" v-show="baseData.branding.iconVisible" />
      <span v-text="this.$t('oauth')" />
    </a>
</template>

<script>
    export default {
        name: 'OAuthButton',
        props: ['api', 'action', 'wftkn'],
        computed: {
          baseData(){
            return this.$store.state.baseData
          },
          loginButtonStyles() {
            return {
              'background': this.loginButtonColor || "#636363",
              'color': this.loginButtonTextColor || '#ffffff',
              'justify-content': this.baseData.loginOptions.styles.layout === "app" ? 'center' : this.loginButtontextAlignment || 'flex-start'
            }
          },
          loginButtontextAlignment(){
            return this.baseData.branding.loginButtonTextAlign.value
          },
          loginButtonColor(){
            return this.baseData.loginOptions.styles.buttons.oauthButtonColor
          },
          loginButtonTextColor(){
            return this.baseData.loginOptions.styles.buttons.loginButtonsTextColor
          },
        },
      methods: {
          loginOAuth() {
            if(this.api.includes('preview')) {
              return
            }
            window.location.href = this.api+'&action='+this.action+'&wftkn='+this.wftkn
          }
      }
    }
</script>

<style>
    .li-btn{
        background: #0976b4;
    }
    .li-btn:hover{
        background: #085786;
    }
</style>
