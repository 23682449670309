<template>
  <div class="fb-cover-area">
      <div class="fb-cover">
          <div>
              <div class="fb-cover-bg">
                  <header-carousel v-if="isCarousel" :baseData="baseData"></header-carousel>
                  <div class="placeholder-cover" v-if="!baseData.headerUrl"></div>
              </div>

              <div class="fb-avatar">
                  <div class="fb-container">
                      <img v-if="!!baseData.logoUrl" :src="baseData.logoUrl" alt="">

                      <div class="fb-title-area">
                          <h2
                            :style="titleTextStyles"
                            v-text="replacePredefinedVariables(baseData.headerText)"
                          />
                      </div>
                  </div>

              </div>
          </div>
      </div>
  </div>
</template>

<style>
  .fb-cover-area .fb-avatar .fb-container{
    display: block;
  }

  .fb-cover-area .fb-avatar .fb-title-area{
    margin-left: 105px;
  }

</style>

<script>
  import HeaderCarousel from './header-image/header.vue';
  import { replacePredefinedVariables } from '../../helpers';

  export default{
    props: ['baseData'],
    components: {
      HeaderCarousel
    },
    methods: {
      replacePredefinedVariables(text) {
        return replacePredefinedVariables(text);
      }
    },
    computed: {
      isCarousel(){
        return Array.isArray(this.baseData.headerUrl);
      },
      titleTextStyles() {
        return {
          'font-size': `${this.baseData.branding.titleTextSize}px !important`,
          'color': `${this.baseData.branding.textColor}`,
        }
      },
    }
  }
</script>
