<template>
 <div class="contentWrapper">
  <thankyou-layout />
  <div class="postLogin-container">
    <div class="wifi-loader-area" style="text-align: center" v-if="showInternetProbe">
      <transition name="slide-fade" v-if="!showConnectionError">
        <div class="loader" v-if="!isOnline">
          <wifi-loader></wifi-loader>
        </div>
      </transition>
      <wifi-icon v-if="showConnectionError"/>
      <p v-if="isDebugging" :key="`debuggerKey_${debuggerKey}`">
        {{ debugging }}
      </p>
      <p v-if="currentAttempt > 0">
        {{ connectionStatusMessage }}
      </p>
    </div>

    <!-- if thankyou has internt -->
    <div v-if="!showInternetProbe && isOnline">
      <ads
        v-if="hasTopAd"
        class="ads-area"
        :wftkn="wftkn"
        :campaignId="campaignId"
        :position="'zone_position_post_top'" />

      <ads-placeholder v-if="hasPreviewAds && ads.postLogin.top" />

      <div v-if="thankYouElements">
        <component
          v-for="(item, index) in firstHalfOrder"
          :key="item"
          :is="item.replace(' ','')"
          :option="thankYouElements"
        />
        <ads v-if="hasMiddleAd"
            class="ads-area"
            :wftkn="wftkn"
            :campaignId="campaignId"
            :position="'zone_position_post_middle'"
        />
        <ads-placeholder v-if="hasPreviewAds && ads.postLogin.middle" />
        <component
          v-for="(item, index) in secondHalfOrder"
          :key="item"
          :is="item.replace(' ','')"
          :option="thankYouElements"
        />
      </div>

      <div v-else>
        <div
          v-if="isOnline"
          class="thankyou-html-area"
          v-html="thankYouData">
        </div>

        <ads v-if="hasMiddleAd"
            class="ads-area"
            :wftkn="wftkn"
            :campaignId="campaignId"
            :position="'zone_position_post_middle'"
        />

        <facebook-like
          v-if="hasFacebookLike && facebookPage" />
      </div>

      <div class="redirect-area" v-if="!isNoRedirect">
        <p
          v-if="!isManual && isOnline"
          class="redirect-time"
        >
          {{ timer/1000 }} s
        </p>
        <p v-if="isManual">
          <a v-if="redirectUrl"
            class="next-btn"
            :href="redirectUrl"> → </a>
        </p>
      </div>

      <ads
        v-if="hasBottomAd"
        class="ads-area"
        :wftkn="wftkn"
        :campaignId="campaignId"
        :position="'zone_position_post_bottom'" />

      <ads-placeholder v-if="hasPreviewAds && ads.postLogin.bottom" />

      <facebook-pixel
        v-if="facebookPixelId" />

      <div v-if="conversionTrackingCode">
        <div v-html="conversionHTML"></div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import Ads from '../components/ads/ads'
import FacebookLike from '../components/facebook-sdk/facebook-like'
import FacebookPixel from '../components/facebook-sdk/facebook-pixel'
import CustomText from '../components/thankyou-elements/custom-text'
import AddImage from '../components/thankyou-elements/custom-image'
import AddHTML from '../components/thankyou-elements/custom-html'
import LikeBox from '../components/thankyou-elements/like-box'
import AdsPlaceholder from './ads-placeholder'
import WifiLoader from "../components/loader/wifi-loading.vue";
import axios from "axios";
import AlertMsg from "../components/errors/alert.vue";
import WifiIcon from "../components/icons/wifi-icon.vue";
import ThankyouLayout from './thankyouLayout.vue'

const $MAX_RETRY_NUMBER = 10;
const $HOSTS = [
  'https://api.ipify.org', // 3rd party from across the internet
  'https://b2gnrdcb4uq7mj322t3lxzmea40wzlye.lambda-url.us-east-1.on.aws', // controlled by MyWiFi Lambda
  'https://httpbin.org/status/200', // 3rd party suggested by plume
];
const $TIMEOUT = 5000;
const $DELAY = 3500;

export default {
  components: {
    WifiIcon,
    AlertMsg,
    WifiLoader,
    Ads,
    FacebookLike,
    FacebookPixel,
    AdsPlaceholder,
    CustomText,
    AddImage,
    AddHTML,
    LikeBox,
    ThankyouLayout,
  },
  data(){
    return {
      timer: null,
      isOnline: false,
      intervalId: null,
      showConnectionError: false,
      currentAttempt: 0,
      debugging: null,
      isDebugging: false,
      currentHost: null,
      debuggerKey: 0,
      showInternetProbe: true,
    }
  },
  async mounted() {
    if (this.isDebugging) {
      setInterval(() => this.debuggerKey++, 200);
    }

    let plume = localStorage.getItem('isPlume')
    const pathName = window.location.pathname;
    const parts = pathName.split('/');
    const pageName = parts.filter(part => part.includes('.html'))[0];
    const localStorageLanguage = JSON.parse(localStorage.getItem('selectedLanguage'))
    let value = localStorageLanguage[pageName];
    if(this.baseData && this.baseData. multilingualOption && this.baseData.multilingualOption.showLangOption === 'true' || this.baseData.multilingualOption.showLangOption === true || plume){
      if (pageName && value) {
        this.$i18n.locale = value
      }
    } // single language campaign and non-plume campaign's it will use default language code
    
    if(this.baseData && this.baseData.isBuilderPreview){
      this.showInternetProbe = false
      this.isOnline = true
      return
    }
    
    if (this.showInternetProbe) {
      this.timer = this.redirectTime;
      if(!!sessionStorage.getItem('internetConnectChecked')) {
        if(this.redirectTime!=''){
          this.intervalId = window.setInterval(() => {
            if(this.timer!==0){
              this.$set(this, 'timer', this.timer-1000);
            } else {
              clearInterval(this.intervalId);
            }
          },1000);
        }
      } else {
        await this.checkInternetConnection();
      }
    }

    if (!!sessionStorage.getItem('isOnline')) {
      this.isOnline = true
      this.showInternetProbe = false
    } else {
      this.isOnline = false
      this.showInternetProbe = true
    }
  },

  methods: {
    getConversionCode(){
      return atob(this.conversionTrackingCode);
    },

    async checkInternetConnection () {
      const urlParams = new URLSearchParams(window.location.search);
      this.debugging = { urlParams: urlParams.toString() };

      if (urlParams.has('probe') && urlParams.get('probe') === 'done') {
        this.debugging.probe_detected = true;
        this.showInternetProb = false
        this.isOnline = true;
        return;
      }

      this.debugging.probes = [];

      if (!urlParams.has('retry')) {
        this.debugging.set_first_retry = true;
        urlParams.set('retry', `1_${Date.now()}`);
      }

      let attempt = parseInt(urlParams.get('retry').split('_')[0]);

      this.currentAttempt = attempt;

      try {
        let response = null;
        let responseUrl = '';

        for (const host of $HOSTS) {
          try {
            this.currentHost = host;
            this.debugging.probes.push({ target: host, status: 'started', delay: $DELAY });
            await new Promise(r => setTimeout(r, $DELAY));

            response = await axios.get(host, {
              timeout: $TIMEOUT,
            });
            responseUrl = new URL(response.request.responseURL);
            const checkUrl = new URL(host);
            this.isOnline = response.status === 200 && responseUrl.host === checkUrl.host;
            if (this.isOnline) {
              this.debugging.set_probe_done = true;
              this.debugging.probes.push({ target: host, status: 'OK' });
              urlParams.set('probe', `done`);
              window.location.href = window.location.protocol + "//" +
                window.location.host +
                window.location.pathname +
                "?" + urlParams.toString();
              this.showInternetProbe = false
              const postLoginData = this.$store.getters.postLogin
              sessionStorage.setItem('internetConnectChecked', true)
              sessionStorage.setItem('isOnline', true)
              if(postLoginData.payload.has_interstitial){
                this.$router.push('interstitial')
              }
              break;
            } else {
              this.debugging.probes.push({ target: host, status: 'FAILED (expectation failed)', responseCode: response.status, responseHost: responseUrl.host });
            }
          } catch (e) {
            this.debugging.probes.push({ target: host, status: 'FAILED (in body)', errorMessage: e.message });
          }
        }

        if (!this.isOnline) {
          this.debugging.round_failed = true;
          throw new Error('Internet probe failed');
        }
      } catch (error) {
        this.debugging.error_handler = true;
        this.debugging.probes.push({ target: this.currentHost, status: 'FAILED (in catch)', errorMessage: error.message });
        if (this.isDebugging) {
          await new Promise(r => setTimeout(r, 1000));
        }

        urlParams.set('retry', `${attempt + 1}_${Date.now()}`);

        if (this.currentAttempt > $MAX_RETRY_NUMBER) {
          // Show error message
          this.showConnectionError = true;

          // Set probe after 3 sec and
          await new Promise(resolve => {
            setTimeout(() => {
              urlParams.set('probe', 'done');
              resolve();
            }, 2000);
          });
        }

        window.location.href = window.location.protocol + "//" +
          window.location.host +
          window.location.pathname +
          "?" + urlParams.toString()
      }
    },
    stopInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
  },

  beforeDestroy() {
    this.stopInterval();
  },

  computed:{
    connectionStatusMessage() {
      if (!this.isOnline && this.currentAttempt <= $MAX_RETRY_NUMBER) {
        return this.$t('attempt_x_of_y', { x: this.currentAttempt, y: $MAX_RETRY_NUMBER });
      }

      if (!this.isOnline) {
        return this.$t('internet_connection_error');
      }
    },
    primaryButtonStyles() {
      if (!this.baseData.branding) {
        return {
          'background': '#FFFFFF',
          'justify-content': 'flex-start',
          'color': '#000000',
        }
      }
      let background = '';
      if (this.baseData.branding.background.substr(0, 3) === 'url') {
        background = `${this.baseData.branding.background} ${this.baseData.branding.backgroundRepeat.value}`
      } else {
        background = this.baseData.branding.background
      }
      return {
        'background': background,
        'justify-content': this.baseData.branding.thankyouJustifyContent.value || 'flex-start',
        'color': this.baseData.branding.textColor,
        'font-family': this.baseData.branding.fontFamily.value
      }
    },
    hasPreviewAds() {
      return this.baseData.isAdvertising && this.baseData.preview;
    },
    ads(){
      return this.baseData.advertisement;
    },
    baseData(){
      return this.$store.state.baseData
    },
    postLogin(){
      return this.$store.getters.postLogin
    },
    wftkn(){
      return (this.postLogin) ? this.postLogin.payload.wftkn : null;
    },
    campaignId(){
      return this.baseData ? this.baseData.campaignId : null;
    },
    thankYouData(){
      if (this.$store.state && this.$store.state.postLogin && this.$store.state.postLogin.payload) {
        // [Mark Pyskunov] 2024-06-05
        // For some reason when I debugged for Plume I saw the this.$i18n.messages.en is empty.
        // With the empty text the redirect looks bad and empty. So I put this.$store.state.postLogin.payload.thankyou_page as a fallback
        return this.$store.state.postLogin.payload.thankyou_page === 'Enjoy Browsing...'
          ? this.$t('enjoy_browsing') ? this.$t('enjoy_browsing') : this.$store.state.postLogin.payload.thankyou_page
          : this.$store.state.postLogin.payload.thankyou_page;
      }

      // I hope that will be a never case in LP v2
      return null;
    },
    thankYouPage(){
      const thankYouOptions = (this.$store.state && this.$store.state.baseData && this.$store.state.baseData.thankYouPageOptions) ? this.$store.state.baseData.thankYouPageOptions : [];
      return (thankYouOptions.length || thankYouOptions.enabled) ? thankYouOptions : null;
    },
    order(){
      if(this.thankYouPage){
        return this.thankYouPage.order
      }
      return ["Like Box", "Custom Text", "Add Image", "Add HTML"]
    },
    thankYouElements(){
      if(this.thankYouPage){
        return this.thankYouPage.options
      }
      return null;
    },

    redirectUrl(){
      return this.postLogin ? this.postLogin.payload.nextUrl : '';
    },

    redirectTime(){
      return this.postLogin ? this.postLogin.payload.redirection_time_of_thankyou_page : 2000;
    },
    hasAdvertising(){
      return this.postLogin ? this.postLogin.payload.advertising : false;
    },
    hasTopAd(){
      return (this.hasAdvertising && (this.postLogin.payload.adData && ('zone_position_post_top' in this.postLogin.payload.adData)))
    },
    hasMiddleAd(){
      return (this.hasAdvertising && (this.postLogin.payload.adData && ('zone_position_post_middle' in this.postLogin.payload.adData)))
    },
    hasBottomAd(){
      return (this.hasAdvertising && (this.postLogin.payload.adData && ('zone_position_post_bottom' in this.postLogin.payload.adData)))
    },
    facebookPage(){
      return (this.postLogin) ? this.postLogin.payload.facebook_page : null;
    },
    hasFacebookLike(){
      return (this.postLogin) ? this.postLogin.payload.facebook_like : false;
    },
    facebookPixelId(){
      return (this.postLogin) ? this.postLogin.payload.campaign_facebook_pixel_id : null;
    },
    conversionTrackingCode(){
      return (this.postLogin) ? this.postLogin.payload.conversion_tracking_code : null;
    },
    conversionHTML(){
      return (this.conversionTrackingCode) ? atob(this.conversionTrackingCode) : null;
    },
    isNoRedirect(){
      return (this.postLogin && this.postLogin.payload.nextUrl=='' && this.postLogin.payload.redirection_time_of_thankyou_page=="") ? true : false
    },
    isManual(){
      return (this.postLogin && this.postLogin.payload.nextUrl!='' && this.postLogin.payload.redirection_time_of_thankyou_page==0) ? true : false
    },
    firstHalfOrder(){
      return this.order.slice(0, Math.ceil(this.order.length/2))
    },
    secondHalfOrder(){
      return this.order.slice(Math.ceil(this.order.length/2), this.order.length)
    }
  },

  watch: {
    async timer(){
      if(this.timer==0 && !this.isManual){

        if (this.redirectUrl && this.isOnline) {
          window.location = this.redirectUrl;
        }
      }
    }
  }
}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@500&family=Archivo&family=B612&family=BioRhyme&family=Cairo&family=Cardo&family=Crimson+Text&family=Fjalla+One&family=Frank+Ruhl+Libre&family=IBM+Plex+Sans&family=Karla&family=Lora&family=Montserrat&family=Muli:wght@577&family=Playfair+Display&family=Roboto&family=Rubik&family=Source+Sans+Pro&family=Spectral&family=Titillium+Web&family=Varela&family=Vollkorn&family=Work+Sans&display=swap');
.contentWrapper{
  width: 100%;
}
.postLogin-container{
  max-width: 600px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.wifi-loader-area{
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.redirect-area{
  display: flex;
  align-items: center;
  justify-content: center;
}
.redirect-time{
  font-size: 12px;
  background: #f4f4f4;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 32px;
  font-weight: 600;
  color: #808080;
  margin-right: 10px;
}
.next-btn{
  background: #55acee;
  padding: 10px 20px;
  border-radius: 32px;
  color: white;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
}
</style>
<style>
.thankyou-html-area img{
  max-width: 100%;
}
</style>
