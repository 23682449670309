<template>
  <div class="country-container">
    <div class="country-selector" v-click-outside="hide">
      <div class="search">
        <input
          ref="searchCountry"
          :placeholder="$t('placeholder_country')"
          @input="(e) => filterCountries(e.target.value)"
        >
      </div>
      <template
        v-if="availableCountries.length > 0"
      >
        <div
          v-for="country in availableCountries"
          :key="country.code"
          class="country-option"
          @click="handleSelect(country)"
        >
          <span>{{ country.flag }}&#160;{{ country.name }}&#160;{{ country.dialCode }}</span>
        </div>
      </template>
      <template
        v-else
      >
        <div
          class="country-option"
        >
          <span v-text="$t('no_options_available')"></span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
import { remove } from 'lodash'
export default {
  data() {
    return {
      countries: [],
      countriesCopy:[],
      availableCountries: [],
      preferedCountry:[
        {
          "code": "us",
          "flag": "🇺🇸",
          "name": "United States of America",
          "dialCode": "+1"
        },
        {
          "code": "ca",
          "flag": "🇨🇦",
          "name": "Canada",
          "dialCode": "+1"
        },
        {
          "name": "Mexico",
          "flag": "🇲🇽",
          "code": "MX",
          "dialCode": "+52"
        },
      ],
      showModal: true,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
  },
  async mounted() {
    this.$refs.searchCountry.focus();

    const countries = await this.loadCountries();
    this.countries = countries.default;
    this.countriesCopy = [...this.countries]
    this.preferedCountry.forEach(country => {
      remove(this.countriesCopy, { code: country.code.toUpperCase() });
    });
    this.preferedCountry = this.preferedCountry.concat(this.countriesCopy)
    this.availableCountries = this.preferedCountry.slice(0,5)
    this.popupItem = this.$el
  },
  methods:{
    async loadCountries() {
      return import("./country-codes.json");
    },
    filterCountries(searchTerm) {
      this.availableCountries = this.preferedCountry.filter((country) =>
        country.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
        country.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        country.dialCode.toLowerCase().includes(searchTerm.toLowerCase())
      ).slice(0,5)
    },
    handleSelect(country) {
      this.$emit("select", country);
    },
    hide(){
      this.$emit("close");
    },
  }
}
</script>

<style>
.country-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.country-option {
  padding: 10px 5px;
  border-bottom: 1px solid #d3d3d3b3;
  cursor: pointer;
  user-select: none;
}
.search {
  box-sizing: border-box;
  padding: 10px 4px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background:#ebebeb;
  z-index: 5;
}
.search input {
  height: 6px;
  font-size: 12px;
  text-align: center;
  width: 256px;
  cursor: pointer;
  margin: unset!important;
}
.country-selector {
  position: absolute;
  height: 258px;
  width: 330px;
  overflow-y: scroll;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #ebebeb;
  color: #000000;
  top: -150px;
  z-index: 999;
  border-radius: 14px;
}
.country-selector::-webkit-scrollbar {
  display: none;
}
.country-option:last-child {
  border: none;
}
</style>
