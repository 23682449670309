<template>
  <input :type="type" v-on:keyup="updateValue">
</template>

<script>
  import 'cleave.js';
  export default{
    props: ['value', 'options', 'type'],
    mounted() {
     this.cleave = new Cleave(this.$el, this.options)
     this.cleave.setRawValue(this.value);
     (!this.type) ? this.type='text' : '';
    },
    destroyed() {
      this.cleave.destroy()
    },
    watch: {
      value: 'updateInput'
    },
    methods: {
      updateValue() {
      	var val = this.cleave.getRawValue()
        if (val !== this.value) {
        	this.$emit('input', val)
        }
      },
      updateInput() {
        this.cleave.setRawValue(this.value)
      }
    }
  }
</script>