<template>
  <div>
    <logo :url="baseData.logoUrl" />

    <div v-if="$t('title_text')">
      <h2
        :key="$i18n.locale"
        :style="titleTextStyles"
        v-text="replacePredefinedVariables($t('title_text'))"
      />
    </div>

    <header-carousel v-if="isCarousel" :baseData="baseData" />

    <div v-if="!baseData.fbPayload && !baseData.facebookGeneratedCampaign" v-html="baseData.headerHtml" />
  </div>
</template>

<script>
  import HeaderCarousel from './header-image/header.vue';
  import Logo from '../logo/logo.vue';
  import {replacePredefinedVariables} from "../../helpers";

  export default {
    props: ['baseData'],
    components: {
      HeaderCarousel,
      Logo
    },
    methods: {
      replacePredefinedVariables,
    },
    computed: {
      isCarousel(){
        return Array.isArray(this.baseData.headerUrl);
      },
      titleTextStyles() {
        return {
          'font-size': `${this.baseData.branding.titleTextSize}px !important`,
          'color': `${this.baseData.branding.textColor}`,
        }
      }
    }
  }
</script>
