import Vue from 'vue'
import Vuelidate from 'vuelidate';
import store from './store.js';
import moment from 'moment';
import router from './routes/index'
import IndexPage from './views/index'
import { i18n } from './lang'

require("es6-promise").polyfill();
window.axios = require("axios");
window.qs = require("qs");
window.axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

Vue.config.errorHandler = (err, vm, info) => {
  if (window.self !== window.top) {
    // Don't log errors in preview mode
    return;
  }
  const stack = err.stack.split("\n");
  const errorPayload = JSON.stringify({
    error: {
      stack: stack,
      message: err.message,
      name: err.name,
      description: err.description,
      number: err.number,
      fileName: err.fileName,
      lineNumber: err.lineNumber,
      columnNumber: err.columnNumber,
    },
    info: info,
    url: window.location.href,
  });
  sendError(errorPayload);
};

Vue.config.warnHandler = function (msg, vm, trace) {
  if (window.self !== window.top) {
    // Don't log errors in preview mode
    return;
  }
  const errorPayload = JSON.stringify({
    message: msg,
    trace: trace,
    url: window.location.href,
  });
  sendError(errorPayload);
};

export async function landingPageLogUsingPortal(message, payload = undefined) {
  console.log('[logUsingPortal] init', {message, payload});

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  };

  return axios
    .post("/index.php?controller=landingpage&action=landing-page-log", qs.stringify({
      message,
      payload,
    }), config);
}

function sendError(item) {
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  };
  console.error('Sending Error', item)
  axios
    .post("/index.php?controller=landingpage&action=error-log", qs.stringify({
      payload: item,
    }), config)
    .then(function (response) {
      if (response.status !== 200) {
        setTimeout(function () {
          sendError(item);
        }, 3000);
      }
    })
    .catch(function (error) {
      setTimeout(function () {
        sendError(item);
      }, 3000);
    });
}

Vue.prototype.moment = moment;
window.moment = moment;

Vue.prototype.$eventHub = new Vue();
Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.use(Vuelidate);

import VueAgile from 'vue-agile'
Vue.use(VueAgile)

new Vue({
  el: '#app',
  template: '<IndexPage/>',
  components: { IndexPage },
  store: store,
  router,
  i18n,
});
