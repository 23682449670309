<template>
  <div class="Mw-PostLogin-thankyou-custom-image" v-if="image && image.enabled">
    <img :src="image.url" alt="">
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object
    }
  },
  computed: {
    image(){
      return this.option.add_image
    }
  }
}
</script>

<style scoped lang="scss">
.Mw-PostLogin-thankyou-custom-image{
  padding: 20px;
  img{
    max-width: 100%;
  }
}
</style>
