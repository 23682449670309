<template>
  <div class="Mw-PostLogin-thankyou-like-box" v-if="likebox.enabled">
    <div class="fb-page"
      v-if="showLikeBox"
      id="fb-page"
      :data-href="url"
      data-hide-cover="false"
      data-show-facepile="true"
      data-show-posts="false">
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  data(){
    return {
      showLikeBox: true
    }
  },
  props: {
    option: {
      type: Object
    }
  },
  computed: {
    likebox(){
      return !!this.option.like_box && this.option.like_box
    },
    url(){
      if(this.likebox.url && (this.likebox.url.includes('https://') || this.likebox.url.includes('http://'))){
        return this.likebox.url
      }
      return 'https://facebook.com/'+this.likebox.url
    }
  },
  mounted(){
    this.mountFB()
    this.checkFBLike(this)
  },
  watch: {
    url(){
      FB.XFBML.parse();
      this.checkFBLike(this)
    }
  },
  methods: {
    mountFB(){
      window.fbAsyncInit = function () {
      FB.init({xfbml: true,version: 'v2.4'});};
      (function (d, s, id) {var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s);
        js.id = id;
        js.src = '//connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    },
    checkFBLike: _.debounce(($vm) => {
      $vm.$nextTick(()=>{
        if(typeof FB !== 'undefined') {
          if(window && window.fbAsyncInit){
            FB.XFBML.parse();
          }
        }
      })
    }, 500),
  }
}
</script>

<style scoped lang="scss">
.Mw-PostLogin-thankyou-like-box{
  padding: 20px 0;
  img{
    max-width: 100%;
  }
}
</style>
