<template>
  <a href="#"
    class="social-btn cu-btn"
    @click.prevent="showCustomFields"
    :style="loginButtonStyles">
    <i class="fa fa-envelope" v-show="baseData.branding.iconVisible"></i>
    <span v-text="$t('email')" />
  </a>
</template>

<script>

export default {
  name: 'CustomButton',
  props: ['api', 'action', 'wftkn'],
  computed: {
    baseData(){
      return this.$store.state.baseData
    },
    loginButtonStyles() {
      return {
          'background': this.loginButtonColor || "orange",
          'color': this.loginButtonTextColor || '#ffffff',
          'justify-content': this.baseData.loginOptions.styles.layout === "app" ? 'center' : this.loginButtontextAlignment || 'flex-start'
      }
    },
    loginButtontextAlignment(){
        return this.baseData.branding.loginButtonTextAlign.value
    },
    loginButtonColor(){
        return this.baseData.loginOptions.styles.buttons.emailButtonColor
    },
    loginButtonTextColor(){
        return this.baseData.loginOptions.styles.buttons.loginButtonsTextColor
    },
  },
  methods: {
    showCustomFields(){
      this.$eventHub.$emit('showCustom', true)
    }
  }
}
</script>

<style>
.cu-btn{
  background: #F7931E;
}
.cu-btn:hover{
  background: #cb771a;
}
</style>
