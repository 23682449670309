<template>
    <a href="#" class="social-btn twl-btn" @click.prevent="showTwilioFields" :style="loginButtonStyles">
      <i v-show="baseData.branding.iconVisible"  class="fa fa-commenting"/>
      <span v-text="$t('sms')" />
    </a>
</template>

<script>
    export default {
        name: 'TwilioButton',
        computed: {
            baseData(){
                return this.$store.state.baseData
            },
            loginButtonStyles() {
                return {
                    'background': this.loginButtonColor || "#35c300",
                    'color': this.loginButtonTextColor || '#ffffff',
                    'justify-content': this.baseData.loginOptions.styles.layout === "app" ? 'center' : this.loginButtontextAlignment || 'flex-start'
                }
            },
            loginButtontextAlignment(){
                return this.baseData.branding.loginButtonTextAlign.value
            },
            loginButtonColor(){
                return this.baseData.loginOptions.styles.buttons.smsButtonColor
            },
            loginButtonTextColor(){
                return this.baseData.loginOptions.styles.buttons.loginButtonsTextColor
            },
        },
        methods: {
            showTwilioFields()
            {
                this.$eventHub.$emit('showTwilio', true)
            }
        }
    }
</script>

<style>
    .twl-btn{
        background: #35c300;
    }
    .twl-btn:hover{
        background: #257b00;
    }
</style>
