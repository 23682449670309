<template>
<?xml versio="1.0" encoding="iso-8859-1"?>
<!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
<svg height="120px" width="120px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 511.998 511.998" xml:space="preserve">
	<g>
	<path style="fill:#FFA500;" d="M13.425,208.583c-2.827,0-5.676-0.889-8.103-2.727c-5.914-4.481-7.076-12.907-2.596-18.822
		c29.028-38.315,66.94-70.058,109.637-91.8c44.799-22.811,93.124-34.379,143.634-34.379s98.836,11.566,143.635,34.378
		c42.697,21.743,80.609,53.488,109.638,91.802c4.481,5.914,3.32,14.342-2.596,18.822c-5.917,4.483-14.342,3.318-18.822-2.596
		C432.225,129.835,347.717,87.725,255.999,87.725S79.773,129.835,24.144,203.261C21.502,206.747,17.487,208.582,13.425,208.583z"/>
	<path style="fill:#FFA500;" d="M256,451.144c-3.439,0-6.876-1.313-9.5-3.935L49.583,250.294
		c-4.614-4.614-5.245-11.873-1.499-17.214c47.604-67.886,125.329-108.414,207.915-108.414c82.588,0,160.314,40.528,207.918,108.414
		c4.26,6.074,2.789,14.453-3.286,18.714c-6.074,4.262-14.453,2.789-18.714-3.286c-42.578-60.72-112.082-96.971-185.918-96.971
		c-70.095,0-136.282,32.67-179.24,87.932L265.5,428.207c5.246,5.246,5.246,13.753,0,19.001
		C262.876,449.833,259.438,451.144,256,451.144z"/>
	<path style="fill:#FFA500;" d="M256,451.144c-3.439,0-6.876-1.313-9.5-3.935L95.633,296.344
		c-4.384-4.385-5.198-11.199-1.972-16.493c16.67-27.348,40.152-50.24,67.903-66.198c28.635-16.469,61.291-25.172,94.434-25.172
		c33.144,0,65.801,8.705,94.436,25.172c27.751,15.958,51.233,38.85,67.903,66.198c3.226,5.293,2.412,12.109-1.972,16.493
		L265.5,447.21C262.876,449.833,259.438,451.144,256,451.144z M122.34,285.052l133.658,133.657l133.659-133.657
		c-30.609-43.839-79.835-69.702-133.66-69.702C202.174,215.349,152.949,241.213,122.34,285.052z"/>
	</g>
	<path style="fill:#FFA500;" d="M256,437.71l103.645-103.645C342.408,293.88,302.496,265.727,256,265.727
	s-86.407,28.153-103.645,68.339L256,437.71z"/>
	<path style="fill:#FFA500;" d="M256,451.144c-3.439,0-6.876-1.313-9.5-3.935L142.854,343.564c-3.885-3.887-5.013-9.747-2.847-14.797
	c19.93-46.458,65.46-76.478,115.993-76.478c50.535,0,96.065,30.019,115.993,76.478c2.166,5.052,1.037,10.911-2.847,14.797
	L265.5,447.209C262.876,449.833,259.438,451.144,256,451.144z M168.593,331.303L256,418.71l87.407-87.407
	C326.178,299.357,292.773,279.16,256,279.16C219.229,279.162,185.824,299.357,168.593,331.303z"/>
</svg>
</template>
  
  <script>
  export default {
	name: "wifi-icon",
  }
  </script>
  
  <style>
  
  </style>