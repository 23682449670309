<template>
  <layout>
    <transition name="slide-fade-right">
      <div v-if="shouldShowPaymentOptions"
        class="custom-field-holder"
        style="text-align:center">

        <div class="container" v-if="paidSessionOver">
          <div class="pre-error paidsession">
            <p>{{ $t(preErrorPayload.reject_reason) }}</p>
            <p>{{ $t(preErrorPayload.reject_solution) }}</p>
          </div>
        </div>

        <transition name="slide-fade-right">
          <div class="payment-options" v-if="!showStripeForm">
            <div class="option"
              v-for="(item, index) in filteredPlans"
              :key="index"
              @click.prevent="choosePlan(item)">
              <div class="info">
                <h3>{{item.humanTime}}</h3>
                <p>{{item.name}}</p>
              </div>
              <div class="buy">
                <button>
                  {{ (item.amount!=0) ? getAmount(item) : 'FREE' }}
                </button>
              </div>
            </div>
            <a
              href="#"
              v-if="showPaymentOptionsBackButton"
              @click.prevent="backToPreviousPage()">
              ← {{ $t('back_button') }}
            </a>
          </div>
        </transition>

        <transition name="slide-fade-right">
          <div v-if="showStripeForm">
            <payment-form
              :selectedPlan="selectedPlan"
              :planData="planData"
              :api="api"
              :wftkn="wftkn"
              action="creditcard"
              :type="baseData.paymentPlans.type"
              :info="baseData.paymentPlans" />
            <a v-if="!paymentSuccess"
              href="#"
              @click.prevent="showStripeForm=false">
              ← {{ $t('back_button') }}
            </a>
          </div>
        </transition>
      </div>
    </transition>
  </layout>
</template>

<script>
import Layout from './layout'
import PaymentForm from '../components/payments/payment-form.vue';

export default {
  components: {
    Layout,
    PaymentForm
  },
  data() {
    return {
      showStripeForm: false,
      selectedPlan: null,
      planData: null,
      paymentSuccess: false,
      showPaymentOptions: false
    }
  },
  computed: {
    shouldShowPaymentOptions() {
      return this.showPaymentOptions;
    },
    baseData() {
      return this.$store.state.baseData
    },
    wftkn(){
      return this.$store.state.wftkn
    },
    api(){
      return this.$store.state.api
    },
    isPaidCampaign(){
      return !!(this.baseData.paymentPlans);
    },
    isRejeted() {
      return this.$store.state.isRejeted
    },
    paidSessionOver(){
      return (this.isRejeted && this.isPaidCampaign);
    },
    plans() {
      return this.$store.state.plans
    },
    filteredPlans() {
      var ret = [];
      var plansvar = this.$store.state.plans;

      if (plansvar != null && plansvar.length > 0) {
        plansvar.forEach(plan => {
          if (!((plan.amount===0) && ( this.freeOver || this.paidSessionOver ))) {
            ret.push(plan);
          }
        });
      }
      
      return ret;
    },
    freeOver() {
      return this.$store.state.freeOver
    },
    showPaymentOptionsBackButton() {
      return !this.paymentSuccess && this.baseData.backButton && this.baseData.loginOptions.options  && this.baseData.loginOptions.options.length > 1 && this.$route.query.option
    },
  },
  methods: {
    showPaymentOptionsArea(value){
      this.showPaymentOptions = value;
    },

    hidePaymentGoBackOption(val){
      this.paymentSuccess = val;
    },

    choosePlan(plan){
      if(plan.amount==0){
        this.showPaymentOptions = false;
        this.planData = 0;
        this.$router.push('/social-logins').catch(error => {
          console.info(error.message)
        })
      }else{
        this.selectedPlan = plan;
        this.planData = plan.amount + "_" + plan.time;
        this.showStripeForm = true;
      }
    },
    getAmount(item){
      if(item.currency && item.currency.symbol){
        return item.currency.symbol + (item.amount/100).toFixed(2)
      }
      return '$' + (item.amount/100).toFixed(2)
    },
    checkPaidReject(){
      if(this.isPaidCampaign && this.paidSessionOver){
        this.showPaymentOptions = true;
      }
    },
    backToPreviousPage() {
      this.$router.push("/social-logins").catch(error => {
        console.info(error.message)
      })
    },
  },
  created(){
    this.$eventHub.$on('showPaymentOptions', this.showPaymentOptionsArea);
    this.$eventHub.$on('hidePaymentGoBack', this.hidePaymentGoBackOption)
  },
  beforeDestroy(){
    this.$eventHub.$off('showPaymentOptions');
    this.$eventHub.$off('hidePaymentGoBack');
  },
  beforeMount() {
    this.preErrorPayload = this.$store.state.preErrorPayload;

    if(this.plans && this.plans.length > 0) {
      this.showPaymentOptions = true;
    } else if(!this.baseData.preview){
      this.$router.push("/social-logins").catch(error => {
        console.info(error.message)
      })
    }

    this.checkPaidReject();
  }
}
</script>

<style lang="scss">
.loginBorder{
  .social-btn{
    border-radius: inherit;
  }
}
.appStyle{
  &.social-btn-holder{
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 70%;
    max-width: 400px;
    border-radius: 32px;
    .social-btn{
      margin: 10px;
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 65px;
      width: 65px;
      padding: 0;
      span{
        display: none;
      }
      .fa{
        margin: 0;
        font-size: 32px;
      }
      .fab{
        margin: 0;
        font-size: 32px;
      }
    }
  }
}
.custom-field-holder{
  width: 100%;
  /*text-align: center;*/
  /*top: 20px;*/
  position: relative;
  padding: 30px 0;
  /*height: 100%;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /*justify-content: center;*/
}
.custom-field-holder a{
  color: inherit;
  text-decoration: none;
  display: inline-block;
  margin: 15px 0;
  border:none;
}
.payment-options{
  display: flex;
  width: 80%;
  flex-direction: column;
  margin: 0 auto;
}
.payment-options .option{
  display: flex;
  align-items: center;
  border-radius: 32px;
  border: 1px solid #ccc;
  padding: 10px 20px;
  justify-content: space-between;
  margin-bottom: 10px;
  transition: 0.3s all;
}
.payment-options .option:hover{
  cursor: pointer;
  background: #f2f2f2;
}
.payment-options .option .info{
  text-align: left;
  float: left;
}
.payment-options .option .info h3, .payment-options .option .info p{
  margin: 0;
}
.payment-options .option .info p{
  color: #a3a3a3;
}
.payment-options .option .info p.price{
  color: #64af0c;
  font-weight: 600;
}
.payment-options .option .buy button{
  background: #64af0c;
  padding: 10px 15px;
  border: 1px solid #64af0c;
  border-radius: 32px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  transition: 0.3s all;
  min-width: 100px;
}
.payment-options .option .buy button:hover{
  background: #75c21b;
}
.payment-options .option.special{
  background: #fff6cf;
  border-color: #ffdd48;
}
.payment-options .option.special:hover{
  cursor: pointer;
  background: #ffeb92;
}
.payment-options .option .buy{
  text-align: right;
  margin-top: 5px;
}
.payment-options .option.special .buy button{
  background: #202020;
  border-color: #202020;
}

.payment-options a{
  text-align: center;
}
</style>
