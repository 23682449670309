<template>
    <a @click.prevent="showPasscodeFields()" href="#" class="social-btn pass-btn" :style="loginButtonStyles">
        <i class="fa fa-lock" v-show="baseData.branding.iconVisible"/>
      <span v-text="$t('stripe')" />
    </a>
</template>

<script>
    export default {
        name: 'StripeLogin',
        props: ['api', 'action', 'wftkn'],
        computed: {
            baseData(){
                return this.$store.state.baseData
            },
            loginButtonStyles() {
                return {
                    'background': this.loginButtonColor || "#636363",
                    'color': this.loginButtonTextColor || '#ffffff',
                    'justify-content': this.baseData.loginOptions.styles.layout === "app" ? 'center' : this.loginButtontextAlignment || 'flex-start'
                }
            },
            loginButtontextAlignment(){
                return this.baseData.branding.loginButtonTextAlign.value
            },
            loginButtonColor(){
                return this.baseData.loginOptions.styles.buttons.creditcardButtonColor
            },
            loginButtonTextColor(){
                return this.baseData.loginOptions.styles.buttons.loginButtonsTextColor
            },
        },
        methods: {
            showPasscodeFields()
            {
                this.$emit('showPaymentOptions')
            }
        }
    }
</script>

<style>
    .pass-btn{
        background: #636363;
    }
    .pass-btn:hover{
        background: #636363;
    }
</style>
