<template>
    <div class="terms">
        <transition name="slide-fade">
            <div class="terms-text-holder" v-if="showTerms">
                <div class="close" @click.prevent="showTerms=false">&times;</div>
                <div class="terms-scroll">
                  <div v-if="termsData.customTerms" data-meta="c1">
                    <!-- If the user hasn't updated yet to new language configuration we will show the past -->
                      <div v-html="convertToMarkup(termsData.customTerms)"></div>
                  </div>

                  <div v-else-if="!termsData.customTerms && !loading" data-meta="c2">
                    <!-- If the site is configured - payload will contain some parts for the standard T&C and they need to be used -->
                    <div v-html="buildTerms"></div>
                  </div>
                </div>
            </div>
        </transition>

        <switches v-if="!wb && !hideButton && !covidForm" id="agree-btn" v-model="enabled" open-name="" :close-name="$t('accept')" color="green"></switches>

        <p
          v-if="!wb && !covidForm"
          class="anchor"
          @click.prevent="showTermsAndConditions()"
          v-text="$t('agree')"
        />
        <p
          v-if="wb && !covidForm"
          class="anchor"
          @click.prevent="showTermsAndConditions()"
          style="margin-bottom: -7px;"
          v-text="$t('by_connecting')"
        />

    </div>
</template>

<script>
    import Switches from 'vue-switch/switch-2.vue';
    import tnc from '../../terms-and-conditions'

    export default {
        components:{
            Switches,
        },
        props: ['termsData', 'wb', 'covidForm', 'wftkn', 'campaignId', 'hideButton'],
        data(){
            return {
                enabled: false,
                showTerms: false,
                loading: false,
                api: '/index.php?controller=apiservices&name=get-dynamic-company-legal-name',
                legalName: this.termsData && this.termsData.options && this.termsData.options.legal_company_name
                  ? this.termsData.options.legal_company_name
                  : 'MyWiFi, a division of Guest Networks Inc.',
                legalEmail: this.termsData && this.termsData.options && this.termsData.options.support_link
                  ? this.termsData.options.support_link
                  : 'support@mywifi.io',
                onlineTool: this.termsData && this.termsData.options && this.termsData.options.tnc__online_tool
                  ? this.termsData.options.tnc__online_tool
                  : this.$i18n.messages.en.online_tool,
                adsPreferencesManager: this.termsData && this.termsData.options && this.termsData.options.tnc__ads_preferences_manager
                  ? this.termsData.options.tnc__ads_preferences_manager
                  : this.$i18n.messages.en.ads_preferences_manager,
                myProfileOptOut: this.termsData && this.termsData.options && this.termsData.options.tnc__my_profile_opt_out_and_data_deletion_tool
                  ? this.termsData.options.tnc__my_profile_opt_out_and_data_deletion_tool
                  : this.$i18n.messages.en.my_profile_opt_out,
                date: this.termsData && this.termsData.options && this.termsData.options.tnc__date
                  ? this.termsData.options.tnc__date
                  : 'May 25, 2018',
                tnc,
            }
        },

        watch:{
            enabled(){
              setTimeout(()=>{
                (this.enabled) && 
                this.$router.push('/consent').catch(error => {
                  console.info(error.message)
                })
              }, 200);
            },
            showTerms(){
              if(this.showTerms){
                this.getDynamicData();
              }
            },
        },
      computed: {
        baseData(){
          return this.$store.state.baseData
        },
        buildTerms() {
          return tnc.build(
                      this.legalName,
                      this.legalEmail,
                      this.onlineTool,
                      this.adsPreferencesManager,
                      this.myProfileOptOut,
                      this.date,
                    )
        },
      },

        methods: {
            showTermsAndConditions()
            {
                this.showTerms = true;
//                this.$eventHub.$emit('showTermsConditions', true);
            },

            getDynamicData(){
              this.loading = true;
              axios.get(this.api + "&wftkn=" + this.wftkn + "&campaign_id=" + this.campaignId).then((res)=>{
                  this.legalName = res.data.legal_company_name ? res.data.legal_company_name : this.legalName;
                  this.legalEmail = res.data.legal_support ? res.data.legal_support : this.legalEmail;
                  this.onlineTool = res.data.tnc__online_tool ? res.data.tnc__online_tool : this.onlineTool;
                  this.adsPreferencesManager = res.data.tnc__ads_preferences_manager ? res.data.tnc__ads_preferences_manager : this.adsPreferencesManager;
                  this.myProfileOptOut = res.data.my_profile_opt_out ? res.data.my_profile_opt_out : this.myProfileOptOut;
                  this.date = res.data.tnc__date ? res.data.tnc__date : this.date;
                  this.loading = false;
              }).catch((res)=>{
                console.error('getDynamicData Err', res)
                //Not block user to see Terms if we can't getDynamicData
                this.loading = false;
              });
            },

            convertToMarkup(text){
              let markup= text.replace(/\n{2}/g, '&nbsp;</p><p>')
              markup = markup.replace(/\n/g, '&nbsp;<br />')
              markup = '<p>' + markup + '</p>'
              return markup
            }
        }
    }
</script>

<style>
    .terms{
        display: flex;
        width: 100%;
        align-items:  center;
        justify-content: center;
        padding: 30px 0;
        transition: 0.3s all;
        flex-direction: column;
        position: relative;
        text-align: center;
    }
    .terms p.anchor{
      border-bottom: unset;
      margin: 15px 15px 0 15px;
      padding: 0;
      color: inherit;
      text-decoration-line: underline;
      text-underline-offset: 2.3px;
      line-height: 20px;
      cursor: pointer;
    }

    .terms p.anchor:hover{
      letter-spacing: 0.07px;
      transition: all 0.4s ease 0s;
    }

    .vue-switch{
        width: 135px!important;
        height: 45px!important;
        border-radius: 32px!important;
    }
    .vue-switch::after {
        width: 35px!important;
        height: 35px!important;
        left: 5px!important;
        top: 4px!important;
    }
    .vue-switch span{
        left: 53px!important;
        top: 12px!important;
        font-size: 16px!important;
    }
    .vue-switch.z-on::after {
        left: 95px!important;
    }
    .vue-switch.z-on span.open{
        left: 30px!important;
    }

    .terms-text-holder{
        position: fixed;
        z-index: 100;
        background: rgba(0, 0, 0, 0.9);
        padding: 20px 0 20px 20px;
        top: 0;
        bottom:0;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 20px;
        right: 20px;
        max-width: 500px;
        margin: 20px auto;
    }

    .terms-text-holder .terms-scroll{
        overflow: auto;
        overflow-y: auto;
        height: 100%;
        transition: 0.3s all;
        color: white;
        text-align: left;
        width: 100%;
        padding: 10px 20px 10px 10px
    }
    .terms-text-holder .close{
        height: 40px;
        width: 40px;
        background: grey;
        position: absolute;
        top: -10px;
        right: -10px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
        z-index: 20;
        color: white;
        cursor: pointer;
    }

</style>
