<template>
    <div>
        <div class="error-msg-area">
            <alert-msg
                    v-if="$v.username.$error && !$v.username.required"
                    :errorMsg="$t('username_required')"></alert-msg>
            <alert-msg
                    v-if="$v.password.$error && !$v.password.required"
                    :errorMsg="$t('password_required')"></alert-msg>
            <alert-msg v-if="customError"
                       :errorMsg="customErrorMsg"></alert-msg>
        </div>

        <div class="custom-fields" v-if="!connected">
            <div>
                <input v-model.trim="username" @input="delayTouch($v.username)" v-bind:class="{ 'input-error': $v.username.$error }" type="text" :placeholder="$t('placeholder_username')">
                <input v-model.trim="password" @input="delayTouch($v.password)" v-bind:class="{ 'input-error': $v.password.$error }" type="password" :placeholder="$t('placeholder_password')">
            </div>
            <div style="position: relative">
                <button class="social-btn connect" @click.prevent="connect()">{{ $t('connect_button') }}</button>
                <div v-show="loading || validating" class="sk-three-bounce" style="position: absolute; top: 23px; width: 100%;">
                    <div class="sk-child sk-bounce1"></div>
                    <div class="sk-child sk-bounce2"></div>
                    <div class="sk-child sk-bounce3"></div>
                </div>
            </div>
            <a href="#" v-if="baseData.backButton" @click.prevent="goBack()">← {{ $t('back_button') }}</a>
        </div>

        <div class="wifi-loader-area" style="text-align: center">
            <transition name="slide-fade">
                <div class="loader" v-if="loading">
                    <wifi-loader></wifi-loader>
                    <p>{{ $t('connecting') }}</p>
                </div>
            </transition>
        </div>

        <div style="text-align: center">
            <transition name="slide-fade">
                <div class="loader" v-if="connected">
                    <p>{{ $t('operation_success') }}</p>
                </div>
            </transition>
        </div>

    </div>
</template>

<style>
    .wifi-loader-area .wifi-symbol .wifi-circle{
      border-color: #55acee;
    }

</style>


<script>
    import { required } from 'vuelidate/lib/validators';
    import WifiLoader from '../../components/loader/wifi-loading.vue';
    import AlertMsg from '../../components/errors/alert.vue';
    const touchMap = new WeakMap();
    export default {

        data(){
            return {
                password: '',
                username: '',
                loading: false,
                connected: false,
                customError: false,
                customErrorMsg: '',
                validating: false
            }
        },

        components: {
            WifiLoader,
            AlertMsg
        },

        props: ['api', 'wftkn', 'action'],

        validations: {
            username: {
              required
            },
            password: {
                required
            },
        },

        computed: {
            allValidated(){
                return !this.$v.$invalid;
            }
        },

        methods: {
            goBack()
            {
                this.$eventHub.$emit('showOfficeRnD', false)
            },

            delayTouch ($v) {
                $v.$reset();
                if (touchMap.has($v)) {
                    clearTimeout(touchMap.get($v))
                }
                touchMap.set($v, setTimeout($v.$touch, 1000))
            },
            connect() {
                if (this.allValidated) this.connectNow();
                else {
                    this.validating = true;
                    this.allValid();
                }
            },
            allValid() {
                this.delayTouch(this.$v.password);
                this.delayTouch(this.$v.username);
                setTimeout(()=>{ this.validating = false; }, 1000);
                return (!this.$v.$error && this.$v.$dirty);
            },

            connectNow()
            {
              if(this.api.includes('preview')) {
                return
              }
                this.loading = true;
                this.customError = false;
                this.customErrorMsg = '';
                axios.post(this.api+'&action='+this.action+'&wftkn='+this.wftkn+'&password='+encodeURIComponent(this.password)+'&username='+encodeURIComponent(this.username)).
                then((res)=>{
                    this.loading = false;
                    if(!res.data.status)
                    {
                        this.customError = true;
                        this.customErrorMsg = this.$t(res.data.message);
                    }
                    else
                    {
                        this.connected = true;
                        setTimeout(()=>{
                            window.location = decodeURIComponent(res.data.url);
                        }, 1000);
                    }

                });
            }
        },
    }
</script>
