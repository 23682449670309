<template>
  <div class="consent-form">
    <div class="consent-text" :key="$i18n.locale">
      <h3>{{ $t('consent_opt_in_text') }}</h3>
      <p>{{ $t('consent_opt_in_description') }}</p>
      <div class="button-area">
        <button class="consent-button wide" @click="getUpdates()" :style="yesButtonStyles">{{ $t('consent_yes_text') }}</button>
        <button class="consent-button wide black" @click.prevent="getNoUpdates()" :style="noTextStyles">{{ $t('consent_no_text') }}</button>
      </div>
    </div>
    <!-- <accept-terms class="consent-terms" :hideButton="true" :campaignId="$store.state.baseData.campaignId" :wftkn="wftkn" :termsData="$store.state.baseData.termsConditionModal"></accept-terms> -->
  </div>
</template>

<script>
  import AcceptTerms from '../components/terms/terms-conditions.vue'
  export default{
    components: {
      AcceptTerms
    },
    props: ['wftkn', 'preview'],
    computed: {
      baseData(){
        return this.$store.state.baseData
      },
      yesButtonStyles() {
        return {
          'background': this.baseData.loginOptions.styles.primaryButtonColor || '#55acee',
          'color': this.baseData.loginOptions.styles.primaryButtonTextColor || 'white',
        }
      },
      noTextStyles() {
        return {
          'background': this.baseData.loginOptions.styles.primaryButtonColor || '#55acee',
          'color': this.baseData.loginOptions.styles.primaryButtonTextColor || 'white',
        }
      },
    },
    methods: {
      getNoUpdates(){
        this.$store.dispatch('agreeConsent', {wftkn:this.wftkn, consent: 0}).then(()=>{
          this.$store.commit('skipToFreeWifi');
          this.$router.push('/covid').catch(error => {
            console.info(error.message)
          })
        });
      },
      getUpdates(){
        this.$store.dispatch('agreeConsent', {wftkn:this.wftkn, consent: 1}).then(()=>{
          this.$router.push('/covid').catch(error => {
            console.info(error.message)
          })
        });
      },
    }
  }
</script>

<style scoped>
  .consent-form{
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 30px;
  }
  .consent-form .consent-text{
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .consent-form .consent-button{
    background: #55acee;
    border: none;
    border-radius: 32px;
    padding: 10px 20px;
    color: white;
    font-size: 14px;
    transition: 0.3s all;
    cursor: pointer;
  }
  .consent-form .consent-button:hover{
    opacity: 0.8;
  }
  .consent-form .black{
    margin: 10px 0;
    display: inline-block;
  }
  .consent-form h3{
    text-align: center;
    padding: 0 10px;
  }

  .consent-form p{
    text-align: center;
    margin-top: -10px;
  }
  .consent-form .consent-button.wide{
    width: 100%;
    font-size: 16px;
    padding: 15px 20px;
    max-width: 200px;
  }
  .consent-form .button-area{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>
<style>
  .consent-form .consent-terms.terms p.anchor{
    font-size: 12px;
  }
</style>
