<template>
  <div class="Campaign-Ads-placeholder">
    {{ $t('ads_placeholder') }}
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
.Campaign-Ads-placeholder{
  background: #f4f4f4!important;
  height: 75px!important;
  width: 100%;
  margin: 10px 0!important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
}
</style>
