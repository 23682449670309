<template>
  <layout>
    <terms-conditions
      :wb="welcomeBack"
      :campaignId="baseData.campaignId"
      :wftkn="wftkn"
      :termsData="baseData.termsConditionModal" />
  </layout>
</template>

<script>
import Layout from './layout'
import TermsConditions from '../components/terms/terms-conditions.vue';

export default {
  components: {
    Layout,
    TermsConditions
  },
  data() {
    return {}
  },
  computed: {
    baseData() {
      return this.$store.state.baseData
    },
    wftkn(){
      return this.$store.state.wftkn
    },
    welcomeBack() {
      return this.$store.state.welcomeBack
    },
  },
}
</script>

<style scoped>
</style>
