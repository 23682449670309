<template>
  <div class="custom-area">
    <div class="custom-fields" v-if="!loading && !connected">

      <div class="error-msg-area">
        <alert-msg
          v-if="this.customError"
          :errorMsg="customErrorMsg">
        </alert-msg>
      </div>

      <CustomFormFields
        :fields="fields"
        ref="customEmailFields"
        v-model="user"
        :baseData="baseData"
        @invalid="invalidEntities"
        @valid="validEntities"
      />

      <div style="position: relative">
        <button class="social-btn connect"
          @click.prevent="connect()"
          :style="primaryButtonStyles">
          <i class="fa fa-wifi" aria-hidden="true" v-show="baseData.loginOptions.styles.primaryButtonIconVisible || false"></i>
          <span>{{ $t('connect_button') }}</span>
        </button>

        <div v-show="validating"
          class="sk-three-bounce"
          style="position: absolute; top: 23px; width: 100%;">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
        </div>
      </div>

      <a href="#"
        v-if="baseData.backButton"
        @click.prevent="goBack()">
        ← {{ $t('back_button') }}
      </a>
    </div>

    <transition name="slide-fade">
      <div style="text-align: center"
        class="loader"
        v-if="loading">
        <wifi-loader></wifi-loader>
        <p>{{ $t('connecting') }}</p>
      </div>
    </transition>

    <div style="text-align: center">
      <transition name="slide-fade">
        <div class="loader"
          v-if="connected">
          <p>{{ $t('operation_success') }}</p>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import {required, minLength, maxLength, email, numeric} from 'vuelidate/lib/validators'
import WifiLoader from './../../components/loader/wifi-loading.vue';
import AlertMsg from './../../components/errors/alert.vue';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import FormField from '../payments/cleave.vue'
import CustomFormFields from '../fields/custom-fields'

const touchMap = new WeakMap();

export default {
  components: {
    WifiLoader,
    vSelect,
    AlertMsg,
    FormField,
    CustomFormFields,
  },
  props: ['api', 'action', 'wftkn', 'fields'],
  computed: {
    baseData(){
      return this.$store.state.baseData
    },
    payload(){
      return {
        ...this.user,
        action: this.action,
        wftkn: this.wftkn
      }
    },
    primaryButtonStyles() {
      return {
        'border-radius': `${this.radius}px` || '32px',
        'background': this.primaryButtonColor || '#55acee',
        'color': this.primaryButtonTextColor || 'white',
        'justify-content': this.primaryButtonTextAlignment || 'center'
      }
    },
    radius(){
      return this.baseData.loginOptions.styles.buttons.radius
    },
    primaryButtonColor(){
      return this.baseData.loginOptions.styles.primaryButtonColor
    },
    primaryButtonTextColor(){
      return this.baseData.loginOptions.styles.primaryButtonTextColor
    },
    primaryButtonTextAlignment() {
      return this.baseData.loginOptions.styles.primaryButtonTextAlign.value
    }
  },
  data() {
    return {
      loading: false,
      validating: false,
      connected: false,
      user: null,
      customError: false,
      customErrorMsg: '',
    }
  },
  mounted(){
    this.getCode();
  },

  methods: {
    invalidEntities(){
      this.validating = false
    },
    validEntities(){
      this.validating = false
      this.connectRequest()
    },
    getCode(){
      this.code = this.baseData.region ? this.baseData.region.phoneCode : '+1';
    },
    resetError(){
      this.customError = false
      this.customErrorMsg = ''
    },
    connectRequest() {
      this.loading = true
      this.resetError()
      axios
        .post(this.api, {}, {params: this.payload})
        .then((res) => {
          if(!res.data.status){
            this.loading = false
            this.customError = true
            this.customErrorMsg = this.$t(res.data.message) || res.data.message
          }
          else{
            this.connected = true
            setTimeout(()=>{
              window.location = decodeURIComponent(res.data.url)
            }, 1000)
          }
        })
        .catch((error) => {
          this.loading = false
          if(error.response && error.response.data && error.response.data.message){
            this.customError = true
            this.customErrorMsg = this.$t(error.response.data.message) || error.response.data.message
          }
        });
    },
    connect() {
      if(this.api.includes('preview')) {
        return
      } else {
        this.validating = true;
        this.$refs.customEmailFields.allValid();
      }
    },
    goBack() {
      this.$eventHub.$emit('showCustom', false)
    },
  }
}
</script>


<style>
.custom-field-holder .wifi-symbol .wifi-circle{
  border-color: #55acee;
  -webkit-animation: wifianimation 1.5s infinite;
  animation: wifianimation 1.5s infinite;
}
.input-error .dropdown-toggle {
    border: 1px solid red !important;
    background: #f6e7e7 !important;
}

.phone-code {
    display: flex;
    max-width: 320px!important;
}

.phone-code .country-code {
    width: 30px!important;
    margin-right: 10px;
}
.custom-area {
    height: 100%;
}
.custom-area .v-select{
  /* width: 320px!important; */
  color: #404040;
}
.social-btn {
  display: flex !important;
}
.social-btn:hover {
  cursor: pointer;
}
.custom-area .v-select .dropdown-toggle{
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 32px;
  padding: 0 10px;
  box-shadow: 2px 0 20px 0 rgba(0, 0, 0, 0.1);
  background: white;
}
.custom-area .v-select.open .dropdown-toggle{
  border-radius: 32px 32px 0 0;
}
.custom-area .v-select input{
  /* position: absolute!important; */
  /*left: 10px;*/
}
.custom-area .v-select .dropdown-toggle .clear{
  bottom: 13px;
}
.custom-area .v-select .open-indicator{
  bottom: 9px;
  right: 15px!important;
}
</style>
