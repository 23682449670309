<template>
  <div class="Mw-PostLogin-thankyou-custom-text" v-if="text.enabled">
    <p> {{ localizedText }} </p>
  </div>
</template>

<script>
export default {
  data(){
    return {
      localizedText: null,
    }
  },
  props: {
    option: {
      type: Object
    }
  },
  mounted() {
    let plume = localStorage.getItem('isPlume')
    const pathName = window.location.pathname;
    const parts = pathName.split('/');
    let pageName = parts.filter(part => part.includes('.html'))[0];

    if (this.baseData.multilingualOption.showLangOption === 'true' || this.baseData.multilingualOption.showLangOption === true || plume) {
      // should be already present
      const localStorageLanguage = JSON.parse(localStorage.getItem('selectedLanguage'))
      let value = localStorageLanguage[pageName];

      if (pageName && value) {
        this.$i18n.locale = value
      }
    } // single language campaign and non-plume campaign's it will use default language code
    this.customText()
  },
  computed: {
    text(){
      return this.option.custom_text
    },
    baseData(){
      return this.$store.state.baseData
    }
  },
  methods:{
    customText() {
      let option = JSON.parse(JSON.stringify(this.option))
      if(this.baseData.multilingualOption.showLangOption === true || this.baseData.multilingualOption.showLangOption === 'true'){
        if(this.baseData.isBuilderPreview){
          this.$i18n.locale = this.baseData.customLangCode
        }
        this.localizedText = this.$t('enjoy_browsing')
      }else{
        this.localizedText = option.custom_text.text
      }
    }
  },
  watch:{
    baseData:{
      deep: true,
      handler(){
        this.customText()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.Mw-PostLogin-thankyou-custom-text{
  padding: 20px;
  p{
    font-size: 14px;
    text-align: center;
    line-height: 20px;
  }
}
</style>
