<template>
    <div id="app1" v-if="!!baseData" :style="backgroundClass">

        <div
            v-if="backgroundBlur"
            :style="backgroundImage"
        ></div>
        <!-- <p>hello cc</p> -->
        <img v-if="backgroundBlur" :src="imageUrl"  :style="foregroundImage">
        <div :style="layerStyles"></div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            // baseData: null
        }
    },
    computed:{
        baseData(){
            console.log(this.$store.state.baseData);
            return this.$store.state.baseData
        },
        backgroundClass(){
            if (!this.baseData.branding) return {}
            if (this.baseData.branding.background.substr(0, 3) === 'url') { 
                const styles = {
                'color': this.textColor,
                'font-family': this.baseData.branding.fontFamily.value,
                'background': `${this.baseData.branding.background} ${this.baseData.branding.backgroundRepeat.value}`,
                }   

                if(this.baseData.branding.backgroundRepeat.value === 'square-fit'){
                Object.assign(styles, {
                    'background': null
                })
                }

                if(this.baseData.branding.backgroundRepeat.value === 'stretch') {
                Object.assign(styles, {
                    'background' : this.baseData.branding.background,
                    'background-position': this.baseData.branding.backgroundRepeatAlignment.value,
                    'background-size': 'cover',
                    'background-attachment': 'fixed',
                })
                }
                return styles
            }
            else {
                return {
                'background': this.baseData.branding.background,
                'color': this.textColor,
                'font-family': this.baseData.branding.fontFamily.value
                }
            }
        },
        backgroundBlur(){
        if (!this.baseData.branding) return false
        return (this.baseData.branding.background.substr(0, 3) === 'url' && this.baseData.branding.backgroundRepeat.value  === 'square-fit') ? true : false
        },
        backgroundImage(){
            if (!this.baseData.branding) return {}
            return {
                'background': this.baseData.branding.background,
                'position': 'absolute',
                'height': '100vh',
                'width': 'inherit',
                'background-size': 'cover',
                'filter': 'blur(5px)',
                'background-position': 'center',
                'z-index': '1',
            }
        },
        foregroundImage(){
            if (!this.baseData.branding) return {}
            return {
                'height': '100vh',
                'max-width': '600px',
                'position': 'absolute',
                'margin': 'auto',
                'left': '0',
                'right': '0',
                'width': '100%',
                'background-size': 'contain',
                'background-repeat': 'no-repeat',
                'z-index': '2'
            }
        },
        imageUrl(){
            if(this.baseData.branding && this.baseData.branding.background.substr(0, 3) !== 'url') return {}
            let imageUrl = this.baseData.branding.background
            return imageUrl.replace(/^url\((.*)\)$/, '$1');
        },
        layerStyles(){
            if(this.baseData && this.baseData.branding && this.baseData.branding.layer && !this.baseData.branding.layer.enabled) return {}
            if(this.baseData && this.baseData.branding && this.baseData.branding.layer.enabled){
                return {
                'background': this.baseData.branding.layer.layerColor,
                'height': '100vh',
                'width': '100%',
                'position': 'absolute',
                'z-index': '3'
                }
            }
        },
    },

}
</script>

<style lang="scss" scoped>
#app1{
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    display: unset;
    -webkit-box-pack: initial;
    -ms-flex-pack: initial;
    justify-content: initial;
    min-height: 100vh;
    max-width: 100%;
}
</style>