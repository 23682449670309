<template>
  <div class="forecast">
    
    <div v-if="show">
      
      <div class="day" v-for="(day, index) in weatherPayload.DailyForecast.DailyForecasts" :key="index" @click="showDetails(day)">
        <div class="date">
          <h4>{{getDayName(day.Date)}}</h4>
          <p>{{moment(day.Date).format('MMMM, D, YYYY')}}</p>
        </div>
        <div class="icon">
          <div :title="day.Day.IconPhrase" v-html="icons[day.Day.Icon]"></div>
        </div>
        <div class="unit">
          <h2>{{getTemperature(day.Temperature)}}°</h2>
          <p :title="day.Day.IconPhrase">{{day.Day.IconPhrase}}</p>
        </div>
        <div class="right-arrow">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 129 129"> <g> <path d="m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z"/> </g></svg>
        </div>
      </div>
      
    </div>
    
    <transition name="slide-fade">
      <day-weather :weatherPayload="weatherPayload" :item="selected" :icons="icons" v-if="details"></day-weather>
    </transition>
  </div>
</template>

<style scoped>
  .forecast{
    color: #202020;
    border-radius: 0 0 5px 5px;
    position: relative;
    /*position: absolute;*/
    /*top: 118px;*/
    /*width: 240px;*/
  }
  
  .forecast .day{
    background: white;
    display: flex;
    justify-content: space-between;
    box-shadow: 1px 1px 1px 1px #2020201c;
    padding: 5px 10px;
    align-items: center;
    opacity: 0;
    transform: translateX(-5px);
    transition: .2s all;
    cursor: pointer;
  }
  .forecast .day:hover{
    background: #f4f4f4;
  }
  
  .forecast h4{
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    margin: 5px 0 5px 0;
  }
  
  .forecast h2{
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }
  
  .forecast p{
    font-size: 10px;
    margin: -4px 0 0 0;
    opacity: 0.7;
    max-width: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .forecast .unit{
    text-align: center;
    min-width: 40px;
  }
  
  .forecast .icon{
    width: 32px;
    margin-left: auto;
    margin-right: 10px;
  }
  
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
  
  .right-arrow{
    width: 8px;
    opacity: .3;
    margin-left: 5px;
  }
  .day .date p{
    max-width: 120px;
  }
</style>
 
<script>
  import DayWeather from './day.vue'
  export default{
    components: {
      DayWeather
    },
    props: ['weatherPayload', 'show', 'icons'],
    data(){
      return {
        details: false,
        selected: {},
      }
    },
    created(){
      this.$eventHub.$on('closeDetails', this.hideDetails);
    },
    watch:{
      show(){
        (this.show) ? this.animate() : '';
      }
    },
    computed:{
      today(){
        return moment(new Date()).format();
      },
    },
    methods: {
      getTemperature(temp){
        return ((temp.Minimum.Value + temp.Maximum.Value)/2).toFixed(0);
      },
      showDetails(day){
        this.selected = day;
        this.details = true;
      },
      getDayName(day){
        let lookpup = {0: 'Today', 1: 'Tomorrow'}
        let diff = moment(moment(day).format('YYYYMMDD')).diff(moment(new Date()).format('YYYYMMDD'), 'days'); 
        return (diff < 2 && diff > -1) ? lookpup[diff] : moment(day).format('dddd');
      },
      
      hideDetails(){
        this.details = false;
      },
      
      animate()
      {
          setTimeout(()=>{
              let btns = document.getElementsByClassName('day');
              for (let i = 0; i < btns.length; i++) {
                  setTimeout(()=>{
                    if(btns[i])
                    {
                        btns[i].style.opacity = 1;
                        btns[i].style.transform = 'translateX(0)';
                    }
                    }, 200+(i*50));
              }
          }, 20);
        },
    }
  }
</script>