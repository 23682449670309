<template>
  <div>
    <div id="fb-root"></div>
    <div class="fb-page" 
          :data-href="facebookPage"
          data-width="380" 
          data-hide-cover="false"
          data-show-facepile="true"></div>
    </div>
  </div>
</template>

<script>
  export default{
    created(){
      let vm = this;
      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.12&autoLogAppEvents=1';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    },
    computed: {
      facebookPage(){
        return (this.$store.getters.postLogin) ? this.$store.getters.postLogin.payload.facebook_page : null;
      },
    }
  }
</script>