<template>
  <layout>
    <div v-if="isSkipToFreeWifi === true || showButtons"
      class="social-btn-holder loginBorder"
      :class="{'appStyle':listStyle==='app', 'defaultStyle':listStyle==='default'}"
      :style="'border-radius:'+borderRadius+'px'"
      id="social-holders">
      <facebook-btn
        :id="'option_'+logins.indexOf('facebook')"
        :ref="'option_'+logins.indexOf('facebook')"
        v-if="isSkipToFreeWifi === false && logins.includes('facebook')"
        :api="api"
        action="facebook"
        :wftkn="wftkn" />
      <twitter-btn
        :id="'option_'+logins.indexOf('twitter')"
        :ref="'option_'+logins.indexOf('twitter')"
        v-if="isSkipToFreeWifi === false && logins.includes('twitter')"
        :api="api"
        action="twitter"
        :wftkn="wftkn" />
      <linkedin-btn
        :id="'option_'+logins.indexOf('linkedin')"
        :ref="'option_'+logins.indexOf('linkedin')"
        v-if="isSkipToFreeWifi === false && logins.includes('linkedin')"
        :api="api"
        action="linkedin"
        :wftkn="wftkn" />
      <o-auth-btn
        :id="'option_'+logins.indexOf('oauth')"
        :ref="'option_'+logins.indexOf('oauth')"
        v-if="isSkipToFreeWifi === false && logins.includes('oauth')"
        :api="api"
        action="oauth"
        :wftkn="wftkn" />
      <office-rnd-btn
        :id="'option_'+logins.indexOf('officernd')"
        :ref="'option_'+logins.indexOf('officernd')"
        v-if="isSkipToFreeWifi === false && logins.includes('officernd')"
        :api="api"
        action="officernd"
        :wftkn="wftkn" />
      <instagram-btn
        :id="'option_'+logins.indexOf('instagram')"
        :ref="'option_'+logins.indexOf('instagram')"
        v-if="isSkipToFreeWifi === false && logins.includes('instagram')"
        :api="api"
        action="instagram"
        :wftkn="wftkn" />
      <vk
        :id="'option_'+logins.indexOf('vkontakte')"
        :ref="'option_'+logins.indexOf('vkontakte')"
        v-if="isSkipToFreeWifi === false && logins.includes('vkontakte')"
        :api="api"
        action="vkontakte"
        :wftkn="wftkn" />
      <custom-btn
        :id="'option_'+logins.indexOf('email')"
        :ref="'option_'+logins.indexOf('email')"
        v-if="isSkipToFreeWifi === false && logins.includes('email')"
        :api="api"
        action="custom-login"
        :wftkn="wftkn" />
      <twilio-btn
        :id="'option_'+logins.indexOf('sms')"
        :ref="'option_'+logins.indexOf('sms')"
        v-if="isSkipToFreeWifi === false && logins.includes('sms')" />
      <messenger-btn
        :id="'option_'+logins.indexOf('facebook_messenger')"
        :ref="'option_'+logins.indexOf('facebook_messenger')"
        v-if="isSkipToFreeWifi === false && logins.includes('facebook_messenger')"
        :api="api"
        action="facebook-messenger"
        :wftkn="wftkn" />
      <passcode-btn
        :id="'option_'+logins.indexOf('passcode')"
        :ref="'option_'+logins.indexOf('passcode')"
        v-if="isSkipToFreeWifi === false && logins.includes('passcode')" />
      <skip-login
        :id="'option_'+logins.indexOf('freewifi')"
        :ref="'option_'+logins.indexOf('freewifi')"
        v-if="isSkipToFreeWifi === true || logins.includes('freewifi')"
        :api="api"
        action="freewifi"
        :wftkn="wftkn" />
      <stripe-btn
        :id="'option_'+logins.indexOf('credit card')"
        :ref="'option_'+logins.indexOf('credit card')"
        v-if="isSkipToFreeWifi === false && logins.includes('credit card')"
        :api="api"
        @showPaymentOptions="pushToCreditCardOption()"
        action="creditcard"
        :wftkn="wftkn" />
    </div>

    <a class="goback-payment"
      href="#"
      v-if="showButtons && isPaidCampaign"
      @click.prevent="backToPaymentsPage()">
      ← {{ $t('back_button') }}
    </a>

    <transition name="slide-fade">
      <div v-if="showTwilioFields" class="custom-field-holder">
        <twilio-fields
          :api="api"
          :wftkn="wftkn"
          action="sms" />
      </div>
    </transition>

    <transition name="slide-fade">
      <div v-if="showCustomFields" class="custom-field-holder">
        <custom-fields
          :api="api"
          action="custom-login"
          :wftkn="wftkn"
          :fields="fields" />
      </div>
    </transition>

    <transition name="slide-fade">
      <div v-if="showPasscodeFields" class="custom-field-holder">
        <passcode-fields
          :api="api"
          :wftkn="wftkn"
          action="pass-code" />
      </div>
    </transition>

    <transition name="slide-fade">
      <div v-if="showOfficeRnDFields" class="custom-field-holder">
        <office-rnd-fields
          :api="api"
          :wftkn="wftkn"
          action="officernd" />
      </div>
    </transition>
  </layout>
</template>

<script>
import Layout from './layout'
import FacebookBtn from '../components/social-buttons/facebook.vue';
import TwitterBtn from '../components/social-buttons/twitter.vue';
import LinkedinBtn from '../components/social-buttons/linkedIn.vue';
import OAuthBtn from '../components/social-buttons/oauth.vue';
import OfficeRndBtn from '../components/social-buttons/officernd.vue';
import InstagramBtn from '../components/social-buttons/instagram.vue';
import CustomBtn from '../components/social-buttons/custom.vue';
import Vk from '../components/social-buttons/vk.vue';
import SkipLogin from '../components/social-buttons/skip.vue';
import TwilioBtn from '../components/social-buttons/twilio.vue';
import MessengerBtn from '../components/social-buttons/messenger.vue';
import PasscodeBtn from '../components/social-buttons/passcode.vue';
import TwilioFields from '../components/twilio-connect/twilio-connect.vue';
import CustomFields from '../components/custom/custom.vue';
import PasscodeFields from '../components/passcode/passcode.vue';
import OfficeRndFields from '../components/officernd/officernd.vue';
import StripeBtn from '../components/social-buttons/stripe.vue';

export default {
  components: {
    Layout,
    FacebookBtn,
    TwitterBtn,
    LinkedinBtn,
    OAuthBtn,
    OfficeRndBtn,
    InstagramBtn,
    CustomBtn,
    StripeBtn,
    CustomFields,
    Vk,
    SkipLogin,
    TwilioBtn,
    MessengerBtn,
    PasscodeBtn,
    TwilioFields,
    PasscodeFields,
    OfficeRndFields,
  },
  data() {
    return {
      showCustomFields: false,
      showTwilioFields: false,
      showPasscodeFields: false,
      showOfficeRnDFields: false,
      showStripeForm: false,
      selectedPlan: null,
      planId: null,
      showSocialIcons: true
    }
  },
  computed: {
    isSkipToFreeWifi() {
      return false;

      return this.$store.state.skipToFreeWifi;
    },
    baseData() {
      return this.$store.state.baseData
    },
    wftkn(){
      return this.$store.state.wftkn
    },
    api(){
      return this.$store.state.api
    },
    logins() {
      return this.$store.state.logins
    },
    listStyle(){
      if(this.baseData.loginOptions){
        return this.baseData.loginOptions.styles.layout
      }
      return 'default'
    },
    borderRadius(){
      if(this.baseData.loginOptions && this.baseData.loginOptions.styles && this.baseData.loginOptions.styles.buttons){
        return this.baseData.loginOptions.styles.buttons.radius
      }
      return 32
    },
    isPaidCampaign(){
      return !!(this.baseData.paymentPlans);
    },
    showButtons(){
      return this.showSocialIcons &&
        !this.showCustomFields &&
        !this.showTwilioFields &&
        !this.showPasscodeFields &&
        !this.showOfficeRnDFields
    },
    fields() {
      return this.$store.state.fields
    },
  },
  methods: {
    showCustomFieldsArea(value){
      this.showCustomFields = value;
    },

    showTwilioFieldsArea(value){
      this.showTwilioFields = !this.showTwilioFields;
    },

    showPasscodeFieldsArea(value){
      this.showPasscodeFields = value;
    },

    showOfficeRnDFieldsArea(value){
      this.showOfficeRnDFields = value;
    },
    backToPaymentsPage() {
      this.$router.push('/payment-plans');
    },
    pushToCreditCardOption() {
      this.$router.push('/payment-plans?option=creditcard');
    },
    animateSocial(){
      setTimeout(()=>{
        let btns = document.getElementsByClassName('social-btn');
        for (let i = 0; i < btns.length; i++) {
          setTimeout(()=>{
            if(btns[i]){
              btns[i].style.opacity = 1;
              btns[i].style.marginLeft = '0';
            }
          }, 200+(i*50));
        }
        this.sortOptions();
      }, 20);
    },
    sortOptions(){
      var main = document.getElementById( 'social-holders' );

      if(main && main.children){
        [].map.call( main.children, Object ).sort( function ( a, b ) {
            return +a.id.match( /\d+/ ) - +b.id.match( /\d+/ );
        }).forEach( function ( elem ) {
            main.appendChild( elem );
        });
      }

    },
    checkAndAutoInitiateLogin(){
      const loginMethods = this.logins
      const allowedMethods = [ 'sms', 'passcode', 'freewifi', 'email' ]
      if(loginMethods.length === 1  && this.baseData.preview !== 1){
        const loginName = loginMethods[0]
        if(allowedMethods.includes(loginName)){
          if(loginName === 'sms'){
            this.$eventHub.$emit('showTwilio', true)
          }
          else if(loginName === 'passcode'){
            this.$eventHub.$emit('showPasscode', true)
          }
          else if(loginName === 'email'){
            this.$eventHub.$emit('showCustom', true)
          }
          else if(loginName === 'freewifi'){
            const url  = `${this.api}&action=freewifi&wftkn=${this.wftkn}`
            window.open(url, '_self')
          }
        }
      }
    }
  },
  watch: {
    showButtons() {
      this.animateSocial();
    },
    baseData() {
      this.animateSocial();
    }
  },
   updated: function () {
    var ref = this;
    this.$store.state.baseData.backButton = true;
  },
  created(){
    this.$eventHub.$on('showCustom', this.showCustomFieldsArea);
    this.$eventHub.$on('showTwilio', this.showTwilioFieldsArea);
    this.$eventHub.$on('showPasscode', this.showPasscodeFieldsArea);
    this.$eventHub.$on('showOfficeRnD', this.showOfficeRnDFieldsArea);
  },
  beforeDestroy(){
    this.$eventHub.$off('showCustom');
    this.$eventHub.$off('showTwilio');
    this.$eventHub.$off('showPasscode');
    this.$eventHub.$off('showOfficeRnD');
  },
  mounted() {
    this.animateSocial();
    this.checkAndAutoInitiateLogin()
  }
}
</script>

<style lang="scss">
.social-btn .fa, .social-btn .far, .social-btn .fab {
  margin-right: 10px;
  font-size: 18px;
  width: 18px;
  height: 18px;
  text-align: center;
}
.social-btn:last-child{
  margin-bottom: 0;
}

.loginBorder{
  .social-btn{
    border-radius: inherit;
  }
}

.social-btn{
  color: white;
  width: 90%;
  max-width: 320px;
  border:none;
  border-radius: 32px;
  padding: 15px 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: 0.3s all;
  opacity: 0;
  outline: none;
  text-decoration: none;
  margin-bottom: 10px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);
}
.social-btn:last-child{
  margin-bottom: 0;
}
.appStyle{
  &.social-btn-holder{
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 70%;
    max-width: 400px;
    border-radius: 32px;
    .social-btn{
      margin: 10px;
      margin-left: 0;
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 65px;
      width: 65px;
      padding: 0;
      span{
        display: none;
      }
      .fa{
        margin: 0;
        font-size: 32px;
        height: auto;
        width: auto;
      }
      .fab{
        margin: 0;
        font-size: 32px;
        margin-right: 8px;
      }
      .fa-lock {
        margin: 0;
      }
    }
  }
}
.custom-field-holder{
  width: 100%;
  /*text-align: center;*/
  /*top: 20px;*/
  position: relative;
  padding: 30px 0;
  /*height: 100%;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /*justify-content: center;*/
}
.custom-field-holder a{
  color: inherit;
  text-decoration: none;
  display: inline-block;
  margin: 15px 0;
  border:none;
}
</style>
